import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Auth
import RegisterPage from "./pages/AuthPages/RegisterPage";
import LoginPage from "./pages/AuthPages/LoginPage";
import ResetPasswordPage from "./pages/AuthPages/ResetPasswordPage";

// Accept invites
import ShareRegisterPage from "./pages/SharePages/ShareRegisterPage";
import ShareLoginPage from "./pages/SharePages/ShareLoginPage";

// Dashboard
import FoldersPage from "./pages/FolderPages/FoldersPage";
import UploadsPage from "./pages/LibraryPages/UploadsPage";
import LikesPage from "./pages/LibraryPages/LikesPage";
import DownloadsPage from "./pages/LibraryPages/DownloadsPage";
import AnalyticsPage from "./pages/AnalyticsPage";

// Track pages
import TrackPage from "./pages/TrackPage";

// Folder pages
import FolderPage from "./pages/FolderPages/FolderPage";

// Submission
import SubmissionsListPage from "./pages/SubmissionPages/SubmissionsListPage";
import SubmissionPage from "./pages/SubmissionPages/SubmissionPage";

// Landing Page
import LandingPage from "./pages/LandingPage";

// Components

import Alert from "./components/alert";
import Modal from "./components/Modal";
import Sidebar from "./components/Sidebar";
import StatusBar from "./components/StatusBar";
import Player from "./components/audio";
import AudioPlayer from "./components/AudioPlayer";
import ContextMenu from "./components/ContextMenu";
import PrivateRoute from "./components/Routing/PrivateRoute";

import "./App.scss";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";
import ScrollToTop from "./utils/scrollToTop";

import TopBar from "./components/TopBar";

if (localStorage.accessToken) {
  setAuthToken(localStorage.accessToken);
}

const App = () => {
  const [mainContainerScrollTop, setMainContainerScrollTop] = useState(0);

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  const handleMainContainerScroll = (e) => {
    setMainContainerScrollTop(e.target.scrollTop);
  };

  // Receive notifications from server
  // useEffect(() => {
  //   const socket = socketClient();
  //   socket.on("notificationStatus", ({ unread }) => {
  //     store.dispatch(setUnreadNotificationsStatus(unread));
  //   });

  //   socket.on("uploadComplete", (message) => {
  //     let trackId = JSON.parse(message).trackId;
  //     toggleTrackUpdate();
  //   })

  //   return () => socket.close();
  // }, []);

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <Fragment>
            <Modal />
            <ContextMenu />
            <ToastContainer />

            <Player />
            <AudioPlayer />
            <Alert />

            <StatusBar />

            <div className="sidebar-mobile-container">
              <Sidebar />
            </div>

            <Switch>
              {/*Auth*/}
              <Route exact path="/register" component={RegisterPage} />

              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/resetPassword/:accessToken"
                component={ResetPasswordPage}
              />

              {/** Landing Page **/}

              <Route exact path="/" component={LandingPage} />

              {/** Track View */}

              <Route exact path="/track/:trackId" component={TrackPage} />

              {/*Accept invites*/}
              <Route
                exact
                path="/access/register/:accessToken"
                component={ShareRegisterPage}
              />
              <Route
                exact
                path="/access/login/:accessToken"
                component={ShareLoginPage}
              />

              {/* In app views */}
              <div
                className="main-page-container"
                onScroll={handleMainContainerScroll}
              >
                <TopBar scrollTop={mainContainerScrollTop} />

                {/*Dashboard*/}
                <PrivateRoute
                  exact
                  path="/dashboard/library"
                  component={FoldersPage}
                />

                <PrivateRoute
                  exact
                  path="/dashboard/folder/:folderId"
                  component={FolderPage}
                />
                <PrivateRoute
                  exact
                  path="/dashboard/uploads"
                  component={UploadsPage}
                />

                <PrivateRoute
                  exact
                  path="/dashboard/likes"
                  component={LikesPage}
                />
                <PrivateRoute
                  exact
                  path="/dashboard/downloads"
                  component={DownloadsPage}
                />

                <PrivateRoute
                  exact
                  path="/dashboard/analytics"
                  component={AnalyticsPage}
                />

                {/*Logged out views*/}
                <Route exact path="/folder/:folderId" component={FolderPage} />

                {/*Submission Routes*/}

                <PrivateRoute
                  exact
                  path="/dashboard/shared"
                  component={SubmissionsListPage}
                />

                <PrivateRoute
                  exact
                  path="/dashboard/shared/:tab"
                  component={SubmissionsListPage}
                />

                <PrivateRoute
                  exact
                  path="/dashboard/shared/open/:id"
                  component={SubmissionPage}
                />
              </div>
            </Switch>
          </Fragment>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default App;
