import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { toggleTrackUpdate } from "../../../../actions/track";
import { seekCommentTime } from "../../../../actions/player";

import CommentMenu from "./CommentMenu";
import { setActiveCommentReply } from "../../../../actions/trackModal";
import "./style.scss";

import moment from "moment";

const Comment = ({
  comment,
  activeCommentReplyId,
  setActiveCommentReply,
  toggleTrackUpdate,
  type,
  reply,
  track,
  seekCommentTime,
}) => {
  const [commentHover, setCommentHover] = useState(false);
  const [formData, setFormData] = useState({
    newComment: "",
  });
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);

  const closeMenuHandler = async () => {
    setOpenSettingsMenu(false);
  };

  const { newComment } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const sendComment = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (newComment !== "") {
        const body = JSON.stringify({
          text: newComment,
        });

        await axios.post(`/api/comments/reply/${comment._id}`, body, config);

        toggleTrackUpdate();
        setFormData({
          newComment: "",
        });

        setActiveCommentReply(null);
      }
    } catch (error) {
      console.log("failed");
    }
  };

  return (
    <div
      className="track-modal-comment-box"
      onMouseEnter={() => setCommentHover(true)}
      onMouseLeave={() => setCommentHover(false)}
    >
      <div
        className="comment-user"
        style={{ paddingLeft: type === "reply" ? "48px" : "0px" }}
      >
        {comment.user.profileImageURL ? (
          <div
            className="profile-image"
            style={{
              backgroundImage: `url(${
                type === "comment"
                  ? comment.user.profileImageURL
                  : reply.user.profileImageURL
              })`,
            }}
          ></div>
        ) : (
          <div className="profile-image">{comment.user.username.charAt(0)}</div>
        )}
        <div className="user-name">
          {type === "comment" ? comment.user.username : reply.user.username}
        </div>
      </div>
      <div
        className="comment-body"
        style={{ paddingLeft: type === "reply" ? "48px" : "0px" }}
      >
        <div className="comment-info">
          {type === "comment" && comment.timeStamp ? (
            <span
              className="comment-timestamp"
              onClick={() =>
                seekCommentTime(track, null, {
                  id: track._id,
                  time: comment.timeStamp,
                })
              }
            >
              {moment(comment.timeStamp).format("m:ss")}
            </span>
          ) : null}

          {type === "comment" ? comment.text : reply.text}
        </div>
        <div className="buttons-row">
          <div
            className="reply-button"
            onClick={() =>
              setActiveCommentReply(reply._id ? reply._id : comment._id)
            }
          >
            Reply
          </div>
          {commentHover ? (
            <div
              className="ellipsis-button"
              onClick={() => setOpenSettingsMenu(true)}
            >
              <ion-icon name="ellipsis-horizontal"></ion-icon>
            </div>
          ) : null}

          {openSettingsMenu ? (
            <CommentMenu
              closeMenuHandler={closeMenuHandler}
              type={type}
              track={track}
              comment={comment}
              reply={reply}
            />
          ) : null}
        </div>
      </div>

      {!reply._id && activeCommentReplyId === comment._id ? (
        <div className="reply-form-container">
          <input
            type="text"
            placeholder="Add a reply..."
            value={newComment}
            onChange={(e) => onChange(e)}
            name="newComment"
            className="reply-form"
          />
          <div className="add-comment-button" onClick={() => sendComment()}>
            Send
          </div>
        </div>
      ) : null}

      {activeCommentReplyId === reply._id ? (
        <div className="reply-form-container">
          <input
            type="text"
            placeholder="Add a reply..."
            value={newComment}
            onChange={(e) => onChange(e)}
            name="newComment"
            className="reply-form"
          />
          <div className="add-comment-button" onClick={() => sendComment()}>
            Send
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeCommentReplyId: state.trackModal.activeCommentReplyId,
});

export default connect(mapStateToProps, {
  setActiveCommentReply,
  toggleTrackUpdate,
  seekCommentTime,
})(Comment);
