import React, { useEffect, useState } from "react";
import { toggleMoveFolderModal } from "../../../actions/modal";
import { toggleFolderUpdate } from "../../../actions/folder";
import axios from "axios";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./style.scss";

import Folder from "./Folder";

const MoveFolderModal = ({
  toggleMoveFolderModal,
  toggleFolderUpdate,
  moveFolderId,
  updateToggle,
  user,
}) => {
  const [selectedFolder, selectFolder] = useState(null);
  const [folders, setFolders] = useState();
  const [formData, setFormData] = useState({
    name: "",
  });
  const [parentFolders, setParentFolders] = useState([]);

  const { name } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ name });
    const res = await axios.post("/api/folders", body, config);

    if (res.data.error) {
      toast.error(res.data.message);
    } else {
      toast.success("Folder created");
      toggleFolderUpdate();
      toggleMoveFolderModal();
    }
  };

  async function fetchTopFolders() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get("/api/folders/getTopFolders", config);

      setFolders(res.data);
    } catch (error) {
      toast.error("Error getting folders");
    }
  }

  useEffect(() => {
    fetchTopFolders();
  }, [updateToggle, user]);

  async function fetchChildFolders(folderId) {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/folders/getChildFolders/${folderId}`,
        config
      );

      setFolders(res.data);
    } catch (error) {
      toast.error("Error getting folders");
    }
  }

  const moveFolderHandler = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      let newParentFolderId = selectedFolder._id;
      let folderId = moveFolderId;

      const res = await axios.post(
        `/api/folders/moveFolder/${newParentFolderId}/${folderId}`,
        config
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success("Added to folder");

      toggleFolderUpdate();
      toggleMoveFolderModal();
    } catch (error) {
      toast.error("Error adding track to folder");
    }
  };

  const addParentFolderHandler = (folder) => {
    let newParentFoldersArray = [...parentFolders];
    newParentFoldersArray.push(folder);
    setParentFolders(newParentFoldersArray);
    fetchChildFolders(folder._id);
  };

  const removeParentFolderHandler = () => {
    let newParentFoldersArray = [...parentFolders];

    newParentFoldersArray.splice(-1, 1);

    let lastParent = newParentFoldersArray[newParentFoldersArray.length - 1];

    setParentFolders(newParentFoldersArray);

    if (parentFolders.length > 1) {
      fetchChildFolders(lastParent._id);
    } else {
      fetchTopFolders();
    }
  };

  return (
    <div className="moveFolderModal">
      {folders ? (
        folders.length > 0 ? (
          <>
            <div className="content-top">
              <div className="modal-title">Move folder to...</div>
              <div className="close-icon" onClick={toggleMoveFolderModal}>
                <ion-icon name="close"></ion-icon>
              </div>
            </div>

            <div className="folders-breadcrumbs">
              {parentFolders.length < 1 ? (
                <div className="library-breadcrumbt">Library </div>
              ) : (
                <div
                  className="back-breadcrumb"
                  onClick={() => removeParentFolderHandler()}
                >
                  <div className="back-icon">
                    <ion-icon name="arrow-back-outline"></ion-icon>
                  </div>
                  <div className="back-text">
                    {parentFolders[parentFolders.length - 1].name}
                  </div>
                </div>
              )}
            </div>
            <div className="content-middle">
              {folders.map((folder) => (
                <Folder
                  folder={folder}
                  selectFolderHandler={selectFolder}
                  selectedFolder={selectedFolder}
                  fetchChildFoldersHandler={fetchChildFolders}
                  key={folder._id}
                  addParentFolderHandler={addParentFolderHandler}
                  moveFolderId={moveFolderId}
                />
              ))}
            </div>

            <div className="content-bottom">
              {selectedFolder ? (
                <>
                  <div
                    className="moveFolder-button"
                    onClick={() =>
                      moveFolderHandler(selectedFolder._id, moveFolderId)
                    }
                  >
                    Move
                  </div>
                  <div className="selected-folder-text">
                    <div className="selected-label">Selected folder</div>
                    <div className="selected-folder-name">
                      {selectedFolder.name}
                    </div>
                  </div>
                </>
              ) : (
                <div className="moveFolder-button-disabled">Move</div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="content-top bottom-16">
              <h2 className="title">First, Let's create a folder</h2>
              <div className="close-icon" onClick={toggleMoveFolderModal}>
                <ion-icon name="close"></ion-icon>
              </div>
            </div>
            <div className="content-middle-form">
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="bottom-16">
                  <input
                    type="text"
                    placeholder="Folder name"
                    name="name"
                    value={name}
                    onChange={(e) => onChange(e)}
                    className="text-field"
                  />
                </div>

                <input
                  type="submit"
                  className="button full-width"
                  value="Create"
                />
              </form>
            </div>
          </>
        )
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  moveFolderId: state.modal.moveFolderId,
  user: state.auth.user,
  updateToggle: state.folder.updateToggle,
});

export default connect(mapStateToProps, {
  toggleMoveFolderModal,
  toggleFolderUpdate,
})(MoveFolderModal);
