import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { register, loadUser } from "../../../../actions/auth";
import { toggleAuthModal } from "../../../../actions/modal";
import { toast } from "react-toastify";
import axios from "axios";

import { Mixpanel } from "../../../../utils/Mixpanel";

import "../style.scss";

const RegisterForm = ({
  register,
  toggleAuthModal,
  setCurrentAuthState,
  authState,
  contextObjectId,
  loadUser,
}) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { username, email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    await register({ username, email, password });
    toast.success("Successfully created account");

    toggleAuthModal();

    if (authState === "register-save-to-library") {
      addtoLibraryHandler();
    }
  };

  async function addtoLibraryHandler() {
    try {
      await axios.patch(`/api/submissions/addToLibrary/${contextObjectId}`);

      loadUser();

      toast.success("Saved folder to library");
    } catch (error) {
      console.log("get loops error");
    }
  }

  const setAnalytics = () => {
    Mixpanel.track("Opened register modal");
  };

  useEffect(() => {
    setAnalytics();
  }, []);

  return (
    <div className="authModal">
      <div className="content-top bottom-16">
        <h2 className="title">Create an account for free</h2>
        <div className="close-icon" onClick={toggleAuthModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>
      <div className="form">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="bottom-16">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => onChange(e)}
              name="username"
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              className="text-field"
            />
          </div>

          <input
            type="submit"
            className="button bottom-40 full-width"
            value="Sign up"
          />
        </form>
        <div className="link-text">
          Already have an account?{" "}
          <div
            className="link"
            onClick={() =>
              setCurrentAuthState(
                authState === "register" ? "login" : "login-save-to-library"
              )
            }
          >
            Log In
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { register, toggleAuthModal, loadUser })(
  RegisterForm
);
