import {
  SELECT_TRACK,
  LOAD_TRACK,
  PLAY_NEXT,
  PLAY_PREVIOUS,
  TOGGLE_PLAY,
  SET_CURRENT_DURATION,
  SET_CURRENT_TIME,
  SEEK,
  SET_SEEKING,
  SET_PLAYER_REF,
  TOGGLE_SHUFFLE,
  PLAY_SHUFFLE,
  TOGGLE_REPEAT,
  PLAY_ON_END,
  SET_VOLUME,
  SEEK_COMMENT_TIME,
} from "../actions/types";

import { Mixpanel } from "../utils/Mixpanel";

const initialState = {
  activeTrackObject: {},
  activeTrack: 0,
  isPlaying: null,
  isSeeking: false,
  currentTime: 0,
  currentDuration: 0,
  activeTrackId: null,
  activeTrackTitle: null,
  activeTrackFileUrl: null,
  currentPlaylist: [],
  currentPlaylistIndex: null,
  seekingTime: null,
  playerRef: null,
  shuffle: false,
  repeat: "no-repeat",
  currentFolderImageURL: null,
  currentVolume: 100,
  lastSeekedValue: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_TRACK:
      Mixpanel.track("Select track", {
        name: action.track.name,
        trackId: action.track._id,
        owner: action.track.owner.username,
      });

      return {
        ...state,
        activeTrackObject: action.track,
        currentPlaylist: action.currentPlaylist,
        currentPlaylistIndex: action.currentPlaylistIndex,
        isPlaying: true,
        currentTime: 0,
        seekingTime: null,
        currentFolderImageURL: action.currentFolderImageURL,
      };

    case LOAD_TRACK:
      return {
        ...state,
        activeTrackObject: action.track,
        currentPlaylist: action.currentPlaylist,
        currentPlaylistIndex: action.currentPlaylistIndex,
        currentTime: 0,
        seekingTime: null,
        currentFolderImageURL: action.currentFolderImageURL,
      };

    case PLAY_NEXT:
      let play_next_nextActiveTrackObject;
      let play_next_nextCurrentPlaylistIndex;
      let play_next_newRepeat;

      if (
        state.currentPlaylist.length - 1 === state.currentPlaylistIndex ||
        state.currentPlaylist.length === 0
      ) {
        play_next_nextActiveTrackObject = state.currentPlaylist[0];
        play_next_nextCurrentPlaylistIndex = 0;
      } else {
        play_next_nextActiveTrackObject =
          state.currentPlaylist[state.currentPlaylistIndex + 1];
        play_next_nextCurrentPlaylistIndex = state.currentPlaylistIndex + 1;
      }

      if (state.repeat === "repeat-one") {
        play_next_newRepeat = "repeat-playlist";
      } else {
        play_next_newRepeat = state.repeat;
      }

      Mixpanel.track("Play next");

      return {
        ...state,
        activeTrackObject: play_next_nextActiveTrackObject,
        currentPlaylistIndex: play_next_nextCurrentPlaylistIndex,
        isPlaying: true,
        currentTime: 0,
        seekingTime: null,
        repeat: play_next_newRepeat,
      };

    case PLAY_ON_END:
      let playOnEnd_nextActiveTrackObject;
      let playOnEnd_nextCurrentPlaylistIndex;
      let playOnEnd_isPlaying;

      if (state.repeat === "repeat-playlist") {
        if (
          state.currentPlaylist.length - 1 === state.currentPlaylistIndex ||
          state.currentPlaylist.length === 0
        ) {
          playOnEnd_nextActiveTrackObject = state.currentPlaylist[0];
          playOnEnd_nextCurrentPlaylistIndex = 0;
          playOnEnd_isPlaying = true;
        } else {
          playOnEnd_nextActiveTrackObject =
            state.currentPlaylist[state.currentPlaylistIndex + 1];
          playOnEnd_nextCurrentPlaylistIndex = state.currentPlaylistIndex + 1;
          playOnEnd_isPlaying = true;
        }
      }

      if (state.repeat === "no-repeat") {
        playOnEnd_nextActiveTrackObject =
          state.currentPlaylist[state.currentPlaylistIndex];
        playOnEnd_nextCurrentPlaylistIndex = state.currentPlaylistIndex;
        playOnEnd_isPlaying = false;
      }

      Mixpanel.track("Play on track end");

      return {
        ...state,
        activeTrackObject: playOnEnd_nextActiveTrackObject,
        currentPlaylistIndex: playOnEnd_nextCurrentPlaylistIndex,
        isPlaying: playOnEnd_isPlaying,
        currentTime: 0,
        seekingTime: null,
      };

    case PLAY_SHUFFLE:
      let playShuffle_nextActiveTrackObject;
      let playShuffle_nextCurrentPlaylistIndex;
      let playShuffle_newRepeat;

      const randomIndex = Math.floor(
        Math.random() * Math.floor(state.currentPlaylist.length)
      );

      playShuffle_nextActiveTrackObject = state.currentPlaylist[randomIndex];
      playShuffle_nextCurrentPlaylistIndex = randomIndex;

      if (state.repeat === "repeat-one") {
        playShuffle_newRepeat = "repeat-playlist";
      } else {
        playShuffle_newRepeat = state.repeat;
      }

      Mixpanel.track("Play shuffle");

      return {
        ...state,
        activeTrackObject: playShuffle_nextActiveTrackObject,
        currentPlaylistIndex: playShuffle_nextCurrentPlaylistIndex,
        isPlaying: true,
        currentTime: 0,
        seekingTime: null,
        repeat: playShuffle_newRepeat,
      };

    case TOGGLE_PLAY:
      Mixpanel.track("Toggle play");

      return {
        ...state,
        isPlaying: !state.isPlaying,
        seekingTime: state.currentTime,
      };

    case PLAY_PREVIOUS:
      let previousActiveTrackObject;
      let previousCurrentPlaylistIndex;

      Mixpanel.track("Play previous");

      if (state.currentPlaylistIndex === 0) {
        previousActiveTrackObject =
          state.currentPlaylist[state.currentPlaylist.length - 1];
        previousCurrentPlaylistIndex = state.currentPlaylist.length - 1;
      } else {
        previousActiveTrackObject =
          state.currentPlaylist[state.currentPlaylistIndex - 1];
        previousCurrentPlaylistIndex = state.currentPlaylistIndex - 1;
      }

      return {
        ...state,
        activeTrackObject: previousActiveTrackObject,
        currentPlaylistIndex: previousCurrentPlaylistIndex,
        isPlaying: true,
        currentTime: 0,
        seekingTime: 0,
      };

    case SET_CURRENT_DURATION:
      return {
        ...state,
        currentDuration: action.duration,
      };

    case SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.time,
      };

    case SEEK:
      Mixpanel.track("Seek time");

      return {
        ...state,
        isSeeking: false,
        seekingTime: action.time,
        lastSeekedObject: action.seekedObject,
      };

    case SET_SEEKING:
      return {
        ...state,
        isSeeking: true,
      };

    case SET_PLAYER_REF:
      return {
        ...state,
        playerRef: action.playerRef,
      };

    case TOGGLE_SHUFFLE:
      Mixpanel.track("Toggle shuffle");
      return {
        ...state,
        shuffle: !state.shuffle,
      };

    case TOGGLE_REPEAT:
      let newRepeat;

      Mixpanel.track("Toggle repeat");

      if (state.repeat === "no-repeat") {
        newRepeat = "repeat-playlist";
      } else if (state.repeat === "repeat-playlist") {
        newRepeat = "repeat-one";
      } else if (state.repeat === "repeat-one") {
        newRepeat = "no-repeat";
      }

      return {
        ...state,
        repeat: newRepeat,
      };

    case SET_VOLUME:
      Mixpanel.track("Set volume", {
        newVolume: action.newVolume,
      });

      return {
        ...state,
        currentVolume: action.newVolume,
      };

    case SEEK_COMMENT_TIME:
      if (state.playerRef) {
        state.playerRef.current.seekTo(action.time / 1000, "seconds");
      }

      Mixpanel.track("Seek comment time", {
        name: action.track.name,
        trackId: action.track._id,
        owner: action.track.owner.username,
      });

      return {
        ...state,
        activeTrackObject: action.track,
        currentPlaylist: [action.track],
        currentPlaylistIndex: 0,
        isPlaying: true,
        currentFolderImageURL: action.currentFolderImageURL,
        seekingTime: action.time,
        currentTime: action.time,
        lastSeekedObject: action.seekedObject,
      };

    default:
      return state;
  }
};
