import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { login } from "../../../actions/auth";
import { toast } from "react-toastify";

import { Mixpanel } from "../../../utils/Mixpanel";

import "./style.scss";

const Login = ({ match, login, user }) => {
  const [formData, setFormData] = useState({
    password: "",
  });

  const { password } = formData;

  // Payload from jwt
  const [payload, setPayload] = useState();

  const history = useHistory();

  // Decodes the token from the URL and updates state
  async function decodeURL() {
    try {
      const accessToken = match.params.accessToken;

      const res = await axios.get(`/api/share/${accessToken}`);

      if (res.data.error) throw new Error(res.data.message);
      setPayload(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function acceptInvite() {
    try {
      // Redirect user
      if (payload.shareType === "folder") {
        Mixpanel.track("Accept folder invite", {
          name: payload.name,
          sentFrom: payload.sentFrom,
          user: user ? user.username : "unidentified",
        });

        history.push(`/dashboard/shared`);
      } else if (payload.shareType === "track") {
        Mixpanel.track("Accept track invite", {
          name: payload.name,
          sentFrom: payload.sentFrom,
          user: user ? user.username : "unidentified",
        });

        history.push(`/dashboard/shared`);
      }
    } catch (error) {
      toast.error(error.message);
      history.push("/");
    }
  }

  useEffect(() => {
    decodeURL();
  }, []);

  useEffect(() => {
    if (user) acceptInvite();
  }, [user]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    let email = payload.sentTo;
    login(email, password);
  };

  return (
    <Fragment>
      <div className="mainPage">
        <div className="background" />
        <div className="mainContent">
          <div className="shareLoginScreen">
            <div className="description">
              {payload && payload.shareType !== "track"
                ? `${payload.sentFrom} is inviting you to join their ${payload.shareType} '${payload.name}'. Log in to gain access.`
                : payload
                ? `${payload.sentFrom} is inviting you to collaborate on their track '${payload.name}'. Log in to gain access.`
                : null}
            </div>
            <div className="form">
              <h2 className="title">Log in</h2>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="bottom-16">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={payload ? payload.sentTo : null}
                    className="text-field"
                    disabled
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                    className="text-field"
                  />
                </div>
                <input
                  type="submit"
                  className="button bottom-40 full-width"
                  value="Log in"
                />
              </form>
              <div className="link-text">
                Don't have an account?{" "}
                <Link to={`access/register/${match.params.accessToken}`}>
                  Sign up
                </Link>
              </div>
            </div>

            <div className="logo">catalog</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { login })(Login);
