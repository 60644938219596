import React, { useState } from "react";
import { connect } from "react-redux";
import { login, loadUser } from "../../../../actions/auth";
import { toggleAuthModal } from "../../../../actions/modal";
import { toast } from "react-toastify";
import axios from "axios";
import "../style.scss";

const LoginForm = ({
  login,
  toggleAuthModal,
  setCurrentAuthState,
  authState,
  contextObjectId,
  loadUser,
  user,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);

    toggleAuthModal();

    if (authState === "login-save-to-library") {
      addtoLibraryHandler();
    }
  };

  async function addtoLibraryHandler() {
    try {
      await axios.patch(`/api/submissions/addToLibrary/${contextObjectId}`);

      loadUser();

      toast.success("Saved folder to library");
    } catch (error) {
      console.log("get loops error");
    }
  }

  return (
    <div className="authModal">
      <div className="content-top bottom-16">
        <h2 className="title">Log in to your account</h2>
        <div className="close-icon" onClick={toggleAuthModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>
      <div className="form">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="bottom-16">
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              className="text-field"
            />
          </div>
          <div className="bottom-16">
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              minLength="6"
              className="text-field"
            />
          </div>
          <input
            type="submit"
            className="button bottom-40 full-width"
            value="Log in"
          />
        </form>
        <div className="link-text">
          Don't have an account?{" "}
          <div
            className="link"
            onClick={() =>
              setCurrentAuthState(
                authState === "login" ? "register" : "register-save-to-library"
              )
            }
          >
            Sign up
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { login, toggleAuthModal, loadUser })(
  LoginForm
);
