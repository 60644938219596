import subscribeToPush from './subscribeToPush';
import unsubscribeFromPush from './unsubscribeFromPush';

/**
 * Register all service workers
 */
export default function() {
  window.addEventListener('load', () => {
    navigator?.serviceWorker?.register(`${process.env.PUBLIC_URL}/notificationSw.js`)
    .then(() => {
      if (Notification.permission === 'granted') subscribeToPush();
      else if (Notification.permission === 'denied') unsubscribeFromPush();
    })
    .catch(e => console.log(e));
  })
}