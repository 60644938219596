import React, { useRef } from "react";
import { toggleInviteSubmissionModal } from "../../../actions/modal";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import "./style.scss";

const InviteSubmissionModal = ({ toggleInviteSubmissionModal, user }) => {
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();

    toast.success("Sharing link copied to clipboard");
  }

  return (
    <div className="inviteSubmissionModal">
      <div className="content-top bottom-16">
        <div className="title">Request music</div>
        <div className="close-icon" onClick={toggleInviteSubmissionModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      <div className="content-middle">
        <div className="submission-text">
          Share this link to receive music submissions
        </div>

        <div className="text-field bottom-24">sendmusic.to/{user.username}</div>

        {document.queryCommandSupported("copy") && (
          <div onClick={copyToClipboard}>
            <div className="button copy-link-button">Copy Link</div>
          </div>
        )}
        <textarea
          ref={textAreaRef}
          value={`localhost:3000/submit-to/${user.username}`}
          style={{
            height: "1px",
            width: "1px",
            opacity: "0",
            position: "absolute",
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  toggleInviteSubmissionModal,
})(InviteSubmissionModal);
