import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import TrackList from "../../../components/TrackList";
import BeatLoader from "react-spinners/BeatLoader";
import "./style.scss";
import { toggleUploadTrackModal } from "../../../actions/modal";

const UploadsPage = ({ updateToggle, user, toggleUploadTrackModal }) => {
  const [tracks, setTracks] = useState();
  const [filters, setFilters] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const filterParams = urlParams.get("filters");

  async function fetchTracks() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var res = await axios.get(
        `/api/users/getUploads/${filterParams}`,
        config
      );

      setFilters(res.data.filters);
      setTracks(res.data.tracks);
    } catch (error) {
      console.log("get loops error");
    }
  }

  useEffect(() => {
    if (user) fetchTracks();
  }, [updateToggle, user, filterParams]);

  return (
    <div className="uploads-page page-size">
      <div className="page-container">
        <div className="page-top">
          <div className="page-title">Your Tracks</div>
          {tracks && tracks.length > 0 ? (
            <div
              className="upload-tracks-button button-secondary"
              onClick={() => toggleUploadTrackModal({})}
            >
              Upload tracks
            </div>
          ) : null}
        </div>

        <div className="page-content">
          {tracks ? (
            <TrackList tracks={tracks} filters={filters} canReorder={false} />
          ) : (
            <div className="loader-icon">
              <BeatLoader
                sizeUnit={"px"}
                size={9}
                color={"#fff"}
                loading={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  updateToggle: state.track.updateToggle,
});

export default connect(mapStateToProps, { toggleUploadTrackModal })(
  UploadsPage
);
