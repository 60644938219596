import axios from "axios";

/**
 * Unsubscribe from push notifications
 */
export default async function () {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      removeSubscription: true,
    };

    await axios.post("/api/auth/setWebPushSubscription", body, config);
  } catch (e) {
    console.log(e);
  }
}
