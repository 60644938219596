import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import landingMobile from "../../images/landing-mobile.png";
import landingWeb from "../../images/landing-web.png";
import TopBar from "./TopBar";

import "./style.scss";

import Fade from "react-reveal/Fade"; // Importing Fade effect

const valuePropItems = [
  {
    icon: "lock-closed",
    color: "#0a84ff",
    title: "Private and secure. ",
    description:
      "Increase security and control access for listening and downloads.",
  },
  {
    icon: "radio",
    color: "#6856fe",
    title: "High-quality streams and downloads. ",
    description:
      "All music streams at 320kbps and downloads in the original file quality.",
  },
  {
    icon: "phone-portrait",
    color: "#34c759",
    title: "Mobile listening and access. ",
    description:
      "Listen to your uploads and received tracks anywhere, anytime.",
  },
  {
    icon: "documents",
    color: "#ff2d55",
    title: "Collaborate with your team.  ",
    description: "Create folders as shared spaces for work-in-progress music.",
  },
  {
    icon: "file-tray-full",
    color: "#a027b3",
    title: "Submissions inbox.   ",
    description:
      "Share music to collaborators, A&Rs and curators. Manage all of your sent and received music in one place.",
  },
  {
    icon: "flash",
    color: "#53abee",
    title: "Get instant, actionable feedback.   ",
    description:
      "See notifications when people comment, like or download your music.",
  },
];

const comingSoonItems = [
  {
    icon: "share-social",
    color: "var(--secondaryColor)",
    title: "Social media integration. ",
    description:
      "Link your key profiles like Instagram, TikTok and Spotify to showcase your entire body of work. No need for another link-in-bio service.",
  },
  {
    icon: "analytics",
    color: "var(--secondaryColor)",
    title: "Real-time analytics. ",
    description: "Know when your recipients have listened to your music.",
  },
  {
    icon: "document",
    color: "var(--secondaryColor)",
    title: "Version control. ",
    description:
      "Save multiple versions of your tracks. Get feedback, revisit and iterate before publishing the final version.",
  },
  {
    icon: "mail",
    color: "var(--secondaryColor)",
    title: "SMS & e-mail support. ",
    description:
      "Automatically share your music to collaborators, curators, A&Rs or followers through an integrated text & e-mail service.",
  },
  {
    icon: "cash",
    color: "var(--secondaryColor)",
    title: "Native payment system. ",
    description: "Sell your music using Catalog to grow your music business.",
  },
];

const LandingPage = ({ isAuthenticated }) => {
  const [mainContainerScrollTop, setMainContainerScrollTop] = useState(0);

  const handleMainContainerScroll = (e) => {
    setMainContainerScrollTop(e.target.scrollTop);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to={`/dashboard/library`} />;
  }

  return (
    <div
      className="landing-page-container"
      onScroll={handleMainContainerScroll}
    >
      <TopBar scrollTop={mainContainerScrollTop} />

      <div className="page-header">
        <div className="header-banner">
          {/**<Fade bottom>
            <div className="header-logo-badge">
              <div className="header-logo-letter">c</div>
            </div>
          </Fade> **/}
          <Fade up>
            <div className="header-description">Modern tools for music.</div>
            <div className="header-body">
              Securely send and receive music with real-time control and
              insights - from anywhere, anytime.
            </div>
          </Fade>
          <Fade>
            <Link to="/register" className="button-secondary sign-up-button">
              Get started
            </Link>
          </Fade>
        </div>
      </div>

      <div className="screenshot-section">
        <div className="desktop-screenshot-section">
          <div className="web-screenshot-background"></div>

          <div className="web-screenshot-content">
            <Fade up>
              <div className="text-container">
                <div className="text-title">Simple & Intuitive.</div>
                <div className="text-description">
                  Organize, preview and share your tracks in an intuitive
                  experience designed for musicians, by musicians. Never send
                  another attachment again.
                </div>
              </div>
            </Fade>

            <Fade up>
              <img src={landingWeb} className="web-screenshot"></img>
            </Fade>
          </div>
        </div>
        <div className="mobile-screenshot-section">
          <div className="mobile-screenshot-background"></div>
          <div className="mobile-screenshot-content">
            <Fade up>
              <img src={landingMobile} className="phone-screenshot"></img>
            </Fade>

            <Fade up>
              <div className="text-container">
                <div className="text-title">Seamless & Clean.</div>
                <div className="text-description">
                  Musicians, curators and A&Rs love Catalog’s smooth, high
                  quality listening experience that allows you to preview music
                  before it’s released to Spotify & Apple Music.
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      <div className="value-prop-section">
        <div className="value-prop-content">
          <Fade>
            <div className="value-prop-title">Professional Workflow.</div>
            <div className="value-prop-subtitle">
              Catalog helps you produce, collaborate and grow your music.
            </div>
          </Fade>

          <div className="value-prop-list">
            {valuePropItems.map((valueProp) => (
              <Fade up>
                <div className="value-prop">
                  <div
                    className="value-badge"
                    style={{ background: valueProp.color }}
                  >
                    <ion-icon name={valueProp.icon}></ion-icon>
                  </div>

                  <div className="text-container">
                    <span className="title-text">{valueProp.title}</span>
                    <span className="description-text">
                      {valueProp.description}
                    </span>
                  </div>
                </div>
              </Fade>
            ))}
          </div>

          <div className="coming-soon-text">Coming soon</div>

          <div className="value-prop-list">
            {comingSoonItems.map((item) => (
              <Fade up>
                <div className="value-prop">
                  <div
                    className="value-badge"
                    style={{ background: item.color }}
                  >
                    <ion-icon name={item.icon}></ion-icon>
                  </div>

                  <div className="text-container">
                    <span className="title-text">{item.title}</span>
                    <span className="description-text">{item.description}</span>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(LandingPage);
