import React, { useRef, useState, useEffect } from "react";
import useOutsideClick from "../../../utils/useOutsideClick";
import NotificationItem from "./Notification";

import { connect } from "react-redux";
import axios from "axios";
import subscribeToPush from "../../../utils/subscribeToPush";
import BeatLoader from "react-spinners/BeatLoader";

import { loadUser } from "../../../actions/auth";
import { toast } from "react-toastify";

import "./style.scss";

const NotificationsMenu = ({ closeMenuHandler, user, loadUser }) => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const menuRef = useRef();
  const enableWebPushRef = useRef();

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  const enablePushRef = useRef();

  async function fetchNotifications() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get("/api/users/getNotifications/null", config);
      if (res.data.error) throw new Error(res.data.message);
      setNotifications(res.data);
      setLoading(false);

      if (user.unreadNotificationCount > 0) {
        await readNotifications();
        loadUser();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  async function readNotifications() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post("/api/users/readAllNotifications", config);

      if (res.data.error) throw new Error(res.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  async function enablePushNotifications() {
    const status = await Notification.requestPermission();

    if (status === "granted") {
      enableWebPushRef.current.remove();
      await subscribeToPush();
      loadUser();

      toast.success("You will now receive notification");
    }
  }

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  return (
    <div className="notification-menu-container" ref={menuRef}>
      <div className="menu-top">
        <div className="menu-title">Notifications</div>

        {!user.webPushSubscription ? (
          <div
            className="enable-push-button button-secondary"
            onClick={enablePushNotifications}
            ref={enableWebPushRef}
          >
            Enable push notifications
          </div>
        ) : null}
      </div>
      <div className="notifications-list">
        {!loading ? (
          notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <NotificationItem notification={notification} key={index} />
            ))
          ) : (
            <div className="empty-notifications">
              <div className="empty-title">Nothing to see here - yet.</div>
              Likes, downloads and comments on your tracks will display here.
            </div>
          )
        ) : (
          <div className="loader-icon">
            <BeatLoader
              sizeUnit={"px"}
              size={10}
              color={"#fff"}
              loading={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, { loadUser })(NotificationsMenu);
