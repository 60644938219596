import React, { useState } from "react";

import { loadUser } from "../../../../actions/auth";
import { connect } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { toast } from "react-toastify";

import "./style.scss";

const ChangeProfilePicture = ({ user, loadUser }) => {
  const [uploading, setUploading] = useState(false);

  const uploadImage = async (e) => {
    e.preventDefault();
    setUploading(true);

    const config = {
      headers: {
        "Content-Type": "application/form-data",
      },
    };

    try {
      let formdata = new FormData();
      formdata.append("image", e.target.files[0]);

      const res = await axios.patch(
        "/api/users/updateProfileImage",
        formdata,
        config
      );
      if (res.data.error) throw new Error(res.data.message);

      setUploading(false);

      loadUser();

      toast.success("Image updated");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="profile-picture-settings">
      <div
        className="image-upload"
        style={{
          backgroundImage: `url(${user.profileImageURL})`,
        }}
      >
        {uploading ? (
          <div className="loader-icon">
            <ClipLoader
              sizeUnit={"px"}
              size={24}
              color={"#fff"}
              loading={true}
            />
          </div>
        ) : null}

        <input
          type="file"
          name="file1"
          id="file"
          onChange={(e) => uploadImage(e)}
          accept="image/png, image/jpeg"
          className="inputfile"
        />
        <label htmlFor="file" className="image-label">
          {!user.profileImageURL && !uploading ? user.username.charAt(0) : null}
        </label>
      </div>
      <div className="profile-image-info">
        <div className="profile-name">{user.username}</div>

        <input
          type="file"
          name="file1"
          id="file"
          onChange={(e) => uploadImage(e)}
          accept="image/png, image/jpeg"
          className="inputfile"
        />
        <label htmlFor="file" className="profile-image-change-link">
          Change Profile Picture
        </label>
      </div>
    </div>
  );
};

export default connect(null, { loadUser })(ChangeProfilePicture);
