import React from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { toggleTrackUpdate } from "../../../actions/track";
import axios from "axios";
import {
  toggleAddToFolderModal,
  toggleEditTrackModal,
  toggleShareTrackModal,
} from "../../../actions/modal";
import { toast } from "react-toastify";
import { Mixpanel } from "../../../utils/Mixpanel";

// Context Menu
import { Menu, Item, animation } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "browse-track-menu";

const BrowseTrackMenu = ({
  folder,
  track,
  toggleAddToFolderModal,
  toggleEditTrackModal,
  toggleShareTrackModal,
  toggleTrackUpdate,
  user,
}) => {
  const location = useLocation();

  const removeFromFolderHandler = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const trackId = track._id;
      const folderId = folder._id;

      const res = await axios.delete(
        `/api/folders/removeTrack/${folderId}/${trackId}`,
        config
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success("Removed from folder");

      toggleTrackUpdate();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const downloadHandler = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const trackId = track._id;

    const body = JSON.stringify({ trackId });

    await axios.post("/api/users/addDownload", body, config);
    toggleTrackUpdate();

    Mixpanel.track("Download track", {
      trackId: trackId,
    });
  };

  return (
    <Menu id={MENU_ID} animation={animation.fade}>
      {(track.publicDownloads || user?._id === track.owner?._id) && (
        <Item>
          <a
            className="menu-item"
            download
            href={track.fileURL}
            onClick={() => downloadHandler()}
          >
            Download Track
          </a>
        </Item>
      )}

      {user && (
        <Item onClick={() => toggleAddToFolderModal(track._id)}>
          Add To Folder
        </Item>
      )}
      {location.pathname.includes("folder") && (
        <Item onClick={() => removeFromFolderHandler()}>
          Remove from Folder
        </Item>
      )}

      <Item onClick={() => toggleShareTrackModal(track)}>Share Track</Item>

      {user?._id === track?.owner?._id && (
        <Item onClick={() => toggleEditTrackModal(track)}>Edit Track</Item>
      )}
    </Menu>
  );
};

export default withRouter(
  connect(null, {
    toggleAddToFolderModal,
    toggleEditTrackModal,
    toggleShareTrackModal,
    toggleTrackUpdate,
  })(BrowseTrackMenu)
);
