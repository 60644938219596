import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { loadUser } from "../../../../actions/auth";

const SetColorForm = ({ loadUser, folder }) => {
  const [formData, setFormData] = useState({
    colorCode: "",
  });

  const { colorCode } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function setFolderColor() {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const body = {
        colorCode: colorCode,
      };

      if (!/^#[0-9A-F]{6}$/i.test(colorCode))
        throw new Error(
          "Color must be in HEX Code Format such as 'var(--primary-color)'",
          "danger"
        );

      const res = await axios.patch(
        `/api/folders/updateFolderColor/${folder._id}`,
        body,
        config
      );

      if (res.data.error) throw new Error(res.data.message);
      else {
        toast.success("Color successfully added");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    await setFolderColor();

    loadUser();
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="input-container">
        <div className="input-label">Set color (HEX Code)</div>
        <input
          type="text"
          placeholder={folder.colorCode ? folder.colorCode : "#8657c5"}
          name="colorCode"
          value={colorCode}
          onChange={(e) => onChange(e)}
          className="text-field"
        />
      </div>

      <input
        type="submit"
        className={
          colorCode !== ""
            ? "button save-button"
            : "button save-button disabled-button"
        }
        value="Submit"
      />
    </form>
  );
};

export default connect(null, { loadUser })(SetColorForm);
