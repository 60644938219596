import React, { useCallback, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";
import UploadTrack from "../../Tracks/UploadModalTrack";
import {
  toggleUploadTrackModal,
  toggleUploadStatusModal,
} from "../../../actions/modal";
import { toggleUploadStatus } from "../../../actions/statusbar";

import {
  addUploadTrack,
  removeAllUploadTracks,
  updateUploadTrack,
} from "../../../actions/uploadTrack";

import { toggleFolderUpdate } from "../../../actions/folder";
import { toggleTrackUpdate } from "../../../actions/track";

import { toast } from "react-toastify";

import { Mixpanel } from "../../../utils/Mixpanel";

import "./style.scss";

const Dropzone = ({
  uploadTracks,
  addUploadTrack,
  toggleTrackUpdate,
  toggleUploadTrackModal,
  toggleUploadStatus,
  updateUploadTrack,
  toggleUploadStatusModal,
  uploadContext,
  uploadContextType,
  removeAllUploadTracks,
  user,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((newFile) => {
      addUploadTrack({
        name: newFile.name.slice(0, -4),
        file: newFile,
        id: new Date().getTime(),
        status: "uploading",
      });
    });
  });

  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: "audio/mp3, audio/mpeg, audio/wav, audio/x-wav",
  });

  const { isDragActive } = useDropzone({ onDrop });

  const uploadTrackHandler = async () => {
    toggleUploadTrackModal();
    toggleUploadStatus();

    const uploadTrackProcess = async (track) => {
      const config = {
        headers: {
          "Content-Type": "application/form-data",
        },
      };

      const fileName = track.name;

      let formdata = new FormData();
      formdata.append("track", track.file);
      formdata.append("name", fileName);
      if (track.bpm) formdata.append("bpm", track.bpm);
      if (track.keySignature)
        formdata.append("keySignature", track.keySignature.value);
      if (track.genre) formdata.append("genre", track.genre.value);
      if (track.notes) formdata.append("notes", track.notes);

      if (uploadContextType === "folder" && uploadContext.permissions.canEdit) {
        formdata.append("folder", uploadContext._id);
      }

      const uploadRes = await axios.post("/api/tracks", formdata, config);

      updateUploadTrack({
        id: track.id,
        _id: uploadRes.data._id,
        status: "uploaded",
      });
      toggleTrackUpdate();

      Mixpanel.track("Upload track", {
        name: track.name,
        trackId: track._id,
        user: user.username,
      });
    };

    const promises = uploadTracks.map(uploadTrackProcess);

    await Promise.all(promises);
    toggleUploadStatus();
    toggleUploadStatusModal("close");
    toast.success("All tracks uploaded successfully");
    removeAllUploadTracks();
  };

  return (
    <div className="upload-modal-dropzone">
      <div className="padding-container">
        <div className="dropzone" {...getRootProps()}>
          <input {...getInputProps()} />

          <div className="add-button">
            <ion-icon name="add"></ion-icon>
          </div>

          {isDragActive ? (
            <div className="dropzone-title">Drop the files here ...</div>
          ) : (
            <Fragment>
              <div className="dropzone-title">
                Drop or select tracks to upload
              </div>
              <div className="dropzone-sub-title">Provide MP3 or WAV files</div>
            </Fragment>
          )}
        </div>
      </div>

      {uploadTracks.length > 0 ? (
        <Fragment>
          <div className="attachment-label-row">
            <div className="attachment-label">New Uploads</div>
          </div>
          <div className="track-container">
            {uploadTracks.map((track, index) => (
              <UploadTrack track={track} key={index} index={index} />
            ))}
          </div>
          <div className="upload-bottom-bar">
            <div
              className="button upload-button"
              onClick={() => uploadTrackHandler()}
            >
              {uploadTracks.length === 1
                ? `Upload ${uploadTracks.length} track`
                : `Upload ${uploadTracks.length} tracks`}
            </div>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  uploadTracks: state.uploadTrack.uploadTracks,
  uploadContext: state.modal.uploadContext,
  uploadContextType: state.modal.uploadContextType,
});

export default connect(mapStateToProps, {
  toggleUploadTrackModal,
  addUploadTrack,
  toggleFolderUpdate,
  toggleTrackUpdate,
  removeAllUploadTracks,
  toggleUploadStatus,
  updateUploadTrack,
  toggleUploadStatusModal,
})(Dropzone);
