import { SET_UNREAD_NOTIFICATIONS_STATUS } from '../actions/types';

const initialState = {
  unreadNotifications: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_UNREAD_NOTIFICATIONS_STATUS:
      return {
        ...state,
        unreadNotifications: payload,
      };

    default:
      return state;
  }
};
