import React from "react";
import { connect } from "react-redux";

import { toggleFolderUpdate } from "../../../actions/folder";
import { toast } from "react-toastify";

import axios from "axios";

// Context Menu
import { Menu, Item, animation } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "share-permissions-menu";

const SharePermissionsMenu = ({ folder, user, toggleFolderUpdate }) => {
  const updatePermissionsHandler = async ({
    canEdit,
    canDownload,
    canView,
  }) => {
    try {
      const res = await axios.patch(
        `/api/folders/changeUserPermissions/${folder._id}/${user._id}`,
        { canEdit, canDownload, canView }
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success(`Updated permissions`);
      toggleFolderUpdate();
    } catch (error) {
      toast.warn(`Failed to update`);
    }
  };

  const removeUserHandler = async () => {
    try {
      const res = await axios.delete(
        `/api/folders/removeUser/${folder._id}/${user._id}`
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success(`Removed access from ${user.username}`);
      toggleFolderUpdate();
    } catch (error) {
      toast.warn(`Failed to update`);
    }
  };

  return (
    <Menu id={MENU_ID} animation={animation.fade}>
      <Item
        onClick={() =>
          updatePermissionsHandler({
            canEdit: true,
            canView: true,
            canDownload: true,
          })
        }
      >
        Full Access
      </Item>
      <Item
        onClick={() =>
          updatePermissionsHandler({
            canEdit: false,
            canView: true,
            canDownload: true,
          })
        }
      >
        View and download
      </Item>
      <Item
        onClick={() =>
          updatePermissionsHandler({
            canEdit: false,
            canView: true,
            canDownload: false,
          })
        }
      >
        View only
      </Item>
      <Item onClick={() => removeUserHandler()}>Remove</Item>
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  user: state.contextMenu.contextMenuObject,
  folder: state.modal.shareFolderObject,
});

export default connect(mapStateToProps, { toggleFolderUpdate })(
  SharePermissionsMenu
);
