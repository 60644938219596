import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { contextMenu } from "react-contexify";
import { setContextMenuObject } from "../../../../actions/contextMenu";

const PendingInvite = ({ invite, setContextMenuObject }) => {
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    setPermissions(invite.permissions);
  }, [invite.permissions]);

  // Display context menu
  function displayMenu(e) {
    setContextMenuObject(invite, "pending-invite");

    contextMenu.show({
      id: "pending-invite-permissions-menu",
      event: e,
    });
  }

  return (
    <div>
      <div className="member-item">
        <div className="member-info">
          <div className="member-icon-inactive"></div>
          <div className="member-name-inactive">{invite.email}</div>
        </div>
        <div className="member-permissions" onClick={(e) => displayMenu(e)}>
          <div className="permission-container">
            <div className="member-permission-text">
              {permissions && permissions.canEdit ? "Full Access" : null}
              {permissions &&
              !permissions.canEdit &&
              permissions.canDownload &&
              permissions.canView
                ? "View and download"
                : null}
              {permissions &&
              !permissions.canEdit &&
              !permissions.canDownload &&
              permissions.canView
                ? "View only"
                : null}
            </div>
            <ion-icon name="chevron-down-outline"></ion-icon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { setContextMenuObject })(PendingInvite);
