import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { register, loadUser } from "../../../actions/auth";

import { toast } from "react-toastify";
import "./style.scss";

import { Mixpanel } from "../../../utils/Mixpanel";

const Register = ({ match, register, user, loadUser }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    password2: "",
  });

  const { username, password, password2 } = formData;

  // Payload from jwt
  const [payload, setPayload] = useState();

  const history = useHistory();

  // Decodes the token from the URL and updates state
  async function decodeURL() {
    try {
      const accessToken = match.params.accessToken;
      const res = await axios.get(`/api/share/${accessToken}`);

      if (res.data.error) throw new Error(res.data.message);
      setPayload(res.data);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function acceptInvite() {
    try {
      const res = await axios.patch(
        `/api/share/acceptInvite/${match.params.accessToken}`,
        {}
      );

      if (res.data.error) throw new Error(res.data.message);

      // Redirect user
      if (payload.shareType === "folder") {
        Mixpanel.track("Accept folder invite", {
          name: payload.name,
          sentFrom: payload.sentFrom,
          user: user ? user.username : "unidentified",
        });

        history.push(`/dashboard/folder/${payload.shareId}`);
      } else if (payload.shareType === "track") {
        Mixpanel.track("Accept track invite", {
          name: payload.name,
          sentFrom: payload.sentFrom,
          user: user ? user.username : "unidentified",
        });

        history.push(`/dashboard/track/${payload.shareId}`);
      }

      loadUser();
    } catch (error) {
      toast.error(error.message);

      history.push("/");
    }
  }

  useEffect(() => {
    decodeURL();
  }, []);

  useEffect(() => {
    if (user) acceptInvite();
  }, [user]);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      toast.error("Passwords do not match");
    } else {
      let email = payload.sentTo;
      register({ username, email, password });
    }
  };

  return (
    <Fragment>
      <div className="mainPage">
        <div className="background" />
        <div className="mainContent">
          <div className="share-registerScreen">
            <div className="description">
              {payload && payload.shareType !== "track"
                ? `${payload.sentFrom} is inviting you to join their ${payload.shareType} '${payload.name}'. Register to gain access.`
                : payload
                ? `${payload.sentFrom} is inviting you to collaborate on their track '${payload.name}'. Register to gain access.`
                : null}
            </div>
            <div className="form">
              <h2 className="title">Sign up</h2>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="bottom-16">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={payload ? payload.sentTo : null}
                    className="text-field"
                    disabled
                  />
                </div>

                <div className="bottom-16">
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => onChange(e)}
                    name="username"
                    className="text-field"
                  />
                </div>

                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="password2"
                    value={password2}
                    onChange={(e) => onChange(e)}
                    className="text-field"
                  />
                </div>
                <input
                  type="submit"
                  className="button bottom-40 full-width"
                  value="Sign up"
                />
              </form>
              <div className="link-text">
                Already have an account?{" "}
                <Link to={`/access/login/${match.params.accessToken}`}>Log In</Link>
              </div>
            </div>

            <div className="logo">catalog</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { register, loadUser })(Register);
