import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

const TopBar = ({ scrollTop }) => {
  return (
    <div
      className={
        scrollTop > 24 ? "landing-top-bar scrolled-top-bar" : "landing-top-bar "
      }
    >
      <div className="top-bar-container">
        <div className="left">
          <Link to="/" className="logo">
            catalog
          </Link>
        </div>
        <div className="right">
          <Link to="/login" className="login-button">
            Log in
          </Link>
          <Link to="/register" className="signup-button button-secondary">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
