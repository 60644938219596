// ALERT
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const TOGGLE_FIREFOX_BANNER = "TOGGLE_FIREFOX_BANNER";

// AUTH / USER
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const USER_TOGGLE_UPDATE = "USER_TOGGLE_UPDATE";

// MODAL
export const DISABLE_ALL_MODAL = "DISABLE_ALL_MODAL";
export const TOGGLE_AUTH_MODAL = "TOGGLE_AUTH_MODAL";
export const TOGGLE_SETTINGS_MODAL = "TOGGLE_SETTINGS_MODAL";
export const TOGGLE_CREATE_FOLDER_MODAL = "TOGGLE_CREATE_FOLDER_MODAL";
export const TOGGLE_ADD_TO_FOLDER_MODAL = "TOGGLE_ADD_TO_FOLDER_MODAL";
export const TOGGLE_EDIT_TRACK_MODAL = "TOGGLE_EDIT_TRACK_MODAL";
export const TOGGLE_UPLOAD_TRACK_MODAL = "TOGGLE_UPLOAD_TRACK_MODAL";
export const TOGGLE_SHARE_TRACK_MODAL = "TOGGLE_SHARE_TRACK_MODAL";
export const TOGGLE_SHARE_FOLDER_MODAL = "TOGGLE_SHARE_FOLDER_MODAL";
export const TOGGLE_DELETE_OBJECT_MODAL = "TOGGLE_DELETE_OBJECT_MODAL";
export const TOGGLE_SHOW_DOWNLOADS_MODAL = "TOGGLE_SHOW_DOWNLOADS_MODAL";
export const TOGGLE_ADD_FROM_CATALOG_MODAL = "TOGGLE_ADD_FROM_CATALOG_MODAL";
export const TOGGLE_UPLOAD_STATUS_MODAL = "TOGGLE_UPLOAD_STATUS_MODAL";
export const TOGGLE_CREATE_REQUEST_MODAL = "TOGGLE_CREATE_REQUEST_MODAL";
export const TOGGLE_ADD_TO_SUBMISSION_MODAL = "TOGGLE_ADD_TO_SUBMISSION_MODAL";
export const TOGGLE_ACCOUNT_SETTINGS_MODAL = "TOGGLE_ACCOUNT_SETTINGS_MODAL";
export const TOGGLE_FOLDER_SETTINGS_MODAL = "TOGGLE_FOLDER_SETTINGS_MODAL";
export const TOGGLE_VIEW_TRACK_MODAL = "TOGGLE_VIEW_TRACK_MODAL";
export const TOGGLE_MOVE_FOLDER_MODAL = "TOGGLE_MOVE_FOLDER_MODAL";

// TRACK
export const TOGGLE_TRACK_UPDATE = "TOGGLE_TRACK_UPDATE";

// FOLDER
export const TOGGLE_FOLDER_UPDATE = "TOGGLE_FOLDER_UPDATE";
export const SET_CURRENT_FOLDER_COLOR = "SET_CURRENT_FOLDER_COLOR";
export const SET_INVITE_PERMISSION = "SET_INVITE_PERMISSION";
export const TOGGLE_ARTIST_NAME_DISPLAYED = "TOGGLE_ARTIST_NAME_DISPLAYED";
export const SET_ARTIST_NAME_DISPLAYED = "SET_ARTIST_NAME_DISPLAYED";

// PLAYER
export const SELECT_TRACK = "SELECT_TRACK";
export const LOAD_TRACK = "LOAD_TRACK";
export const PLAY_FIRST = "PLAY_FIRST";
export const PLAY_NEXT = "PLAY_NEXT";
export const PLAY_PREVIOUS = "PLAY_PREVIOUS";
export const TOGGLE_PLAY = "TOGGLE_PLAY";
export const SET_CURRENT_TIME = "SET_CURRENT_TIME";
export const SET_CURRENT_DURATION = "SET_CURRENT_DURATION";
export const SEEK = "SEEK";
export const SET_SEEKING = "SET_SEEKING";
export const SET_PLAYER_REF = "SET_PLAYER_REF";
export const TOGGLE_SHUFFLE = "TOGGLE_SHUFFLE";
export const PLAY_SHUFFLE = "PLAY_SHUFFLE";
export const TOGGLE_REPEAT = "TOGGLE_REPEAT";
export const PLAY_ON_END = "PLAY_ON_END";
export const SET_VOLUME = "SET_VOLUME";
export const SEEK_COMMENT_TIME = "SEEK_COMMENT_TIME";

// NOTIFICATIONS
export const SET_UNREAD_NOTIFICATIONS_STATUS =
  "SET_UNREAD_NOTIFICATIONS_STATUS";

// UPLOAD TRACK
export const ADD_UPLOAD_TRACK = "ADD_UPLOAD_TRACK";
export const REMOVE_UPLOAD_TRACK = "REMOVE_UPLOAD_TRACK";
export const UPDATE_UPLOAD_TRACK = "UPDATE_UPLOAD_TRACK";
export const REMOVE_ALL_UPLOAD_TRACKS = "REMOVE_ALL_UPLOAD_TRACKS";

// STATUS BAR
export const TOGGLE_UPLOAD_STATUS = "TOGGLE_UPLOAD_STATUS";

// CONTEXT OBJECT
export const SET_CONTEXT_OBJECT = "SET_CONTEXT_OBJECT";

// SUBMISSIONS
export const SELECT_SUBMISSION_CATALOG_TRACKS =
  "SELECT_SUBMISSION_CATALOG_TRACKS";
export const REMOVE_SUBMISSION_CATALOG_TRACK =
  "REMOVE_SUBMISSION_CATALOG_TRACK";
export const REMOVE_SUBMISSION_UPLOAD_FILE = "REMOVE_SUBMISSION_UPLOAD_FILE";
export const ADD_SUBMISSION_UPLOAD_FILE = "ADD_SUBMISSION_UPLOAD_FILE";
export const TOGGLE_SUBMISSION_UPDATE = "TOGGLE_SUBMISSION_UPDATE";
export const INCREMENT_TRACKS_UPLOADED = "INCREMENT_TRACKS_UPLOADED";
export const SET_UPLOADING = "SET_UPLOADING";
export const TOGGLE_INVITE_SUBMISSION_MODAL = "TOGGLE_INVITE_SUBMISSION_MODAL";

// TRACK MODAL
export const SET_ACTIVE_COMMENT_REPLY = "SET_ACTIVE_COMMENT_REPLY";

// CONTEXT MENU
export const SET_CONTEXT_MENU_OBJECT = "SET_CONTEXT_MENU_OBJECT";
