import React from "react";
import { connect } from "react-redux";
import {
  toggleEditTrackModal,
  toggleDeleteObjectModal,
} from "../../../../actions/modal";

const DeleteTrack = ({
  track,
  toggleEditTrackModal,
  toggleDeleteObjectModal,
}) => {
  const deleteTrackModalHandler = () => {
    toggleEditTrackModal();
    toggleDeleteObjectModal(track, "track");
  };

  return (
    <div className="delete-track-container">
      <div
        className="button save-button"
        onClick={() => deleteTrackModalHandler()}
      >
        Delete Track
      </div>
    </div>
  );
};

export default connect(null, { toggleEditTrackModal, toggleDeleteObjectModal })(
  DeleteTrack
);
