import React, { useState } from "react";
import { connect } from "react-redux";

import { toggleInviteSubmissionModal } from "../../../actions/modal";

import InboxList from "./InboxList";
import SentList from "./SentList";
import "./style.scss";

const SubmissionsPage = ({ match, updateToggle, user }) => {
  const [tab, setTab] = useState(
    match.params.tab === "sent" ? "sent" : "inbox"
  );

  return (
    <div className="submissions-list-page page-size">
      <div className="page-container">
        <div className="page-top">
          <div className="page-top-container">
            <div className="page-details">
              <div className="page-title">
                {tab === "inbox" ? "Inbox" : "Sent"}
              </div>
              {/** {tab === "inbox" ? (
                <div
                  className="button-secondary button-right"
                  onClick={toggleInviteSubmissionModal}
                >
                  Request music
                </div>
              ) : null} **/}
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="submissions-list">
            <div className="tab-container">
              <div
                className={tab === "inbox" ? "tab tab-active" : "tab"}
                onClick={() => setTab("inbox")}
              >
                Inbox
              </div>
              <div
                className={tab === "sent" ? "tab tab-active" : "tab"}
                onClick={() => setTab("sent")}
              >
                Sent
              </div>
            </div>
            {tab === "inbox" ? (
              <InboxList updateToggle={updateToggle} user={user} />
            ) : null}
            {tab === "sent" ? <SentList updateToggle={updateToggle} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  updateToggle: state.submission.updateToggle,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  toggleInviteSubmissionModal,
})(SubmissionsPage);
