import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import axios from "axios";
import SentSubmission from "../../../../components/Submission/SentSubmission";

import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";

const InboxList = ({ updateToggle }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [submissionList, setSubmissionList] = useState();

  async function fetchSubmissionList() {
    setIsFetching(true);
    try {
      const res = await axios.get(`/api/submissions/sentSubmissions`);

      await setSubmissionList(res.data);

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      toast.error("Error getting inbox");
    }
  }

  useEffect(() => {
    fetchSubmissionList();
  }, [updateToggle]);

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();

    toast.success("Sharing link copied to clipboard");
  }

  return (
    <div>
      {!isFetching ? (
        <div className="submission-list">
          {submissionList && submissionList.length > 0 ? (
            submissionList.map((submission, index) => (
              <SentSubmission
                key={index}
                submission={submission}
                tab={"sent"}
              />
            ))
          ) : (
            <div className="empty-state-container">
              <div className="empty-info">
                <div className="empty-state-title">
                  You haven't sent any music yet.
                </div>
                <div className="empty-state-text">
                  Music you send will show here.
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="empty-state-container">
          <div className="loading-icon">
            <BeatLoader
              sizeUnit={"px"}
              size={9}
              color={"#fff"}
              loading={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect()(InboxList);
