import {
  SELECT_TRACK,
  LOAD_TRACK,
  PLAY_NEXT,
  PLAY_PREVIOUS,
  TOGGLE_PLAY,
  SET_CURRENT_DURATION,
  SET_CURRENT_TIME,
  SEEK,
  SET_SEEKING,
  SET_PLAYER_REF,
  TOGGLE_SHUFFLE,
  PLAY_SHUFFLE,
  TOGGLE_REPEAT,
  PLAY_ON_END,
  SET_VOLUME,
  SEEK_COMMENT_TIME,
} from "./types";

// TODO: look into streamlining selectTrack and loadTrack
export const selectTrack = (
  track,
  currentPlaylist,
  currentPlaylistIndex,
  currentFolderImageURL
) => {
  return {
    type: SELECT_TRACK,
    track,
    currentPlaylist,
    currentPlaylistIndex,
    currentFolderImageURL,
  };
};

export const loadTrack = (
  track,
  currentPlaylist,
  currentPlaylistIndex,
  currentFolderImageURL
) => {
  return {
    type: LOAD_TRACK,
    track,
    currentPlaylist,
    currentPlaylistIndex,
    currentFolderImageURL,
  };
};

export const playNext = () => {
  return {
    type: PLAY_NEXT,
  };
};

export const playShuffle = () => {
  return {
    type: PLAY_SHUFFLE,
  };
};

export const playPrevious = () => {
  return {
    type: PLAY_PREVIOUS,
  };
};

export const togglePlay = () => {
  return {
    type: TOGGLE_PLAY,
  };
};

export const setCurrentDuration = (duration) => {
  return {
    type: SET_CURRENT_DURATION,
    duration,
  };
};

export const setCurrentTime = (time) => {
  return {
    type: SET_CURRENT_TIME,
    time,
  };
};

export const seek = (seekedObject) => {
  return {
    type: SEEK,
    time: seekedObject.time,
    seekedObject,
  };
};

export const setSeeking = () => {
  return {
    type: SET_SEEKING,
  };
};

export const setPlayerRef = (playerRef) => {
  return {
    type: SET_PLAYER_REF,
    playerRef,
  };
};

export const toggleShuffle = () => {
  return {
    type: TOGGLE_SHUFFLE,
  };
};

export const toggleRepeat = () => {
  return {
    type: TOGGLE_REPEAT,
  };
};

export const playOnEnd = () => {
  return {
    type: PLAY_ON_END,
  };
};

export const setVolume = (newVolume) => {
  return {
    type: SET_VOLUME,
    newVolume: newVolume,
  };
};

export const seekCommentTime = (track, currentFolderImageURL, seekedObject) => {
  return {
    type: SEEK_COMMENT_TIME,
    track,
    currentFolderImageURL,
    time: seekedObject.time,
    seekedObject,
  };
};
