import {
  DISABLE_ALL_MODAL,
  TOGGLE_AUTH_MODAL,
  TOGGLE_SETTINGS_MODAL,
  TOGGLE_CREATE_FOLDER_MODAL,
  TOGGLE_ADD_TO_FOLDER_MODAL,
  TOGGLE_EDIT_TRACK_MODAL,
  TOGGLE_SHARE_TRACK_MODAL,
  TOGGLE_UPLOAD_TRACK_MODAL,
  TOGGLE_SHARE_FOLDER_MODAL,
  TOGGLE_DELETE_OBJECT_MODAL,
  TOGGLE_SHOW_DOWNLOADS_MODAL,
  TOGGLE_ADD_FROM_CATALOG_MODAL,
  TOGGLE_UPLOAD_STATUS_MODAL,
  TOGGLE_CREATE_REQUEST_MODAL,
  TOGGLE_ADD_TO_SUBMISSION_MODAL,
  TOGGLE_INVITE_SUBMISSION_MODAL,
  TOGGLE_ACCOUNT_SETTINGS_MODAL,
  TOGGLE_FOLDER_SETTINGS_MODAL,
  TOGGLE_VIEW_TRACK_MODAL,
  TOGGLE_MOVE_FOLDER_MODAL,
} from "./types";

export const disableAllModal = () => {
  return {
    type: DISABLE_ALL_MODAL,
  };
};

export const toggleAuthModal = (authState) => {
  return {
    type: TOGGLE_AUTH_MODAL,
    authState,
  };
};

export const toggleSettingsModal = () => {
  return {
    type: TOGGLE_SETTINGS_MODAL,
  };
};

export const toggleCreateFolderModal = (uploadContext, uploadContextType) => {
  return {
    type: TOGGLE_CREATE_FOLDER_MODAL,
    uploadContext,
    uploadContextType,
  };
};

export const toggleAddToFolderModal = (trackId) => {
  return {
    type: TOGGLE_ADD_TO_FOLDER_MODAL,
    payload: trackId,
  };
};

export const toggleEditTrackModal = (track) => {
  return {
    type: TOGGLE_EDIT_TRACK_MODAL,
    payload: track,
  };
};

export const toggleUploadTrackModal = (uploadContext, uploadContextType) => {
  return {
    type: TOGGLE_UPLOAD_TRACK_MODAL,
    uploadContext,
    uploadContextType,
  };
};

export const toggleShareTrackModal = (track = null) => {
  return {
    type: TOGGLE_SHARE_TRACK_MODAL,
    payload: track,
  };
};

export const toggleShareFolderModal = (folder = null) => {
  return {
    type: TOGGLE_SHARE_FOLDER_MODAL,
    payload: folder,
  };
};

export const toggleDeleteObjectModal = (object, type) => {
  return {
    type: TOGGLE_DELETE_OBJECT_MODAL,
    payload: { object: object, type: type },
  };
};

export const toggleShowDownloadsModal = (trackId) => {
  return {
    type: TOGGLE_SHOW_DOWNLOADS_MODAL,
    payload: trackId,
  };
};

export const toggleAddFromCatalogModal = (folderId) => {
  return {
    type: TOGGLE_ADD_FROM_CATALOG_MODAL,
    payload: {
      addFromCatalogFolderId: folderId,
    },
  };
};

export const toggleUploadStatusModal = (payload) => {
  return {
    type: TOGGLE_UPLOAD_STATUS_MODAL,
    payload,
  };
};

export const toggleCreateRequestModal = () => {
  return {
    type: TOGGLE_CREATE_REQUEST_MODAL,
  };
};

export const toggleAddToSubmissionModal = () => {
  return {
    type: TOGGLE_ADD_TO_SUBMISSION_MODAL,
  };
};

export const toggleInviteSubmissionModal = () => {
  return {
    type: TOGGLE_INVITE_SUBMISSION_MODAL,
  };
};

export const toggleAccountSettingsModal = () => {
  return {
    type: TOGGLE_ACCOUNT_SETTINGS_MODAL,
  };
};

export const toggleFolderSettingsModal = (folder) => {
  return {
    type: TOGGLE_FOLDER_SETTINGS_MODAL,
    payload: folder,
  };
};

export const toggleViewTrackModal = (
  trackId,
  currentPlaylist,
  playlistIndex,
  currentFolderImageURL
) => {
  return {
    type: TOGGLE_VIEW_TRACK_MODAL,
    trackId,
    currentPlaylist,
    playlistIndex,
    currentFolderImageURL,
  };
};

export const toggleMoveFolderModal = (folderId) => {
  return {
    type: TOGGLE_MOVE_FOLDER_MODAL,
    payload: folderId,
  };
};
