import { SET_CONTEXT_OBJECT } from "../actions/types";

const initialState = {
  contextObject: {},
  contextType: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CONTEXT_OBJECT:
      return {
        ...state,
        contextObject: action.contextObject,
        contextType: action.contextType,
      };

    default:
      return state;
  }
}
