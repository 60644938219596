import { TOGGLE_UPLOAD_STATUS } from "../actions/types";

const initialState = {
  statusBarOpen: false,
  uploadStatusOpen: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_UPLOAD_STATUS:
      return {
        ...state,
        statusBarOpen: !state.statusBarOpen,
        uploadStatusOpen: !state.uploadStatusOpen,
      };

    default:
      return state;
  }
}
