import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import registerSw from './utils/registerSw';

registerSw();

// axios.interceptors.request.use((config) => {
//   if (!config.params) config.params = {};
    
//   const folderIdSearch = window.location.pathname.match("/folder/[0-9|a-z]{24}");
//   const folderId = folderIdSearch ? folderIdSearch[0].substr(8, 24) : null;
    
//   const trackIdSearch = window.location.pathname.match("/track/[0-9|a-z]{24}");
//   const trackId = trackIdSearch ? trackIdSearch[0].substr(7, 24) : null;
  
//   if (folderId) config.params['folder'] = folderId;
//   if (trackId) config.params['track'] = trackId;
  
//   return config;
// });

axios.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
        config.headers["x-auth-token"] = accessToken;
    }

    return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        let originalRequest = error.config;
        const refreshToken = localStorage.getItem("refreshToken");
        if (refreshToken && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            return axios
                .post(`/api/auth/refresh_token`, { refreshToken })
                .then((res) => {
                    if (res.status === 200) {
                        try {
                            localStorage.setItem("accessToken", res.data.accessToken);
                            console.log("Access token refreshed");
                            return axios(originalRequest);
                        } catch (err) {
                            console.log("error saving accessToken", err);
                            return Promise.reject(error);
                        }
                    }
                });
        }

        return Promise.reject(error);
    }
);

ReactDOM.render(<App />, document.getElementById('root'));
