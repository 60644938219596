import React, { useState } from "react";

import { toggleTrackUpdate } from "../../../../actions/track";
import { toast } from "react-toastify";

import { connect } from "react-redux";
import axios from "axios";
import "./style.scss";
import moment from "moment";

const CommentBox = ({ trackId, toggleTrackUpdate, lastSeekedObject }) => {
  const [formData, setFormData] = useState({
    newComment: "",
  });

  const [timeStampActive, setTimeStampActive] = useState(false);

  const { newComment } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const sendComment = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (newComment !== "") {
        let body;

        if (timeStampActive) {
          body = JSON.stringify({
            text: newComment,
            timeStamp: lastSeekedObject
              ? lastSeekedObject.id === trackId
                ? lastSeekedObject.time
                : "0"
              : "0",
          });
        } else {
          body = JSON.stringify({
            text: newComment,
          });
        }

        await axios.post(`/api/comments/add/${trackId}`, body, config);

        toggleTrackUpdate();
        setFormData({
          newComment: "",
        });
      } else {
        toast.error("First add a comment");
      }
    } catch (error) {
      console.log("failed");
    }
  };

  return (
    <div className="track-modal-comment-input-container">
      <div className="input-top">
        <textarea
          type="text"
          placeholder="Add a comment..."
          value={newComment}
          onChange={(e) => onChange(e)}
          name="newComment"
          className="comment-text-field"
        />
      </div>
      <div className="button-row">
        <div
          className="comment-timestamp"
          style={{ color: timeStampActive ? "#fff" : "#b3b3b3" }}
        >
          <div className="comment-timestamp-left">
            <div className="time-icon">
              <ion-icon name="time-outline"></ion-icon>
            </div>
            {lastSeekedObject
              ? lastSeekedObject.id === trackId
                ? moment(lastSeekedObject.time).format("m:ss")
                : moment(0).format("m:ss")
              : moment(0).format("m:ss")}
          </div>
          <div
            className="select-timestamp-button"
            onClick={() => setTimeStampActive(!timeStampActive)}
          >
            {timeStampActive ? (
              <div className="timestamp-active-icon">
                <ion-icon name="checkbox"></ion-icon>
              </div>
            ) : (
              <div className="timestamp-disabled-icon">
                <ion-icon name="square-outline"></ion-icon>
              </div>
            )}
          </div>
        </div>
        {newComment !== "" ? (
          <div className="add-comment-button" onClick={() => sendComment()}>
            Send
          </div>
        ) : (
          <div className="add-comment-button-disabled">Send</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  lastSeekedObject: state.player.lastSeekedObject,
});

export default connect(mapStateToProps, {
  toggleTrackUpdate,
})(CommentBox);
