import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toggleViewTrackModal } from "../../../actions/modal";
import { togglePlay, selectTrack, loadTrack } from "../../../actions/player";
import { toast } from "react-toastify";
import { setActiveCommentReply } from "../../../actions/trackModal";
import { toggleTrackUpdate } from "../../../actions/track";
import Seekbar from "../../AudioPlayer/Seekbar";
import CommentBox from "./CommentBox";
import Comment from "./Comment";

import "./style.scss";

import { Mixpanel } from "../../../utils/Mixpanel";

const TrackModal = ({
  toggleViewTrackModal,
  currentDuration,
  currentTime,
  isPlaying,
  togglePlay,
  user,
  updateToggle,
  trackId,
  activeTrackObject,
  selectTrack,
  loadTrack,
  setActiveCommentReply,
  toggleTrackUpdate,
}) => {
  const [track, setTrack] = useState({});
  const [liked, setLiked] = useState();

  async function fetchTrack() {
    try {
      const res = await axios.get(`/api/tracks/${trackId}`);

      if (res.data.error) throw new Error(res.data.message);

      const track = res.data;
      setTrack(track);
      loadTrack(track, [track], 0, null);

      if (user) {
        setLiked(res.data.likedUsers.includes(user._id) ? true : false);
      } else {
        setLiked(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    fetchTrack();
  }, [updateToggle, user, trackId]);

  const selectTrackHandler = () => {
    if (activeTrackObject._id !== track._id) {
      selectTrack(track, [track], 0, null);
    } else {
      togglePlay(currentTime);
    }
  };

  const toggleViewTrackModalHandler = () => {
    toggleViewTrackModal();
    setActiveCommentReply(null);
  };

  const likeHandler = async (e) => {
    e.stopPropagation();
    const trackId = track._id;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!liked) {
      toast.success("Added to your favorites");

      Mixpanel.track("Add track to your favorites", {
        trackId: trackId,
      });
    } else {
      toast.success("Removed from your favorites");

      Mixpanel.track("Remove track from favourites", {
        trackId: trackId,
      });
    }

    setLiked(!liked);

    const body = JSON.stringify({ trackId });

    await axios.post("/api/users/toggleLike", body, config);

    toggleTrackUpdate();
  };

  return (
    <div className="track-modal">
      {track ? (
        <Fragment>
          <div className="track-section">
            <div className="track-section-content">
              <div className="track-top">
                {/** currentFolderImageURL ? (
                  <div
                    className="track-image"
                    style={{
                      backgroundImage: `url(${currentFolderImageURL})`,
                    }}
                  ></div>
                  ) : null **/}

                <div className="track-info">
                  {/** <div className="version-button">
                    V1{" "}
                  <ion-icon name="chevron-down-outline"></ion-icon>
                  </div> **/}
                  <div className="track-title">{track.name}</div>
                  <div className="profile-container">
                    {!track.owner ? null : track.owner.profileImageURL ? (
                      <div
                        className="profile-image"
                        style={{
                          backgroundImage: `url(${track.owner.profileImageURL})`,
                        }}
                      ></div>
                    ) : (
                      <div className="profile-image">
                        {track.owner.username.charAt(0)}
                      </div>
                    )}
                    <div className="user-name">
                      {track.owner ? track.owner.username : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="track-bottom">
                <div className="buttons-row">
                  <div
                    className="play-button"
                    onClick={() => selectTrackHandler()}
                  >
                    {isPlaying && activeTrackObject._id === track._id ? (
                      <div className="pause-icon">
                        <ion-icon name="pause-sharp"></ion-icon>
                      </div>
                    ) : (
                      <div className="play-icon">
                        <ion-icon name="play-sharp"></ion-icon>
                      </div>
                    )}
                  </div>
                  {user ? (
                    <div
                      className={
                        liked ? "like-button like-button-active" : "like-button"
                      }
                      onClick={(e) => likeHandler(e)}
                    >
                      {liked ? (
                        <ion-icon name="heart"></ion-icon>
                      ) : (
                        <ion-icon name="heart-outline"></ion-icon>
                      )}
                    </div>
                  ) : null}
                  {/**  
                  <div className="ellipsis-button">
                    <ion-icon name="ellipsis-horizontal"></ion-icon>
                  </div> **/}
                </div>

                <div className="progressBar-container">
                  <div className="seekBar-container">
                    <Seekbar
                      currentTime={
                        activeTrackObject._id === track._id ? currentTime : null
                      }
                      currentDuration={
                        activeTrackObject._id === track._id
                          ? currentDuration
                          : null
                      }
                      trackId={activeTrackObject._id}
                    />
                  </div>
                </div>
              </div>
            </div>
            <CommentBox
              trackId={track._id}
              activeTrackObjectId={activeTrackObject._id}
              currentTime={currentTime}
            />
          </div>
          <div className="comments-section">
            <div className="comment-header">
              <div className="comment-label">
                {/** track.comments
                  ? track.comments.length === 1
                    ? "1 Comment"
                    : `${track.comments.length} Comments`
                : null **/}
                Comments
              </div>
              <div className="comment-header-right">
                <div
                  className="close-icon"
                  onClick={() => toggleViewTrackModalHandler()}
                >
                  <ion-icon name="close"></ion-icon>
                </div>
              </div>
            </div>
            <div className="comment-container">
              {track && track.comments ? (
                track.comments.length > 0 ? (
                  track.comments.map((comment) => (
                    <Fragment>
                      <Comment
                        comment={comment}
                        key={comment._id}
                        type={"comment"}
                        reply={{}}
                        track={track}
                      />
                      {comment.replies
                        ? comment.replies.map((reply) => (
                            <Comment
                              type={"reply"}
                              comment={comment}
                              key={reply._id}
                              reply={reply}
                              track={track}
                            />
                          ))
                        : null}
                    </Fragment>
                  ))
                ) : (
                  <div className="comments-empty-state">
                    <div className="comments-empty-title">
                      No comments added - yet.
                    </div>
                    <div className="comments-empty-text">
                      When added, comments will show here.
                    </div>
                  </div>
                )
              ) : null}
            </div>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  currentDuration: state.player.currentDuration,
  currentTime: state.player.currentTime,
  isPlaying: state.player.isPlaying,
  updateToggle: state.track.updateToggle,
  trackId: state.modal.viewTrackId,
  currentFolderImageURL: state.player.currentFolderImageURL,
  activeTrackObject: state.player.activeTrackObject,
  currentSeekingTime: state.player.currentSeekingTime,
});

export default connect(mapStateToProps, {
  toggleViewTrackModal,
  togglePlay,
  selectTrack,
  loadTrack,
  setActiveCommentReply,
  toggleTrackUpdate,
})(TrackModal);
