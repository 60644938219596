import React from "react";
import { toggleDeleteObjectModal } from "../../../actions/modal";
import { connect } from "react-redux";
import { toggleTrackUpdate } from "../../../actions/track";
import { toggleFolderUpdate } from "../../../actions/folder";
import { loadUser } from "../../../actions/auth";
import { withRouter } from "react-router-dom";
import createRequestQuery from "../../../utils/createRequestQuery";
import { toast } from "react-toastify";

import axios from "axios";
import "./style.scss";

const DeleteModal = ({
  toggleDeleteObjectModal,
  deleteObject,
  toggleTrackUpdate,
  toggleFolderUpdate,
  history,
  loadUser,
}) => {
  const deleteObjectHandler = () => {
    if (deleteObject.type === "track") {
      deleteTrackHandler();
    } else if (deleteObject.type === "folder") {
      deleteFolderHandler();
    } else if (deleteObject.type === "remove-folder") {
      removeFolderHandler();
    }
  };

  const deleteTrackHandler = async () => {
    toggleDeleteObjectModal();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.delete(
        `/api/tracks/${deleteObject.object._id}${createRequestQuery(
          deleteObject.object
        )}`,
        config
      );

      toggleTrackUpdate();

      toast.success("Deleted track");
    } catch (error) {
      toast.error("Failed to delete track");
    }
  };

  const deleteFolderHandler = async () => {
    toggleDeleteObjectModal();

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.patch(
        `/api/folders/moveToTrash/${deleteObject.object._id}`,
        config
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success("Folder deleted");

      toggleFolderUpdate();

      history.push("/dashboard/library");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const removeFolderHandler = async () => {
    toggleDeleteObjectModal();

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.patch(
        `/api/folders/removeCurrentUser/${deleteObject.object._id}`,
        config
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success("Folder removed");

      history.push("/dashboard/library");
      loadUser();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="settingsModal">
      <div className="content-middle">
        <div
          className="button top-16 full-width"
          onClick={() => deleteObjectHandler()}
        >
          Confirm {deleteObject.type === "remove-folder" ? "remove" : "delete"}
        </div>
      </div>

      <div className="content-middle">
        <div
          className="button-secondary top-16 full-width"
          onClick={toggleDeleteObjectModal}
        >
          Cancel
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  deleteObject: state.modal.deleteObject,
});

export default withRouter(
  connect(mapStateToProps, {
    toggleDeleteObjectModal,
    toggleTrackUpdate,
    toggleFolderUpdate,

    loadUser,
  })(DeleteModal)
);
