import React, { useState, useEffect } from "react";
import axios from "axios";
import { toggleCreateFolderModal } from "../../../actions/modal";
import { connect } from "react-redux";
import FolderTile from "../../../components/FolderTile";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

import "./style.scss";

const SortableItem = SortableElement(({ folder }) => (
  <FolderTile folder={folder._id} />
));

const SortableList = SortableContainer(({ folders }) => {
  return (
    <div>
      {folders.map((folder, index) => (
        <SortableItem
          key={`item-${folder._id._id}`}
          index={index}
          folder={folder}
        />
      ))}
    </div>
  );
});

const FoldersPage = ({ updateToggle, user, toggleCreateFolderModal }) => {
  const [folders, setFolders] = useState();
  const [loading, setLoading] = useState(true);

  async function fetchFolders() {
    try {
      var res = await axios.get("/api/users/getFolders");

      setFolders(res.data);
      setLoading(false);
    } catch (error) {
      toast.error("Error getting folders");
    }
  }

  useEffect(() => {
    fetchFolders();
  }, [updateToggle, user]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const folder = folders[oldIndex];

    setFolders((folders) => arrayMove(folders, oldIndex, newIndex));

    axios.patch(`/api/users/reorderFolders`, {
      folderId: folder._id._id,
      newPosition: newIndex,
    });
  };

  return (
    <div className="folders-page page-size">
      <div className="page-container">
        <div className="page-top">
          <div className="page-title">Library</div>
          {folders && folders.length > 0 ? (
            <div
              className="create-folder-button button-secondary"
              onClick={() => toggleCreateFolderModal({})}
            >
              New folder
            </div>
          ) : null}
        </div>

        <div className="page-content">
          <div className="folders-container">
            {!loading ? (
              folders && folders.length > 0 ? (
                <SortableList
                  distance={1}
                  folders={folders}
                  axis="xy"
                  onSortEnd={onSortEnd}
                />
              ) : (
                <div className="empty-state-container">
                  <div className="empty-info">
                    <div className="empty-state-title">Welcome to Catalog.</div>
                    <div className="empty-state-text">
                      Let's create a folder to upload tracks.
                    </div>
                    <div className="button" onClick={toggleCreateFolderModal}>
                      Create folder
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className="empty-state-container">
                <div className="loading-icon">
                  <BeatLoader
                    sizeUnit={"px"}
                    size={10}
                    color={"#fff"}
                    loading={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  updateToggle: state.folder.updateToggle,
});

export default connect(mapStateToProps, {
  toggleCreateFolderModal,
})(FoldersPage);
