export function useMatch(match) {
  if (match.params.folderId) {
    return `?folder=${match.params.folderId}`;
  }

  return '';
}

export default function({parentFolder}) {
  if (parentFolder) {
    return `?folder=${parentFolder._id}`;
  }

  return '';
}