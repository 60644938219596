import React, { useState } from "react";
import { connect } from "react-redux";
import { toggleTrackUpdate } from "../../../../actions/track";

import { toast } from "react-toastify";
import axios from "axios";

const BasicInfo = ({ track, toggleTrackUpdate }) => {
  const [formData, setFormData] = useState({
    name: track.name,
  });

  const { name } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const trackId = track._id;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const body = JSON.stringify({
        name,
      });

      await axios.patch(`/api/tracks/updateTrack/${trackId}`, body, config);

      toggleTrackUpdate();

      toast.success("Updated track");
    } catch (error) {
      toast.error("Failed to update");
    }
  };

  return (
    <>
      <div className="name-input setting-input-container">
        <div className="input-label">Name</div>
        <input
          type="text"
          placeholder={name}
          name="name"
          value={name}
          onChange={(e) => onChange(e)}
          minLength="1"
          className="text-field"
        />
      </div>

      <div className="button save-button" onClick={(e) => onSubmit(e)}>
        Save
      </div>
    </>
  );
};

export default connect(null, { toggleTrackUpdate })(BasicInfo);
