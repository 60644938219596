import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  togglePlay,
  playNext,
  playPrevious,
  toggleShuffle,
  playShuffle,
  toggleRepeat,
  setVolume,
} from "../../actions/player";
import { useLocation } from "react-router-dom";
import Seekbar from "./Seekbar";
import "rc-slider/assets/index.css";
import { seek } from "../../actions/player";

import Slider from "rc-slider";
import "./style.scss";

const Audiobar = ({
  activeTrackObject,
  isPlaying,
  togglePlay,
  currentTime,
  currentDuration,
  playNext,
  playPrevious,
  isAuthenticated,
  toggleShuffle,
  shuffle,
  playShuffle,
  toggleRepeat,
  repeat,
  currentFolderImageURL,
  setVolume,
  seek,
  artistNameDisplayed,
}) => {
  const location = useLocation();

  const audioPlayerClassHandler = () => {
    let audioPlayerClass = null;

    if (
      !location.pathname.includes("register") ||
      !location.pathname.includes("login")
    ) {
      if (
        !isAuthenticated &&
        !location.pathname.includes("folder") &&
        !location.pathname.includes("track")
      ) {
        audioPlayerClass = "audioPlayer audioPlayer-hidden";
      } else {
        audioPlayerClass = "audioPlayer";
      }
    } else {
      audioPlayerClass = "audioPlayer-hidden";
    }

    return audioPlayerClass;
  };

  const playNextHandler = () => {
    shuffle ? playShuffle() : playNext();
  };

  const renderRepeatIcon = () => {
    if (repeat === "no-repeat") {
      return <ion-icon name="repeat-outline"></ion-icon>;
    } else if (repeat === "repeat-playlist") {
      return (
        <div className="repeat-playlist-icon">
          <ion-icon name="repeat-outline"></ion-icon>
        </div>
      );
    } else if (repeat === "repeat-one") {
      return (
        <div className="repeat-one-icon">
          <div className="repeat-one-badge">1</div>
          <ion-icon name="repeat-outline"></ion-icon>
        </div>
      );
    }
  };

  const onAfterVolumeChangeHandler = (value) => {
    setVolume(value);
  };

  {
    return (
      <div className={audioPlayerClassHandler()}>
        <div className="left-container">
          {activeTrackObject._id ? (
            <>
              <div
                className="track-image"
                style={{
                  backgroundImage:
                    currentFolderImageURL !== null
                      ? `url(${currentFolderImageURL})`
                      : null,
                }}
              >
                {currentFolderImageURL !== null ? null : (
                  <ion-icon name="musical-notes"></ion-icon>
                )}
              </div>
              <div className="track-info">
                <Link
                  to={`/track/${activeTrackObject._id}`}
                  className="track-name"
                >
                  {activeTrackObject.name}
                </Link>
                {artistNameDisplayed && (
                  <div className="track-username">
                    {activeTrackObject.owner.username}
                  </div>
                )}
              </div>{" "}
            </>
          ) : (
            <>
              {" "}
              <div className="track-image">
                <ion-icon name="musical-notes"></ion-icon>
              </div>
              <div className="track-info">
                <div className="track-name"></div>
              </div>
            </>
          )}
        </div>
        <div className="middle-container">
          <div className="control-buttons">
            <div
              className={
                shuffle
                  ? "shuffle-button shuffle-button-active"
                  : "shuffle-button"
              }
              onClick={() => toggleShuffle()}
            >
              <ion-icon name="shuffle-outline"></ion-icon>
            </div>

            {activeTrackObject._id ? (
              <>
                <div className="back-button" onClick={() => playPrevious()}>
                  <ion-icon name="play-skip-back-sharp"></ion-icon>
                </div>
                <div
                  className="play-button"
                  onClick={() => togglePlay(currentTime)}
                >
                  {isPlaying ? (
                    <div className="pause-icon">
                      <ion-icon name="pause-sharp"></ion-icon>
                    </div>
                  ) : (
                    <div className="play-icon">
                      <ion-icon name="play-sharp"></ion-icon>
                    </div>
                  )}
                </div>
                <div className="next-button" onClick={() => playNextHandler()}>
                  <ion-icon name="play-skip-forward-sharp"></ion-icon>
                </div>
              </>
            ) : (
              <>
                <div className="back-button-disabled">
                  <ion-icon name="play-skip-back-sharp"></ion-icon>
                </div>
                <div className="play-button-disabled">
                  <div className="play-icon">
                    <ion-icon name="play-sharp"></ion-icon>
                  </div>
                </div>
                <div className="next-button-disabled">
                  <ion-icon name="play-skip-forward-sharp"></ion-icon>
                </div>
              </>
            )}

            <div className="repeat-button" onClick={() => toggleRepeat()}>
              {renderRepeatIcon()}
            </div>
          </div>

          <div className="progressBar-container">
            <div className="seekBar-container">
              <Seekbar
                currentTime={currentTime}
                currentDuration={currentDuration}
                trackId={activeTrackObject._id}
              />
            </div>
          </div>
          <div className="mobile-name-container">{activeTrackObject.name}</div>
        </div>
        <div className="right-container">
          <div className="volume-icon">
            <ion-icon name="volume-medium-outline"></ion-icon>
          </div>
          <div className="volume-slider">
            <Slider
              min={0}
              max={100}
              defaultValue={100}
              onAfterChange={onAfterVolumeChangeHandler}
              trackStyle={{ backgroundColor: "#b3b3b3" }}
              railStyle={{ backgroundColor: "#515151" }}
              handleStyle={{
                borderColor: "#fff",
                backgroundColor: "#fff",
                opacity: 0,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  activeTrackObject: state.player.activeTrackObject,
  isPlaying: state.player.isPlaying,
  currentDuration: state.player.currentDuration,
  currentTime: state.player.currentTime,
  user: state.auth.user,
  updateToggle: state.track.updateToggle,
  isAuthenticated: state.auth.isAuthenticated,
  shuffle: state.player.shuffle,
  repeat: state.player.repeat,
  currentFolderImageURL: state.player.currentFolderImageURL,
  artistNameDisplayed: state.folder.artistNameDisplayed,
});

export default connect(mapStateToProps, {
  togglePlay,
  playNext,
  playPrevious,
  toggleShuffle,
  playShuffle,
  toggleRepeat,
  setVolume,
  seek,
})(Audiobar);
