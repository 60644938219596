import {
  SELECT_SUBMISSION_CATALOG_TRACKS,
  REMOVE_SUBMISSION_CATALOG_TRACK,
  REMOVE_SUBMISSION_UPLOAD_FILE,
  ADD_SUBMISSION_UPLOAD_FILE,
  TOGGLE_SUBMISSION_UPDATE,
  INCREMENT_TRACKS_UPLOADED,
  SET_UPLOADING,
} from "./types";

export const selectCatalogTracks = (selectedTracks) => {
  return {
    type: SELECT_SUBMISSION_CATALOG_TRACKS,
    payload: selectedTracks,
  };
};

export const removeCatalogTrack = (selectedTrack) => {
  return {
    type: REMOVE_SUBMISSION_CATALOG_TRACK,
    payload: selectedTrack,
  };
};

export const removeUploadFile = (name) => {
  return {
    type: REMOVE_SUBMISSION_UPLOAD_FILE,
    payload: name,
  };
};

export const addUploadFile = (uploadFile) => {
  return {
    type: ADD_SUBMISSION_UPLOAD_FILE,
    payload: uploadFile,
  };
};

export const toggleSubmissionUpdate = () => {
  return {
    type: TOGGLE_SUBMISSION_UPDATE,
  };
};
export const incrementTracksUploaded = () => {
  return {
    type: INCREMENT_TRACKS_UPLOADED,
  };
};

export const setUploading = (uploading) => {
  return {
    type: SET_UPLOADING,
    payload: uploading,
  };
};
