import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import useOutsideClick from "../../../utils/useOutsideClick";
import { logout } from "../../../actions/auth";
import "./style.scss";
import { toggleAccountSettingsModal } from "../../../actions/modal";

const SettingsMenu = ({
  closeMenuHandler,
  logout,
  toggleAccountSettingsModal,
}) => {
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  const toggleAccountSettingsModalHandler = (e) => {
    e.stopPropagation();
    toggleAccountSettingsModal();
    closeMenuHandler();
  };

  const logoutHandler =  async (e) => {
    e.stopPropagation();
    const refreshToken = localStorage.getItem("refreshToken");
    logout();
    await axios.delete(`/api/auth/logout`, { params: { refreshToken }});
    closeMenuHandler();
  };

  return (
    <div className="topbar-settings-menu-container" ref={menuRef}>
      <div
        className="menu-item account-settings"
        onClick={(e) => toggleAccountSettingsModalHandler(e)}
      >
        Account settings
      </div>
      <div className="menu-item" onClick={(e) => logoutHandler(e)}>
        Log out
      </div>
    </div>
  );
};

export default withRouter(
  connect(null, {
    logout,
    toggleAccountSettingsModal,
  })(SettingsMenu)
);
