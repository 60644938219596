import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format, parseISO, subDays } from "date-fns";
import CustomToolTip from "../ToolTip";

const TotalPlaysChart = ({ playData }) => {
  console.log(playData, "go");

  return (
    <ResponsiveContainer width="100%" height={240}>
      <AreaChart data={playData}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#e0245e" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#e0245e" stopOpacity={0.05} />
          </linearGradient>
        </defs>

        <Area dataKey="value" stroke="#e0245e" fill="url(#color)" />

        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickFormatter={() => {
            return "";
          }}
        />

        <YAxis
          datakey="value"
          axisLine={false}
          tickLine={false}
          tickCount={8}
        />

        <Tooltip content={<CustomToolTip />} />

        <CartesianGrid opacity={0.1} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TotalPlaysChart;
