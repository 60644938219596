import React, { useState } from "react";
import { toggleEditTrackModal } from "../../../actions/modal";
import { connect } from "react-redux";

import BasicInfo from "./BasicInfo";
import Metadata from "./Metadata";
import DeleteTrack from "./DeleteTrack";
import "./style.scss";

const EditTrackModal = ({ toggleEditTrackModal, user, track }) => {
  const [tab, setTab] = useState("basic-info");

  const renderSettingsBody = () => {
    if (tab === "basic-info") {
      return <BasicInfo track={track} />;
    }

    if (tab === "metadata") {
      return <Metadata track={track} />;
    }

    if (tab === "delete-track") {
      return <DeleteTrack track={track} />;
    }
  };

  return (
    <div className="editTrackModal">
      <div className="content-top">
        <h2 className="title">Edit Track</h2>
        <div className="close-icon" onClick={toggleEditTrackModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      {user ? (
        <div className="content-middle">
          <div className="settings-menu">
            <div
              className={
                tab === "basic-info"
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
              onClick={() => setTab("basic-info")}
            >
              Basic Info
            </div>
            <div
              className={
                tab === "metadata" ? "menu-item menu-item-active" : "menu-item"
              }
              onClick={() => setTab("metadata")}
            >
              Metadata
            </div>
            <div
              className={
                tab === "delete-track"
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
              onClick={() => setTab("delete-track")}
            >
              Delete track
            </div>
          </div>

          <div className="settings-body">{renderSettingsBody()}</div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  track: state.modal.editTrackObject,
});

export default connect(mapStateToProps, {
  toggleEditTrackModal,
})(EditTrackModal);
