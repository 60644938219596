import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_TOGGLE_UPDATE
} from "../actions/types";

const initialState = {
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  isAuthenticated: null,
  loading: true,
  user: null,
  toggleUpdate: false
};

export default function (state = initialState, action) {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
          loading: false,
          user: payload
      };
    
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem("accessToken", payload.accessToken);
      localStorage.setItem("refreshToken", payload.refreshToken);
      navigator?.serviceWorker?.controller?.postMessage(payload.accessToken);
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false
      };
    case REGISTER_FAIL:
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        accessToken: null,
        isAuthenticated: false,
        loading: false
      };

    case USER_TOGGLE_UPDATE:
      return {
        ...state,
        toggleUpdate: !state.toggleUpdate
      };

    default:
      return state;
  }
}