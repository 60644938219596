import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import modal from "./modal";
import track from "./track";
import player from "./player";
import folder from "./folder";
import notification from "./notification";
import uploadTrack from "./uploadTrack";
import statusBar from "./statusBar";
import context from "./context";
import submission from "./submission";
import trackModal from "./trackModal";
import contextMenu from "./contextMenu";

export default combineReducers({
  alert,
  auth,
  modal,
  track,
  player,
  folder,
  notification,
  uploadTrack,
  statusBar,
  context,
  submission,
  trackModal,
  contextMenu,
});
