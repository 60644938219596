import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { loadUser } from "../../../actions/auth"
import { Link } from "react-router-dom";
import moment from "moment";
import "../style.scss";

const Submission = ({ submission, tab, loadUser, readSubmission }) => {
  const renderUserIcon = () => {
    return (submission.fromUser.profileImageURL ? (
      <div
        className="user-icon"
        style={{
          backgroundImage: `url(${submission.fromUser.profileImageURL})`,
        }}
      ></div>
    ) : (
      <div className="user-icon">
        {submission.fromUser.username.charAt(0)}
      </div>
    ))
  };

  const renderUsername = () => {
    return `${submission.fromUser.username}`;
  };

  const readSubmissionHandler = async () => {
    if (!readSubmission) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        await axios.patch(
          `/api/submissions/readSubmission/${submission._id}`,
          config
        );

        loadUser();
      } catch (error) {
        console.log("Failed to read");
      }
    }
  } 

  return (
    <Link
      to={`/dashboard/shared/open/${submission._id}`}
      className="submission-container"
      onClick={() => readSubmissionHandler()}
    >
      <div className="left">
        <div className="user-icon-container">
          
          {readSubmission ? null : (<div className="unread-badge"></div>)}
          {renderUserIcon()}
        </div>
        <div className="submission-info">
          <div className="title">
            {submission.folder ? submission.folder.name : null}
          </div>
          <div className="subtext">{renderUsername()}</div>
        </div>
      </div>
      <div className="right">
        <div className="date">{moment(submission.createdAt).format("ll")}</div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {loadUser})(Submission);
