import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toggleShareTrackModal } from "../../../actions/modal";

import { connect } from "react-redux";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import createRequestQuery from "../../../utils/createRequestQuery";

import PendingInvite from "./TrackPendingInvite";
import InvitedMember from "./TrackInvitedMember";
import InvitePermissionsMenu from "./TrackInvitePermissionsMenu";
import { toast } from "react-toastify";
import "./style.scss";

import { Mixpanel } from "../../../utils/Mixpanel";

const ShareTrackForm = ({ toggleShareTrackModal, track, user }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [shareEmails, setShareEmails] = useState([]);
  const [invitePermission, setInvitePermission] = useState("full-access");
  const [publicViewing, setPublicViewing] = useState(track.publicViewing);
  const [publicDownloads, setPublicDownloads] = useState(track.publicDownloads);
  const [users, setUsers] = useState();
  const [pendingInvites, setPendingInvites] = useState();

  const linkRef = useRef();

  /**  const getUsers = async () => {
    try {
      const res = await axios.get(
        `/api/tracks/users/${track._id}${createRequestQuery(track)}`
      );

      if (res.data.error) throw new Error(res.data.message);
      setUsers(res.data.users);
      setPendingInvites(res.data.pendingInvites);
    } catch (error) {
      console.log(error.message);
    }
  };

useEffect(() => {
    getUsers();
  }, []); **/

  const invite = async (e) => {
    e.preventDefault();

    if (!shareEmails.length) return;

    let canEdit = false,
      canView = false,
      canDownload = false;
    if (invitePermission === "full-access") {
      canEdit = true;
      canView = true;
      canDownload = true;
    } else if (invitePermission === "view-and-download") {
      canView = true;
      canDownload = true;
    } else if (invitePermission === "view-only") {
      canView = true;
    }

    Promise.all(
      shareEmails.map((email) =>
        axios.post(
          `/api/tracks/inviteUser/${track._id}${createRequestQuery(track)}`,
          {
            email: email,
            permissions: { canEdit, canView, canDownload },
          }
        )
      )
    )
      .then(() => {
        toast.success(
          shareEmails.length > 1 ? "Invites sent" : "Invite sent",
          "success"
        );

        toggleShareTrackModal();
        Mixpanel.track("Invite to track", {
          trackId: track._id,
        });
      })
      .catch((error) => toast.error(error.message));
  };

  const copyURL = (e) => {
    linkRef.current.select();

    document.execCommand("copy");

    toast.success("Link copied");

    Mixpanel.track("Share track via link", {
      trackId: track._id,
    });
  };

  const onPublicViewingChange = async () => {
    await axios.patch(
      `/api/tracks/updateSharing/${track._id}${createRequestQuery(track)}`,
      { publicViewing: !publicViewing, publicDownloads: !publicViewing }
    );
    setPublicViewing(!publicViewing);

    toast.success("Updated sharing access");
  };

  const onPublicDownloadsChange = async () => {
    await axios.patch(
      `/api/tracks/updateSharing/${track._id}${createRequestQuery(track)}`,
      { publicViewing, publicDownloads: !publicDownloads }
    );
    setPublicDownloads(!publicDownloads);

    toast.success("Updated sharing access");
  };

  const closeMenuHandler = () => {
    setOpenMenu(false);
  };

  const setInvitePermissionHandler = (permission) => {
    setInvitePermission(permission);
    setOpenMenu(false);
  };

  return (
    <div className="shareTrackModal">
      <>
        <div className="content-top bottom-16">
          <h2 className="title">Share Track</h2>
          <div className="close-icon" onClick={() => toggleShareTrackModal()}>
            <ion-icon name="close"></ion-icon>
          </div>
        </div>

        {track.owner._id === user?._id ? (
          <>
            <div className="content-middle">
              <div className="share-form">
                <div className="form-box email-form-container">
                  <div className="email-form">
                    <ReactMultiEmail
                      placeholder="Invite via email address"
                      emails={shareEmails}
                      onChange={(emails) => setShareEmails(emails)}
                      validateEmail={(email) => isEmail(email)}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span
                              data-tag-handle
                              onClick={() => removeEmail(index)}
                            >
                              x
                            </span>
                          </div>
                        );
                      }}
                    />

                    <div className="permissions-field">
                      {openMenu ? (
                        <InvitePermissionsMenu
                          closeMenuHandler={closeMenuHandler}
                          setInvitePermissionHandler={
                            setInvitePermissionHandler
                          }
                        />
                      ) : null}

                      <div
                        className="permissions-container"
                        onClick={() => setOpenMenu(true)}
                      >
                        <div className="permissions-text">
                          {invitePermission === "full-access"
                            ? "Full access"
                            : null}
                          {invitePermission === "view-and-download"
                            ? "View and download"
                            : null}
                          {invitePermission === "view-only"
                            ? "View only"
                            : null}
                        </div>
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </div>
                    </div>
                  </div>

                  <div className={"button invite-button"} onClick={invite}>
                    Invite
                  </div>
                </div>

                <div className="member-list">
                  {users
                    ? users.map((user, index) => (
                        <InvitedMember key={index} user={user} id={track._id} />
                      ))
                    : null}
                  {pendingInvites
                    ? pendingInvites.map((invite, index) => (
                        <PendingInvite
                          key={index}
                          invite={invite}
                          id={track._id}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="content-bottom">
              <div className="share-form">
                <label htmlFor="invite-email">Shareable link</label>
                <div className="form-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={publicViewing}
                      onClick={() => onPublicViewingChange()}
                    />
                    <span className="slider round"></span>
                  </label>
                  <div className="link-access-text">
                    {publicViewing
                      ? "Anyone with the link can access"
                      : "Only people invited can access"}
                  </div>
                </div>
              </div>

              {publicViewing ? (
                <>
                  <div className="share-form">
                    <label htmlFor="invite-email">Download Access</label>
                    <div className="form-box">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={publicDownloads}
                          onClick={() => onPublicDownloadsChange()}
                        />
                        <span className="slider round"></span>
                      </label>
                      <div className="link-access-text">
                        {publicDownloads
                          ? "Anyone with the link can download tracks"
                          : "Downloads are disabled"}
                      </div>
                    </div>
                  </div>

                  <div className="share-form">
                    <div className="form-box">
                      <input
                        className="text-field sharing-link-field"
                        type="text"
                        name="username"
                        ref={linkRef}
                        autoComplete="off"
                        value={`https://catalog.studio/track/${track._id}`}
                      />

                      <div
                        className={"button-secondary invite-button"}
                        onClick={copyURL}
                      >
                        Copy Link
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <div className="share-form">
            <div className="form-box just-link">
              <input
                className="text-field sharing-link-field"
                type="text"
                name="username"
                ref={linkRef}
                autoComplete="off"
                value={`https://catalog.studio/track/${track._id}`}
              />

              <div
                className={"button-secondary invite-button"}
                onClick={copyURL}
              >
                Copy Link
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  track: state.modal.shareTrackObject,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  toggleShareTrackModal,
})(ShareTrackForm);
