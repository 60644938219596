import React, { useRef, useEffect } from "react";
import Sound from "react-sound";
import { connect } from "react-redux";
import {
  setCurrentTime,
  setCurrentDuration,
  playNext,
  setPlayerRef,
  playShuffle,
  playOnEnd,
} from "../../actions/player";

window.soundManager.setup({ debugMode: false });

const Player = ({
  activeTrackObject,
  isPlaying,
  setCurrentTime,
  setCurrentDuration,
  playOnEnd,
  playShuffle,
  shuffle,
  repeat,
  currentVolume,
  currentDuration,
  seekingTime,
}) => {
  const onEndHandler = () => {
    if (currentDuration > 10000) {
      shuffle ? playShuffle() : playOnEnd();
    }
  };

  if (activeTrackObject?.fileURL) {
    return (
      <>
        <Sound
          url={activeTrackObject.fileURL}
          playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.PAUSED}
          onLoading={({ duration }) => setCurrentDuration(duration)}
          onPlaying={({ position }) => setCurrentTime(position)}
          onFinishedPlaying={() => onEndHandler()}
          loop={repeat === "repeat-one" ? true : false}
          volume={currentVolume}
          position={seekingTime}
        />
      </>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  isPlaying: state.player.isPlaying,
  isSeeking: state.player.isSeeking,
  activeTrackObject: state.player.activeTrackObject,
  currentPlaylist: state.player.currentPlaylist,
  currentPlaylistIndex: state.player.currentPlaylistIndex,
  shuffle: state.player.shuffle,
  repeat: state.player.repeat,
  currentVolume: state.player.currentVolume,
  currentDuration: state.player.currentDuration,
  seekingTime: state.player.seekingTime,
});

export default connect(mapStateToProps, {
  setCurrentTime,
  setCurrentDuration,
  setPlayerRef,
  playShuffle,
  playNext,
  playOnEnd,
})(Player);
