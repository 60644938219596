import React, { useState, useEffect } from "react";
import "./style.scss";
import { connect } from "react-redux";
import axios from "axios";

import { toggleAddFromCatalogModal } from "../../../actions/modal";
import filterData from "../../../utils/filterData.json";
import { toast } from "react-toastify";
import { toggleFolderUpdate } from "../../../actions/folder";
import moment from "moment";

const AddFromCatalogModal = ({
  toggleAddFromCatalogModal,
  toggleFolderUpdate,
  addFromCatalogFolderId,
}) => {
  const [selectedTracks, setSelectedTracks] = useState([]);
  const [tracks, setTracks] = useState();

  async function fetchTracks() {
    try {
      const res = await axios.get(`/api/users/getUploads/null`);

      setTracks(res.data);
    } catch (error) {
      toast.error("Error getting trackings");
    }
  }

  useEffect(() => {
    fetchTracks();
  }, []);

  const toggleSelectTrackHandler = (track) => {
    if (selectedTracks.some((el) => el._id === track._id)) {
      setSelectedTracks(
        [...selectedTracks].filter(({ _id }) => _id !== track._id)
      );
    } else {
      setSelectedTracks([...selectedTracks, track]);
    }
  };

  const renderFormattedTextHelper = (tag, type) => {
    const obj = filterData[type].find((o) => o.value === tag);
    let label = "";

    if (obj) {
      label = obj.label;
    }
    return label;
  };

  const addCatalogTracksHandler = async () => {
    await selectedTracks.forEach(async (track) => {
      try {
        var res = await axios.post(
          `/api/folders/addTrack/${addFromCatalogFolderId}/${track._id}`
        );

        if (res.data.error) throw new Error(res.data.message);

        toast.success("Added to folder");
        toggleFolderUpdate();
      } catch (error) {
        toast.error("Track already added to folder");
      }
    });

    toggleAddFromCatalogModal();
  };

  return (
    <div className="addFromCatalogModal">
      <div className="content-top">
        <h2 className="title">Select tracks</h2>
        <div className="close-icon" onClick={toggleAddFromCatalogModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      <div className="content-body">
        {tracks
          ? tracks.tracks.map((track, index) => (
              <div
                className="add-track"
                onClick={() => toggleSelectTrackHandler(track)}
                key={index}
              >
                <div className="add-icon">
                  {selectedTracks.some((el) => el._id === track._id) ? (
                    <ion-icon name="checkbox"></ion-icon>
                  ) : (
                    <ion-icon name="square-outline"></ion-icon>
                  )}
                </div>
                <div className="info">
                  <div className="track-name">{track.name}</div>
                  <div className="track-tags">
                    {moment(track.createdAt).format("MMM Do")}
                    {/** {renderFormattedTextHelper(track.trackType, "typeOptions")},{" "} 
                   {`${track.owner.username}, `}
                    
                    {track.genre
                      ? `, ${renderFormattedTextHelper(
                          track.genre,
                          "genreOptions"
                        )}`
                      : null}
                 {track.bpm ? `, ${track.bpm} BPM` : null}
                    {track.keySignature
                      ? `, ${renderFormattedTextHelper(
                          track.keySignature,
                          "keySignatureOptions"
                        )}`
                      : null} **/}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>

      <div className="content-bottom">
        <div className="selected-count">
          {selectedTracks.length === 1
            ? "1 Track selected"
            : `${selectedTracks.length} Tracks selected`}
        </div>
        <div
          className="button add-button"
          onClick={() => addCatalogTracksHandler()}
        >
          Add to folder
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addFromCatalogFolderId: state.modal.addFromCatalogFolderId,
});

export default connect(mapStateToProps, {
  toggleAddFromCatalogModal,
  toggleFolderUpdate,
})(AddFromCatalogModal);
