import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./style.scss";

export default function Notification({ notification }) {
  const renderNotificationMessage = () => {
    if (notification.type === "like") {
      return (
        <div className="notification-message">
          <span className="user-name"> {notification.fromUser.username} </span>
          liked your track:
          <Link to={`/track/${notification.track._id}`} className="track-name">
            {notification.track.name}
          </Link>
        </div>
      );
    }
    if (notification.type === "download") {
      return (
        <div className="notification-message">
          <span className="user-name"> {notification.fromUser.username} </span>
          downloaded your track:
          <Link to={`/track/${notification.track._id}`} className="track-name">
            {notification.track.name}
          </Link>
        </div>
      );
    }
    if (notification.type === "comment") {
      return (
        <div className="notification-message">
          <span className="user-name"> {notification.fromUser.username} </span>
          commented on your track:
          <Link to={`/track/${notification.track._id}`} className="track-name">
            {notification.track.name}
          </Link>
        </div>
      );
    }
    if (notification.type === "replyToComment") {
      return (
        <div className="notification-message">
          <span className="user-name"> {notification.fromUser.username} </span>
          replied to your comment on track:
          <Link to={`/track/${notification.track._id}`} className="track-name">
            {notification.track.name}
          </Link>
        </div>
      );
    }
  };

  return (
    <div className="notification-item">
      {notification.fromUser.profileImageURL ? (
        <div
          className="user-image"
          style={{
            backgroundImage: `url(${notification.fromUser.profileImageURL})`,
          }}
        ></div>
      ) : (
        <div className="user-image">
          {notification.fromUser.username.charAt(0)}
        </div>
      )}

      <div className="notification-info">
        {renderNotificationMessage()}
        <div className="notification-time">
          {moment(notification.createdAt).fromNow()}
        </div>
      </div>
    </div>
  );
}
