import React from "react";
import { toggleUploadStatusModal } from "../../../actions/modal";
import filterData from "../../../utils/filterData.json";
import { connect } from "react-redux";

import "./style.scss";

const renderFormattedTextHelper = (tag, type) => {
  const obj = filterData[type].find((o) => o.value === tag);
  let label = "";

  if (obj) {
    label = obj.label;
  }
  return label;
};

const UploadStatusModal = ({ toggleUploadStatusModal, uploadTracksArray }) => {
  return (
    <div className="upload-status-modal">
      <div className="content-top bottom-16">
        <h2 className="title">Upload details</h2>
        <div className="close-icon" onClick={toggleUploadStatusModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      <div className="content-middle">
        {uploadTracksArray.map((track) => (
          <div className="uploading-track">
            <div className="uploading-track-left">
              {track.status === "uploading" ? (
                <div className="updating-icon spin">
                  <ion-icon name="sync-circle-outline"></ion-icon>
                </div>
              ) : track.status === "uploaded" ? (
                <div className="uploaded-icon">
                  <ion-icon name="checkmark-circle-outline"></ion-icon>
                </div>
              ) : null}

              <div className="track-info">
                <div className="track-name">{track.name}</div>
                <div className="track-type">
                  {renderFormattedTextHelper(track.trackType, "typeOptions")}
                </div>
              </div>
            </div>
            <div className="upload-status">
              {track.status === "uploading"
                ? "Uploading..."
                : track.status === "uploaded"
                ? "Complete"
                : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uploadTracksArray: state.uploadTrack.uploadTracks,
});

export default connect(mapStateToProps, {
  toggleUploadStatusModal,
})(UploadStatusModal);
