import {
  ADD_UPLOAD_TRACK,
  REMOVE_UPLOAD_TRACK,
  UPDATE_UPLOAD_TRACK,
  REMOVE_ALL_UPLOAD_TRACKS,
} from "./types";

export const addUploadTrack = (uploadTrackObject) => {
  return {
    type: ADD_UPLOAD_TRACK,
    payload: uploadTrackObject,
  };
};

export const removeUploadTrack = (trackId) => {
  return {
    type: REMOVE_UPLOAD_TRACK,
    payload: trackId,
  };
};

export const updateUploadTrack = (uploadTrackObject) => {
  return {
    type: UPDATE_UPLOAD_TRACK,
    payload: uploadTrackObject,
  };
};

export const removeAllUploadTracks = () => {
  return {
    type: REMOVE_ALL_UPLOAD_TRACKS,
  };
};
