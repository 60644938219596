import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import axios from "axios";
import {
  toggleShareFolderModal,
  toggleDeleteObjectModal,
  toggleFolderSettingsModal,
  toggleAddFromCatalogModal,
  toggleMoveFolderModal,
} from "../../../actions/modal";

import { toggleFolderUpdate } from "../../../actions/folder";

import { loadUser } from "../../../actions/auth";

// Context Menu
import { Menu, Item, animation, Separator } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "folder-menu";

const FolderMenu = ({
  contextFolder,
  toggleShareFolderModal,
  toggleDeleteObjectModal,
  toggleFolderSettingsModal,
  toggleAddFromCatalogModal,
  toggleMoveFolderModal,
  user,
  loadUser,
  contextMenuType,
}) => {
  const [folder, setFolder] = useState();
  const [inLibrary, setInLibrary] = useState();

  async function fetchFolder() {
    try {
      const res = await axios.get(
        `/api/folders/getFolder/${contextFolder._id}/null`
      );

      if (res.data.error) throw new Error(res.data.message);
      await setFolder(res.data.folder);

      let folderInLibrary = user.folders.filter(
        (_folder) => _folder._id === res.data.folder._id
      );

      await setInLibrary(
        folderInLibrary[0] && folderInLibrary[0].addedToLibrary ? true : false
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (contextFolder && contextFolder._id && contextMenuType === "folder") {
      fetchFolder();
    }
  }, [contextFolder, user]);

  const toggleRemoveObjectModalHandler = () => {
    toggleDeleteObjectModal(folder, "remove-folder");
  };

  const toggleDeleteObjectModalHandler = () => {
    toggleDeleteObjectModal(folder, "folder");
  };

  const renderAddToLibraryItem = () => {
    if (inLibrary) {
      return (
        <Item onClick={() => toggleRemoveObjectModalHandler()}>
          Remove from library
        </Item>
      );
    } else {
      return (
        <Item onClick={(e) => addtoLibraryHandler(e)}>Save to library</Item>
      );
    }
  };

  async function addtoLibraryHandler() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await axios.patch(`/api/submissions/addToLibrary/${folder._id}`, config);

      loadUser();

      toast.success("Added to library");
    } catch (error) {
      console.log("get loops error");
    }
  }

  return (
    <Menu id={MENU_ID} animation={animation.fade}>
      {folder ? (
        <>
          <Item onClick={() => toggleShareFolderModal(folder)}>
            Share folder
          </Item>
          {folder.permissions.canEdit ? (
            <>
              <Separator />
              <Item onClick={() => toggleFolderSettingsModal(folder)}>
                Edit folder
              </Item>
              <Item onClick={() => toggleAddFromCatalogModal(folder._id)}>
                Add tracks from library
              </Item>
              <Item onClick={() => toggleMoveFolderModal(folder._id)}>
                Move folder
              </Item>
            </>
          ) : null}

          <Separator />
          {user && folder ? renderAddToLibraryItem() : null}

          {folder.permissions.canEdit ? (
            <Item onClick={(e) => toggleDeleteObjectModalHandler()}>
              Delete folder
            </Item>
          ) : null}
        </>
      ) : null}
    </Menu>
  );
};

export default connect(null, {
  toggleShareFolderModal,

  toggleDeleteObjectModal,
  toggleFolderSettingsModal,
  toggleAddFromCatalogModal,
  toggleFolderUpdate,
  loadUser,
  toggleMoveFolderModal,
})(FolderMenu);
