import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  toggleFolderUpdate,
  toggleArtistNameDisplayed,
} from "../../../../actions/folder";
import { toast } from "react-toastify";

import "./style.scss";

const ArtistNameSettings = ({ folder, toggleFolderUpdate }) => {
  const [currentArtistNameDisplayed, setCurrentNameDisplayed] = useState();

  useEffect(() => {
    setCurrentNameDisplayed(
      folder.artistNameDisplayed === false ? false : true
    );
  }, []);

  async function toggleArtistNameDisplayHandler() {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const newArtistNameDisplayed = !currentArtistNameDisplayed;
    console.log(newArtistNameDisplayed, "gooo");

    try {
      const body = JSON.stringify({
        name: folder.name,
        artistNameDisplayed: newArtistNameDisplayed,
      });

      await axios.patch(
        `/api/folders/updateFolder/${folder._id}`,
        body,
        config
      );

      toast.success("Updated Folder");
      toggleFolderUpdate();
      toggleArtistNameDisplayed();
      setCurrentNameDisplayed(newArtistNameDisplayed);
    } catch (error) {
      toast.error("Failed to update");
    }
  }

  return (
    <div className="input-container">
      <div className="input-label">Display artist name</div>

      <label className="switch">
        <input
          type="checkbox"
          checked={currentArtistNameDisplayed}
          onClick={() => toggleArtistNameDisplayHandler()}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default connect(null, { toggleFolderUpdate, toggleArtistNameDisplayed })(
  ArtistNameSettings
);
