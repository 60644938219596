import {
  TOGGLE_FOLDER_UPDATE,
  SET_CURRENT_FOLDER_COLOR,
  SET_INVITE_PERMISSION,
  TOGGLE_ARTIST_NAME_DISPLAYED,
  SET_ARTIST_NAME_DISPLAYED,
} from "../actions/types";

const initialState = {
  updateToggle: false,
  currentFolderColor: null,
  currentFolderId: null,
  invitePermission: null,
  artistNameDisplayed: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TOGGLE_FOLDER_UPDATE:
      return {
        ...state,
        updateToggle: !state.updateToggle,
      };

    case SET_CURRENT_FOLDER_COLOR:
      return {
        ...state,
        currentFolderColor: action.colorCode,
        currentFolderId: action.folderId,
      };

    case SET_INVITE_PERMISSION:
      return {
        ...state,
        invitePermission: action.invitePermission,
      };

    case TOGGLE_ARTIST_NAME_DISPLAYED:
      return {
        ...state,
        artistNameDisplayed: !state.artistNameDisplayed,
      };

    case SET_ARTIST_NAME_DISPLAYED:
      return {
        ...state,
        artistNameDisplayed: action.artistNameDisplayed,
      };

    default:
      return state;
  }
}
