import {
  TOGGLE_FOLDER_UPDATE,
  SET_CURRENT_FOLDER_COLOR,
  SET_INVITE_PERMISSION,
  TOGGLE_ARTIST_NAME_DISPLAYED,
  SET_ARTIST_NAME_DISPLAYED,
} from "./types";

export const toggleFolderUpdate = () => {
  return {
    type: TOGGLE_FOLDER_UPDATE,
  };
};

export const setCurrentFolderColor = (colorCode, folderId) => {
  return {
    type: SET_CURRENT_FOLDER_COLOR,
    colorCode,
    folderId,
  };
};

export const setInvitePermission = (invitePermission) => {
  return {
    type: SET_INVITE_PERMISSION,
    invitePermission,
  };
};

export const toggleArtistNameDisplayed = () => {
  return {
    type: TOGGLE_ARTIST_NAME_DISPLAYED,
  };
};

export const setArtistNameDisplayed = (artistNameDisplayed) => {
  return {
    type: SET_ARTIST_NAME_DISPLAYED,
    artistNameDisplayed,
  };
};
