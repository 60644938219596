import React from "react";
import { connect } from "react-redux";

import UploadStatus from "./UploadStatus";
import "./style.scss";

const StatusBar = ({ activeTrackObject, statusBarOpen, uploadStatusOpen }) => {
  return (
    <>
      {statusBarOpen ? (
        <div className="status-bar" style={{ bottom: "104px" }}>
          {uploadStatusOpen ? <UploadStatus /> : null}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeTrackObject: state.player.activeTrackObject,
  statusBarOpen: state.statusBar.statusBarOpen,
  uploadStatusOpen: state.statusBar.uploadStatusOpen,
});

export default connect(mapStateToProps)(StatusBar);
