import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import filterData from "../../../../utils/filterData.json";
import selectStyles from "../../../../utils/reactSelectStyle.js";

import { updateUploadTrack } from "../../../../actions/uploadTrack";

import Select from "react-select";

import { css } from "@emotion/core";
import "./style.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const typeOptions = filterData.typeOptions;
const genreOptions = filterData.genreOptions;
const keySignatureOptions = filterData.keySignatureOptions;

const EditTrack = ({ track, setEditOpen, updateUploadTrack }) => {
  const [tab, setTab] = useState("basic-info");

  const [formData, setFormData] = useState({
    name: track.name,
    producers: null,
    bpm: track.bpm,
    notes: track.notes,
    sharedWith: null,
  });
  const [trackType, setTrackType] = useState(track.trackType);
  const [isPrivate, setIsPrivate] = useState(false);
  const [keySignature, setKeySignature] = useState(track.keySignature);
  const [genre, setGenre] = useState(track.genre);

  const { name, producers, bpm, notes, sharedWith } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const renderFormattedTextHelper = (tag, type) => {
    const obj = filterData[type].find((o) => o.value === tag);
    let label = "";

    if (obj) {
      label = obj.label;
    }
    return label;
  };

  var formContent;
  if (tab === "basic-info") {
    formContent = (
      <Fragment>
        <div className="name-input">
          <div className="input-label">Name</div>
          <input
            type="text"
            placeholder={name}
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            minLength="1"
            className="text-field"
          />
        </div>
        <div className="type-input">
          <div className="input-label">Type</div>
          <Select
            options={typeOptions}
            styles={selectStyles}
            value={trackType}
            onChange={(trackType) => setTrackType(trackType)}
            placeholder={
              trackType
                ? renderFormattedTextHelper(trackType, "typeOptions")
                : "Song"
            }
          />
        </div>
        {/*<div className="producer-input">
          <div className="input-label">Producer</div>
          <input
            type="text"
            placeholder="Producers"
            name="producers"
            value={producers}
            onChange={(e) => onChange(e)}
            className="text-field"
          />
        </div>*/}
      </Fragment>
    );
  }

  if (tab === "metadata") {
    formContent = (
      <Fragment>
        <div className="genre-input">
          <div className="input-label">Genre</div>
          <Select
            options={genreOptions}
            styles={selectStyles}
            value={genre}
            onChange={(genre) => setGenre(genre)}
            placeholder={
              genre ? renderFormattedTextHelper(genre, "genreOptions") : "Genre"
            }
          />
        </div>
        <div className="bpm-input">
          <div className="input-label">BPM</div>
          <input
            type="text"
            placeholder={bpm ? bpm : "BPM"}
            name="bpm"
            value={bpm}
            onChange={(e) => {
              if (isNaN(Number(e.target.value))) {
                return;
              } else {
                onChange(e);
              }
            }}
            maxLength="3"
            className="text-field"
          />
        </div>
        <div className="key-input">
          <div className="input-label">Key Signature</div>
          <Select
            options={keySignatureOptions}
            styles={selectStyles}
            value={keySignature}
            onChange={(keySignature) => setKeySignature(keySignature)}
            placeholder={
              keySignature
                ? renderFormattedTextHelper(keySignature, "keySignatureOptions")
                : "Key"
            }
          />
        </div>
        <div className="notes-input">
          <div className="input-label">Notes</div>
          <input
            type="text"
            placeholder="Notes"
            name="notes"
            value={notes}
            onChange={(e) => onChange(e)}
            className="text-field"
          />
        </div>
      </Fragment>
    );
  }

  const updateUploadTrackHandler = () => {
    updateUploadTrack({
      name: name,
      producers: producers,
      genre: genre,
      keySignature: keySignature,
      bpm: bpm,
      id: track.id,
      notes: notes,
      sharedWith: sharedWith,
      trackType: trackType.value ? trackType.value : track.trackType,
    });
    setEditOpen(false);
  };

  return (
    <div className="upload-modal-edit-track">
      <div className="tabs">
        <div
          className={tab === "basic-info" ? "tab tab-active" : "tab"}
          onClick={() => setTab("basic-info")}
        >
          Basic Info
        </div>
        <div
          className={tab === "metadata" ? "tab tab-active" : "tab"}
          onClick={() => setTab("metadata")}
        >
          Metadata
        </div>
        {/* <div
              className={tab === "sharing" ? "tab tab-active" : "tab"}
              onClick={() => setTab("sharing")}
            >
              Sharing
            </div> */}
      </div>
      <div className="form-content">{formContent}</div>

      <div className="bottom-section">
        <div className="bottom-left">
          <div className="delete-button" onClick={() => setEditOpen(false)}>
            Cancel
          </div>
        </div>
        <div className="bottom-right">
          {/* <div className="private-upload">
                <div className="icon" onClick={() => setIsPrivate(!isPrivate)}>
                  {isPrivate ? (
                    <ion-icon name="checkbox"></ion-icon>
                  ) : (
                    <ion-icon name="square-outline"></ion-icon>
                  )}
                </div>
                <div className="text">Upload privately</div>
              </div>*/}
          <div className="button" onClick={() => updateUploadTrackHandler()}>
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateUploadTrack })(EditTrack);
