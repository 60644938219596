
/**
 * Automatically request the correct route based on the url of the current page
 * @param {*} url the url to append onto the end of the parent container route. e.g. /folder/getFolder/:folderId
 * @param {*} defaultUrl the default url to use if there is no parent container. e.g. /api/folders/getFolder/:folderId
 * @param {*} object the object to be modified in the request (optional)
 */
export default function(url, defaultUrl, object = null) {
  const pathname = window.location.pathname;
  
  const folderIdSearch = pathname.match("/folder/[0-9|a-z]{24}");
  const folderId = folderIdSearch ? folderIdSearch[0].substr(6, 24) : null;
  if ((folderId && !defaultUrl.includes('/folders')) || (object && object.shared && object.parentfolder)) {
    return `/api/folders/${folderId ? folderId : object.parentFolder._id}${url}`;
  };

  return defaultUrl;  
}