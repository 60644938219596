import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { seek, setSeeking } from "../../../actions/player";
import moment from "moment";
import "./style.scss";

function Seekbar({ currentTime, currentDuration, seek, setSeeking, trackId }) {
  const [position, setPosition] = useState(0);
  const [marginLeft, setMarginLeft] = useState(0);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const onChange = (e) => {
    seek({ id: trackId, time: (e.target.value / 100) * currentDuration });
    setPercentage(e.target.value);
  };

  const rangeRef = useRef();
  const thumbRef = useRef();

  useEffect(() => {
    const percent = ((currentTime / currentDuration) * 100).toFixed(2);
    setPosition(percent);

    const rangeWidth = rangeRef.current.getBoundingClientRect().width;
    const thumbWidth = thumbRef.current.getBoundingClientRect().width;
    const centerThumb = (thumbWidth / 100) * percent * -1;
    const centerProgressBar =
      thumbWidth + (rangeWidth / 100) * percent - (thumbWidth / 100) * percent;

    setMarginLeft(centerThumb);
    setProgressBarWidth(centerProgressBar);
  }, [currentTime]);

  return (
    <div className="seekBar">
      <div className="time-left">
        {currentTime
          ? moment(currentTime).format("m:ss")
          : moment(0).format("m:ss")}
      </div>

      <div className="slider-container">
        <div
          className="progress-bar-cover"
          style={{
            width: `${progressBarWidth}px`,
          }}
        ></div>
        <div
          className="thumb"
          ref={thumbRef}
          style={{
            left: `${position}%`,
            marginLeft: `${marginLeft}px`,
          }}
        ></div>
        <input
          type="range"
          value={position}
          ref={rangeRef}
          step="0.01"
          className="range"
          onChange={onChange}
        />
      </div>

      <div className="time-right">
        {currentDuration
          ? moment(currentDuration).format("m:ss")
          : moment(0).format("m:ss")}
      </div>
    </div>
  );
}
export default connect(null, { seek, setSeeking })(Seekbar);
