import React, { useState } from "react";
import { toggleFolderUpdate } from "../../../actions/folder";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import axios from "axios";

import "./style.scss";

const ImageUpload = ({ imageURL, folderId, toggleFolderUpdate }) => {
  const [newImage, setNewImage] = useState({});
  const [newImagePreview, setNewImagePreview] = useState(null);
  const [displayImageUpload, setDisplayImageUpload] = useState({
    mouseOver: false,
    newImageAdded: false,
  });
  const [uploading, setUploading] = useState(false);

  const fileChangedHandler = (e) => {
    setNewImagePreview(URL.createObjectURL(e.target.files[0]));
    setNewImage(e.target.files[0]);

    setDisplayImageUpload({
      ...displayImageUpload,
      newImageAdded: true,
    });
  };

  const removeImageHandler = () => {
    setDisplayImageUpload({
      ...displayImageUpload,
      newImageAdded: false,
    });
    setNewImage(null);
    setNewImagePreview(null);
  };

  const uploadImage = async (e) => {
    e.preventDefault();
    setUploading(true);

    const config = {
      headers: {
        "Content-Type": "application/form-data",
      },
    };

    try {
      let formdata = new FormData();
      formdata.append("image", newImage);

      const res = await axios.patch(
        `/api/folders/updateFolderImage/${folderId}`,
        formdata,
        config
      );
      if (res.data.error) throw new Error(res.data.msg);

      toast.success("Image updated");
      setNewImage(null);
      setUploading(false);
      setDisplayImageUpload({
        ...displayImageUpload,
        newImageAdded: false,
      });
      toggleFolderUpdate();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className="edit-folder-image-container">
      <div className="folder-image-label">Folder Image</div>
      <div
        className="edit-profile-image"
        style={{
          backgroundImage: `url(${imageURL})`,
        }}
        onMouseEnter={() =>
          setDisplayImageUpload({
            ...displayImageUpload,
            mouseOver: true,
          })
        }
        onMouseLeave={() =>
          setDisplayImageUpload({
            ...displayImageUpload,
            mouseOver: false,
          })
        }
      >
        {imageURL || newImagePreview ? (
          <div
            className="image-preview"
            style={{
              backgroundImage: `url(${newImagePreview})`,
            }}
          />
        ) : (
          <p className="no-profile-picture"> Upload new image </p>
        )}
        <div className="image-upload">
          <div
            className={
              displayImageUpload.mouseOver === true &&
              displayImageUpload.newImageAdded !== true
                ? "image-upload"
                : "image-upload-hidden"
            }
          >
            <input
              type="file"
              name="file"
              id="file"
              onChange={(e) => fileChangedHandler(e)}
              accept="image/png, image/jpeg"
              className="inputfile"
            />
            <label htmlFor="file" className="no-profile-picture">
              {" "}
              Upload new image{" "}
            </label>{" "}
          </div>{" "}
          {newImagePreview && newImage !== null ? (
            uploading !== true ? null : (
              <div className="uploading-container"> Uploading... </div>
            )
          ) : null}{" "}
        </div>{" "}
      </div>
      {newImagePreview && newImage !== null && uploading !== true ? (
        <div className="image-button-container">
          <div
            className="image-remove-button"
            onClick={() => removeImageHandler()}
          >
            Cancel{" "}
          </div>
          <div className="image-upload-button" onClick={(e) => uploadImage(e)}>
            Save{" "}
          </div>{" "}
        </div>
      ) : null}{" "}
    </div>
  );
};

export default connect(null, {
  toggleFolderUpdate,
})(ImageUpload);
