import React from "react";
import "./style.scss";

export default function Folder({
  folder,
  selectFolderHandler,
  selectedFolder,
  addParentFolderHandler,
  moveFolderId,
}) {
  const fetchChildFoldersHandler = (e) => {
    e.preventDefault();
    addParentFolderHandler(folder);
  };

  return (
    <>
      {folder._id === moveFolderId ? (
        <div className="moveFolder-folder-item-disabled">
          <div className="name">{folder.name}</div>
          <div className="disabled-notice">
            Can't move this folder into itself
          </div>
        </div>
      ) : (
        <div
          className="moveFolder-folder-item"
          onClick={() => selectFolderHandler(folder)}
        >
          <div className="folder-info">
            <div
              className={
                selectedFolder && selectedFolder._id === folder._id
                  ? "radio-button-icon radio-button-icon-active"
                  : "radio-button-icon"
              }
            >
              {selectedFolder && selectedFolder._id === folder._id ? (
                <ion-icon name="radio-button-on"></ion-icon>
              ) : (
                <ion-icon name="radio-button-off-outline"></ion-icon>
              )}
            </div>
            <div className="name">{folder.name}</div>
          </div>

          {folder.folders.length > 0 ? (
            <div
              className="open-icon"
              onClick={(e) => fetchChildFoldersHandler(e)}
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
