import {
  DISABLE_ALL_MODAL,
  TOGGLE_AUTH_MODAL,
  TOGGLE_SETTINGS_MODAL,
  TOGGLE_CREATE_FOLDER_MODAL,
  TOGGLE_ADD_TO_FOLDER_MODAL,
  TOGGLE_EDIT_TRACK_MODAL,
  TOGGLE_UPLOAD_TRACK_MODAL,
  TOGGLE_SHARE_TRACK_MODAL,
  TOGGLE_SHARE_FOLDER_MODAL,
  TOGGLE_DELETE_OBJECT_MODAL,
  TOGGLE_SHOW_DOWNLOADS_MODAL,
  TOGGLE_ADD_FROM_CATALOG_MODAL,
  TOGGLE_UPLOAD_STATUS_MODAL,
  TOGGLE_CREATE_REQUEST_MODAL,
  TOGGLE_INVITE_SUBMISSION_MODAL,
  TOGGLE_ACCOUNT_SETTINGS_MODAL,
  TOGGLE_FOLDER_SETTINGS_MODAL,
  TOGGLE_VIEW_TRACK_MODAL,
  TOGGLE_MOVE_FOLDER_MODAL,
} from "../actions/types";

const initialState = {
  modalOpen: false,
  authOpen: false,
  authState: "register",
  settingsOpen: false,
  createFolderOpen: false,
  addToFolderOpen: false,
  editTrackOpen: false,
  shareTrackOpen: false,
  uploadTrackOpen: false,
  shareTrackOpen: false,
  shareFolderOpen: false,
  deleteObjectOpen: false,
  showDownloadsOpen: false,
  showDownloadsTrackId: null,
  addToFolderTrackId: null,
  shareTrackObject: {},
  shareFolderObject: {},
  editTrackObject: {},
  folderSettingsObject: {},
  deleteObject: {},
  addFromCatalogOpen: false,
  addFromCatalogFolderId: null,
  uploadStatusOpen: false,
  uploadContext: null,
  uploadContextType: null,
  createRequestOpen: false,
  inviteSubmissionOpen: false,
  accountSettingsOpen: false,
  folderSettingsOpen: false,
  viewTrackOpen: false,
  viewTrackId: null,
  viewTrackCurrentPlaylist: null,
  viewTrackPlaylistIndex: null,
  viewTrackCurrentFolderImageURL: null,
  moveFolderOpen: false,
  moveFolderId: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case DISABLE_ALL_MODAL:
      return {
        ...state,
        modalOpen: false,
        authOpen: false,
        settingsOpen: false,
        createFolderOpen: false,
        addToFolderOpen: false,
        editTrackOpen: false,
        editFolderOpen: false,
        shareTrackOpen: false,
        shareTrackOpen: false,
        shareFolderOpen: false,
        deleteOpen: false,
        showDownloadsOpen: false,
        uploadContext: null,
        uploadContextType: null,
      };

    case TOGGLE_AUTH_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        authOpen: !state.authOpen,
        authState: action.authState,
      };

    case TOGGLE_SETTINGS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        settingsOpen: !state.settingsOpen,
      };

    case TOGGLE_CREATE_FOLDER_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        createFolderOpen: !state.createFolderOpen,
        uploadContext: action.uploadContext,
        uploadContextType: action.uploadContextType,
      };

    case TOGGLE_ADD_TO_FOLDER_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        addToFolderOpen: !state.addToFolderOpen,
        addToFolderTrackId: payload,
      };

    case TOGGLE_EDIT_TRACK_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        editTrackOpen: !state.editTrackOpen,
        editTrackObject: payload,
      };

    case TOGGLE_SHARE_TRACK_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        shareTrackOpen: !state.shareTrackOpen,
        shareTrackObject: payload,
      };

    case TOGGLE_SHARE_FOLDER_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        shareFolderOpen: !state.shareFolderOpen,
        shareFolderObject: payload,
      };

    case TOGGLE_DELETE_OBJECT_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        deleteObjectOpen: !state.deleteObjectOpen,
        deleteObject: payload,
        editTrackOpen: false,
      };

    case TOGGLE_SHOW_DOWNLOADS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        showDownloadsOpen: !state.showDownloadsOpen,
        showDownloadsTrackId: payload,
      };

    case TOGGLE_UPLOAD_TRACK_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        uploadTrackOpen: !state.uploadTrackOpen,
        uploadContext: action.uploadContext,
        uploadContextType: action.uploadContextType,
      };

    case TOGGLE_ADD_FROM_CATALOG_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        addFromCatalogOpen: !state.addFromCatalogOpen,
        addFromCatalogFolderId: payload.addFromCatalogFolderId,
      };

    case TOGGLE_UPLOAD_STATUS_MODAL:
      return {
        ...state,
        modalOpen: payload === "close" ? false : !state.modalOpen,
        uploadStatusOpen: payload === "close" ? false : !state.uploadStatusOpen,
      };

    case TOGGLE_CREATE_REQUEST_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        createRequestOpen: !state.createRequestOpen,
      };

    case TOGGLE_INVITE_SUBMISSION_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        inviteSubmissionOpen: !state.inviteSubmissionOpen,
      };

    case TOGGLE_ACCOUNT_SETTINGS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        accountSettingsOpen: !state.accountSettingsOpen,
      };

    case TOGGLE_FOLDER_SETTINGS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        folderSettingsOpen: !state.folderSettingsOpen,
        folderSettingsObject: payload,
      };

    case TOGGLE_VIEW_TRACK_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        viewTrackOpen: !state.viewTrackOpen,
        viewTrackId: action.trackId,
        viewTrackCurrentPlaylist: action.currentPlaylist,
        viewTrackPlaylistIndex: action.playlistIndex,
        viewTrackCurrentFolderImageURL: action.currentFolderImageURL,
      };

    case TOGGLE_MOVE_FOLDER_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
        moveFolderOpen: !state.moveFolderOpen,
        moveFolderId: payload,
      };

    default:
      return state;
  }
};
