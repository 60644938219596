import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { toggleFolderUpdate } from "../../../../actions/folder";
import axios from "axios";
import { toast } from "react-toastify";

import { contextMenu } from "react-contexify";

import { Mixpanel } from "../../../../utils/Mixpanel";

const InviteInput = ({
  user,
  folder,
  newInvitePermission,
  toggleFolderUpdate,
}) => {
  const [invitePermission, setInvitePermission] = useState();
  const [shareEmails, setShareEmails] = useState([]);

  useEffect(() => {
    setInvitePermission(
      newInvitePermission ? newInvitePermission : "view-and-download"
    );
  }, [newInvitePermission]);

  const invite = async (e) => {
    e.preventDefault();

    if (!shareEmails.length) return;

    let canEdit = false,
      canView = false,
      canDownload = false;
    if (invitePermission === "full-access") {
      canEdit = true;
      canView = true;
      canDownload = true;
    } else if (invitePermission === "view-and-download") {
      canView = true;
      canDownload = true;
    } else if (invitePermission === "view-only") {
      canView = true;
    }

    Promise.all(
      shareEmails.map((email) => {
        axios.post(`/api/folders/inviteUser/${folder._id}`, {
          email: email,
          permissions: { canEdit, canView, canDownload },
        });
        Mixpanel.track("Invite to folder", {
          invitedEmail: email,
          folderId: folder._id,
          sentFrom: user.username,
        });
      })
    )
      .then(() => {
        toast.success(
          shareEmails.length > 1 ? "Invites sent" : "Invite sent",
          "success"
        );

        toggleFolderUpdate();
        setShareEmails([]);
      })
      .catch((error) => toast.error(error.message));
  };

  // Display context menu
  function displayMenu(e) {
    contextMenu.show({
      id: "new-folder-invite",
      event: e,
    });
  }

  return (
    <div className="share-form">
      <div className="form-box email-form-container">
        <div className="email-form">
          <ReactMultiEmail
            placeholder="Invite via email address"
            emails={shareEmails}
            onChange={(emails) => setShareEmails(emails)}
            validateEmail={(email) => isEmail(email)}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    <ion-icon name="close"></ion-icon>
                  </span>
                </div>
              );
            }}
          />

          <div className="permissions-field" onClick={(e) => displayMenu(e)}>
            <div className="permissions-container">
              <div className="permissions-text">
                {invitePermission === "full-access" ? "Full access" : null}
                {invitePermission === "view-and-download"
                  ? "View and download"
                  : null}
                {invitePermission === "view-only" ? "View only" : null}
              </div>
              <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
          </div>
        </div>

        <div className={"button invite-button"} onClick={invite}>
          Invite
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  newInvitePermission: state.folder.invitePermission,
});

export default connect(mapStateToProps, { toggleFolderUpdate })(InviteInput);
