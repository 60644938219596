import React, { useEffect, useState, Fragment } from "react";
import { toggleShowDownloadsModal } from "../../../actions/modal";
import getRequestUrl from '../../../utils/getRequestUrl';

import axios from "axios";
import { connect } from "react-redux";

import "./style.scss";

const ShowDownloadsModal = ({ toggleShowDownloadsModal, trackId }) => {
  const [track, setTrack] = useState();

  async function fetchTrack() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        getRequestUrl(`/track/${trackId}`, `/api/tracks/${trackId}`),
        config);

      await setTrack(res.data);
    } catch (error) {
      console.log("get downloaders error");
    }
  }

  useEffect(() => {
    fetchTrack();
  }, []);

  return (
    <div className="showDownloadsModal">
      {track ? (
        <Fragment>
          <div className="content-top bottom-16">
            <div className="title">
              {track.downloadedUsers.length === 1
                ? "1 download"
                : `${track.downloadedUsers.length} downloads`}
            </div>
            <div className="close-icon" onClick={toggleShowDownloadsModal}>
              <ion-icon name="close"></ion-icon>
            </div>
          </div>
          <div className="content-middle">
            {track.downloadedUsers
              ? track.downloadedUsers.map((user) => (
                  <div className="item">
                    <div className="icon">
                      <ion-icon name="person-outline"></ion-icon>
                    </div>
                    <div className="name">{user.username}</div>
                  </div>
                ))
              : null}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  trackId: state.modal.showDownloadsTrackId,
});

export default connect(mapStateToProps, {
  toggleShowDownloadsModal,
})(ShowDownloadsModal);
