import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { loadUser } from "../../../actions/auth";
import { Link } from "react-router-dom";
import moment from "moment";
import "../style.scss";

const Submission = ({ submission, tab }) => {
  const renderUserIcon = () => {
    return submission.toUser ? (
      submission.toUser.profileImageURL ? (
        <div
          className="user-icon"
          style={{
            backgroundImage: `url(${submission.toUser.profileImageURL})`,
          }}
        ></div>
      ) : (
        <div className="user-icon">{submission.toUser.username.charAt(0)}</div>
      )
    ) : (
      <div className="user-icon">{submission.toUserEmail.charAt(0)}</div>
    );
  };

  const renderUsername = () => {
    return `${
      submission.toUser ? submission.toUser.username : submission.toUserEmail
    }`;
  };

  return (
    <Link
      to={`/dashboard/shared/open/${submission._id}`}
      className="submission-container"
    >
      <div className="left">
        <div className="user-icon-container">{renderUserIcon()}</div>
        <div className="submission-info">
          <div className="title">
            {submission.folder ? submission.folder.name : null}
          </div>
          <div className="subtext">{renderUsername()}</div>
        </div>
      </div>
      <div className="right">
        <div className="date">{moment(submission.createdAt).format("ll")}</div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser })(Submission);
