import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toggleShareFolderModal } from "../../../actions/modal";
import { toggleFolderUpdate } from "../../../actions/folder";
import { toast } from "react-toastify";

import { connect } from "react-redux";

import PendingInvite from "./PendingInvite";
import InvitedMember from "./InvitedMember";
import InviteInput from "./InviteInput";
import "./style.scss";

import { Mixpanel } from "../../../utils/Mixpanel";

let env_check = process.env.NODE_ENV === "production";

const ShareFolderForm = ({
  toggleShareFolderModal,
  folder,
  updateToggle,
  toggleFolderUpdate,
}) => {
  const [publicViewing, setPublicViewing] = useState(folder.publicViewing);
  const [publicDownloads, setPublicDownloads] = useState(
    folder.publicDownloads
  );
  const [users, setUsers] = useState();
  const [pendingInvites, setPendingInvites] = useState();

  const linkRef = useRef();

  const getUsers = async () => {
    try {
      const res = await axios.get(`/api/folders/users/${folder._id}`);

      if (res.data.error) throw new Error(res.data.message);
      setUsers(res.data.users);
      setPendingInvites(res.data.pendingInvites);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, [updateToggle]);

  const copyURL = () => {
    linkRef.current.select();

    document.execCommand("copy");
    toast.success("Link copied");

    Mixpanel.track("Share folder via link", {
      folderId: folder._id,
    });
  };

  const onPublicViewingChange = async () => {
    await axios.patch(`/api/folders/updateSharing/${folder._id}`, {
      publicViewing: !publicViewing,
      publicDownloads,
    });
    setPublicViewing(!publicViewing);

    toast.success("Updated sharing access");

    toggleFolderUpdate();
  };

  const onPublicDownloadsChange = async () => {
    await axios.patch(`/api/folders/updateSharing/${folder._id}`, {
      publicViewing,
      publicDownloads: !publicDownloads,
    });
    setPublicDownloads(!publicDownloads);

    toast.success("Updated sharing access");

    toggleFolderUpdate();
  };

  return (
    <div className="shareFolderModal">
      <>
        <div className="content-top bottom-16">
          <h2 className="title">Share Folder</h2>
          <div className="close-icon" onClick={() => toggleShareFolderModal()}>
            <ion-icon name="close"></ion-icon>
          </div>
        </div>

        {folder.permissions.canEdit ? (
          <>
            <div className="content-middle">
              <InviteInput folder={folder} />

              <div className="member-list">
                {users
                  ? users.map((user, index) => (
                      <InvitedMember
                        key={index}
                        user={user}
                        folderId={folder._id}
                        ownerId={folder.owner._id}
                      />
                    ))
                  : null}
                {pendingInvites
                  ? pendingInvites.map((invite, index) => (
                      <PendingInvite
                        key={index}
                        invite={invite}
                        id={folder._id}
                      />
                    ))
                  : null}
              </div>
            </div>

            <div className="content-bottom">
              <div className="share-form">
                <label htmlFor="invite-email">Shareable link</label>
                <div className="form-box">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={publicViewing}
                      onClick={() => onPublicViewingChange()}
                    />
                    <span className="slider round"></span>
                  </label>
                  <div className="link-access-text">
                    {publicViewing
                      ? "Anyone with the link can access"
                      : "Only people invited can access"}
                  </div>
                </div>
              </div>

              {publicViewing ? (
                <>
                  <div className="share-form">
                    <label htmlFor="invite-email">Download Access</label>
                    <div className="form-box">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={publicDownloads}
                          onClick={() => onPublicDownloadsChange()}
                        />
                        <span className="slider round"></span>
                      </label>
                      <div className="link-access-text">
                        {publicDownloads
                          ? "Anyone with the link can download tracks"
                          : "Downloads are disabled"}
                      </div>
                    </div>
                  </div>

                  <div className="share-form">
                    <div className="form-box">
                      <input
                        className="text-field sharing-link-field"
                        type="text"
                        name="username"
                        ref={linkRef}
                        autoComplete="off"
                        value={
                          env_check
                            ? `https://catalog.studio/folder/${folder._id}`
                            : `http://localhost:3000/folder/${folder._id}`
                        }
                      />

                      <div
                        className={"button-secondary invite-button"}
                        onClick={copyURL}
                      >
                        Copy Link
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : (
          <div className="share-form">
            <div className="form-box just-link">
              <input
                className="text-field sharing-link-field"
                type="text"
                name="username"
                ref={linkRef}
                autoComplete="off"
                value={
                  env_check
                    ? `https://catalog.studio/folder/${folder._id}`
                    : `http://localhost:3000/folder/${folder._id}`
                }
              />

              <div
                className={"button-secondary invite-button"}
                onClick={copyURL}
              >
                Copy Link
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({
  folder: state.modal.shareFolderObject,
  user: state.auth.user,
  updateToggle: state.folder.updateToggle,
});

export default connect(mapStateToProps, {
  toggleShareFolderModal,
  toggleFolderUpdate,
})(ShareFolderForm);
