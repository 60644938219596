import React, { useState, useEffect } from "react";
import axios from "axios";
import AnalyticsTrack from "../../components/Tracks/AnalyticsTrack";
import TotalPlaysChart from "./TotalPlaysChart";
import "./style.scss";

const AnalyticsPage = () => {
  const [tracks, setTracks] = useState();
  const [playData, setPlayData] = useState([]);

  const fetchTracks = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var res = await axios.get(`/api/users/getUploadsWithAnalytics`, config);

      setTracks(res.data.tracks);
    } catch (error) {
      console.log("get loops error");
    }
  };

  const fetchPlayData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var res = await axios.get(`/api/users/getTotalPlays`, config);

      setPlayData(res.data);
    } catch (error) {
      console.log("get total plays error");
    }
  };

  useEffect(() => {
    fetchTracks();
    fetchPlayData();
  }, []);

  return (
    <div className="analytics-page page-size">
      <div className="page-container">
        <div className="page-top">
          <div className="page-title">Analytics</div>
        </div>

        <div className="page-content">
          <div className="page-section">
            <div className="page-label">Total Plays</div>

            {playData?.length > 0 && <TotalPlaysChart playData={playData} />}
          </div>

          <div className="page-section">
            <div className="page-label">Tracks</div>
            <div className="tracks-label-container">
              <div className="tracks-label">#</div>
              <div className="tracks-label">Title</div>
              <div className="tracks-label">Plays</div>
              <div className="tracks-label">Date added</div>
              <div className="tracks-label options-label">Options</div>
            </div>

            <div
              className="track-container"
              style={{
                borderTop: `1px solid rgba(255,255,255,0.1)`,
              }}
            >
              {tracks?.map((track, index) => (
                <AnalyticsTrack
                  track={track}
                  trackIndex={index + 1}
                  key={index}
                  playlistIndex={index}
                  currentPlaylist={tracks}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPage;
