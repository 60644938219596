import React from "react";
import { format, parseISO, subDays } from "date-fns";

export default function index({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>{format(parseISO(label), "eeee, d MMM, yyyy")}</h4>
        <p>{payload[0].payload.value.toFixed(0)} Plays</p>
      </div>
    );
  }
  return null;
}
