import React, { useRef, useState, useEffect, Fragment } from "react";
import useOutsideClick from "../../../utils/useOutsideClick";

const UserFilterMenu = ({
  closeMenuHandler,
  userFilters,
  saveFiltersHandler,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const menuRef = useRef();

  const urlParams = new URLSearchParams(window.location.search);
  const filterParams = urlParams.get("filters");

  const setCurrentFilters = () => {
    setSelectedOptions(filterParams ? JSON.parse(filterParams).user : []);
  };

  useEffect(() => {
    setCurrentFilters();
  }, []);

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  const selectOptionHandler = (filter) => {
    if (selectedOptions.includes(filter)) {
      setSelectedOptions(
        selectedOptions.filter((selectedFilter) => selectedFilter !== filter)
      );
    } else {
      setSelectedOptions([...selectedOptions, filter]);
    }
  };

  const clearOptionsHandler = () => {
    setSelectedOptions([]);
  };

  const saveAndCloseFilters = () => {
    saveFiltersHandler("USER_FILTERS", selectedOptions);
    closeMenuHandler();
  };

  return (
    <div className="filter-menu" ref={menuRef}>
      {userFilters !== undefined && userFilters.length !== 0 ? (
        <Fragment>
          <div className="filter-options-container">
            {userFilters.map((filter) => (
              <div
                className="filter-option"
                key={filter._id}
                onClick={() => selectOptionHandler(filter._id)}
              >
                <div className="icon">
                  {selectedOptions.includes(filter._id) ? (
                    <ion-icon name="checkbox"></ion-icon>
                  ) : (
                    <ion-icon name="square-outline"></ion-icon>
                  )}
                </div>
                <div className="text">{filter.username}</div>
              </div>
            ))}
          </div>
          <div className="filter-bottom">
            <div className="clear-button" onClick={() => clearOptionsHandler()}>
              Clear
            </div>
            <div className="save-button" onClick={() => saveAndCloseFilters()}>
              Save
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="empty-filter-container">No users to filter</div>
      )}
    </div>
  );
};

export default UserFilterMenu;
