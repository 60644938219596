import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useOutsideClick from "../../../utils/useOutsideClick";
import { useLocation } from "react-router-dom";
import {
  toggleCreateFolderModal,
  toggleUploadStatusModal,
  toggleUploadTrackModal,
} from "../../../actions/modal";

import "./style.scss";

const CreateMenu = ({
  closeMenuHandler,
  contextObject,
  uploadStatusOpen,
  toggleUploadStatusModal,
  toggleCreateFolderModal,
  toggleUploadTrackModal,
}) => {
  const menuRef = useRef();
  const location = useLocation();

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  const openUploadTrackModalHandler = () => {
    if (uploadStatusOpen) {
      toggleUploadStatusModal();
    } else {
      const folderSearch = location.pathname.match("/folder/");

      let uploadContext;

      if (folderSearch) {
        uploadContext = contextObject;
        toggleUploadTrackModal(uploadContext, "folder");
        closeMenuHandler();
      } else {
        toggleUploadTrackModal({});
        closeMenuHandler();
      }
    }
  };

  const openCreateFolderModalHandler = () => {
    const folderSearch = location.pathname.match("/folder/");

    let uploadContext;

    if (folderSearch) {
      uploadContext = contextObject;

      toggleCreateFolderModal(uploadContext, "folder");
      closeMenuHandler();
    } else {
      toggleCreateFolderModal({});
      closeMenuHandler();
    }
  };

  return (
    <div className="create-menu-container" ref={menuRef}>
      <div className="menu-item" onClick={openCreateFolderModalHandler}>
        New folder
      </div>
      <div className="menu-item" onClick={openUploadTrackModalHandler}>
        Upload tracks
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uploadStatusOpen: state.statusBar.uploadStatusOpen,
  contextObject: state.context.contextObject,
});

export default withRouter(
  connect(mapStateToProps, {
    toggleCreateFolderModal,
    toggleUploadStatusModal,
    toggleUploadTrackModal,
  })(CreateMenu)
);
