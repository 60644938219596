import React, { useState } from "react";

import { toggleAccountSettingsModal } from "../../../actions/modal";
import ChangeProfilePicture from "./ChangeProfilePicture";
import EditProfileForm from "./EditProfileForm";

import ChangePasswordForm from "./ChangePasswordForm";

import { connect } from "react-redux";

import "./style.scss";

const AccountSettingsModal = ({ toggleAccountSettingsModal, user }) => {
  const [tab, setTab] = useState("edit-profile");

  return (
    <div className="accountSettingsModal">
      <div className="content-top">
        <h2 className="title">Account Settings</h2>
        <div className="close-icon" onClick={toggleAccountSettingsModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      {user ? (
        <div className="content-middle">
          <div className="settings-menu">
            <div
              className={
                tab === "edit-profile"
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
              onClick={() => setTab("edit-profile")}
            >
              Edit Profile
            </div>
            <div
              className={
                tab === "change-password"
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
              onClick={() => setTab("change-password")}
            >
              Change Password
            </div>
          </div>
          <div className="settings-body">
            {tab === "edit-profile" ? (
              <>
                <ChangeProfilePicture user={user} />
                <EditProfileForm user={user} />
              </>
            ) : (
              <ChangePasswordForm user={user} />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  toggleAccountSettingsModal,
})(AccountSettingsModal);
