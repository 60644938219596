import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useOutsideClick from "../../../../utils/useOutsideClick";

import "./style.scss";

const InvitePermissionsMenu = ({
  closeMenuHandler,
  setInvitePermissionHandler,
}) => {
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  return (
    <div className="invite-permissions-menu-container" ref={menuRef}>
      <div
        className="menu-item"
        onClick={() => setInvitePermissionHandler("full-access")}
      >
        Full access
      </div>
      <div
        className="menu-item"
        onClick={() => setInvitePermissionHandler("view-and-download")}
      >
        View and download
      </div>
      <div
        className="menu-item"
        onClick={() => setInvitePermissionHandler("view-only")}
      >
        View only
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, {})(InvitePermissionsMenu));
