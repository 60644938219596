import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { contextMenu } from "react-contexify";
import { toast } from "react-toastify";
import { selectTrack, togglePlay, loadTrack } from "../../../actions/player";
import { toggleTrackUpdate } from "../../../actions/track";
import {
  toggleShowDownloadsModal,
  toggleViewTrackModal,
  toggleShareTrackModal,
} from "../../../actions/modal";
import { removeUploadTrack } from "../../../actions/uploadTrack";
import { setContextMenuObject } from "../../../actions/contextMenu";
import filterData from "../../../utils/filterData.json";
import "./style.scss";
import moment from "moment";

import { Mixpanel } from "../../../utils/Mixpanel";

const BrowseTrack = ({
  track,
  activeTrackObject,
  currentTime,
  selectTrack,
  user,
  toggleTrackUpdate,
  isPlaying,
  togglePlay,
  currentPlaylist,
  isAuthenticated,
  playlistIndex,
  uploadTracks,
  removeUploadTrack,
  style,
  trackIndex,
  folderImageURL,
  toggleViewTrackModal,
  history,
  setContextMenuObject,
  contextObject,
  artistNameDisplayed,
}) => {
  const [liked, setLiked] = useState();
  const [trackHover, setTrackHover] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const setOriginalLikedHandler = () => {
    setLiked(track.likedUsers.includes(user?._id) ? true : false);
  };

  useEffect(() => {
    setOriginalLikedHandler();
  }, [track]);

  useEffect(() => {
    const newUpload = uploadTracks.findIndex(
      (_track) => _track._id == track._id.toString()
    );

    if (newUpload > -1) {
      setDisabled(true);
      const uploadTrack = uploadTracks[newUpload];
      const pollingRequest = setInterval(() => {
        axios.get(`/api/tracks/status/${uploadTrack._id}`).then((res) => {
          if (res.data === "success") {
            clearInterval(pollingRequest);
            removeUploadTrack(uploadTrack.id);
            toggleTrackUpdate();
          } else if (res.data === "failed") {
            clearInterval(pollingRequest);
            removeUploadTrack(uploadTrack.id);

            toast.error(`Your upload ${track.name} was unable to be processed`);

            toggleTrackUpdate();
          }
        });
      }, 2500);

      // Stop polling after 1 minute
      setTimeout(() => {
        clearInterval(pollingRequest);
      }, 60000);
    } else {
      setDisabled(false);
    }
  }, [uploadTracks]);

  const renderFormattedTextHelper = (tag, type) => {
    const obj = filterData[type].find((o) => o.value === tag);
    let label = "";

    if (obj) {
      label = obj.label;
    }
    return label;
  };

  const selectTrackHandler = () => {
    if (disabled) {
      toast.error("This track is still processing, try again in a few moments");

      return;
    }

    var currentFolderImageURL = folderImageURL ? folderImageURL : null;

    if (activeTrackObject._id !== track._id) {
      selectTrack(track, currentPlaylist, playlistIndex, currentFolderImageURL);
      addPlayAnalytics();
    } else {
      togglePlay(currentTime);
    }
  };

  const addPlayAnalytics = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios.post(
      `/api/tracks/addPlay/${track._id}/${user ? user._id : "null"}`,
      config
    );
  };

  const likeHandler = async (e) => {
    e.stopPropagation();
    const trackId = track._id;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!liked) {
      toast.success("Added to your favorites");
    } else {
      toast.success("Added to your favorites");
    }

    setLiked(!liked);

    const body = JSON.stringify({ trackId });

    await axios.post("/api/users/toggleLike", body, config);
  };

  const toggleViewTrackModalHandler = (e) => {
    e.stopPropagation();

    var currentFolderImageURL = folderImageURL ? folderImageURL : null;

    toggleViewTrackModal(
      track._id,
      currentPlaylist,
      playlistIndex,
      currentFolderImageURL
    );
  };

  const goToTrackHandler = (e) => {
    e.stopPropagation();
    history.push(`/track/${track._id}`);
  };

  // Display context menu
  function displayMenu(e) {
    e.stopPropagation();
    setContextMenuObject(track, "browse-track");

    contextMenu.show({
      id: "browse-track-menu",
      event: e,
    });
  }

  const downloadHandler = async (e) => {
    e.stopPropagation();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const trackId = track._id;

    const body = JSON.stringify({ trackId });

    await axios.post("/api/users/addDownload", body, config);
    toggleTrackUpdate();

    Mixpanel.track("Download track", {
      trackId: trackId,
    });
  };

  return (
    <div className="browse-track-wrapper" onContextMenu={displayMenu}>
      <div
        className="browse-track"
        style={style}
        onMouseEnter={() => setTrackHover(true)}
        onMouseLeave={() => setTrackHover(false)}
        onClick={() => selectTrackHandler()}
      >
        {trackHover ? (
          <div className="play-button">
            {activeTrackObject._id === track._id && isPlaying ? (
              <div className="pause-icon">
                <ion-icon name="pause-sharp"></ion-icon>
              </div>
            ) : (
              <div className="play-icon">
                <ion-icon name="play-sharp"></ion-icon>
              </div>
            )}
          </div>
        ) : activeTrackObject._id === track._id && isPlaying ? (
          <div className="playing-track">
            <ion-icon name="volume-medium-outline"></ion-icon>
          </div>
        ) : (
          <div className="track-number">{trackIndex}</div>
        )}

        <div className="info">
          <div
            className={
              activeTrackObject._id === track._id
                ? "track-name track-name-active"
                : "track-name"
            }
            onClick={(e) => goToTrackHandler(e)}
          >
            {track.name}
          </div>

          {(artistNameDisplayed || artistNameDisplayed) && (
            <div className="track-tags">
              {`${track.owner.username}`}

              {track.genre
                ? `, ${renderFormattedTextHelper(track.genre, "genreOptions")}`
                : null}
              {track.bpm ? `, ${track.bpm} BPM` : null}
              {track.keySignature
                ? `, ${renderFormattedTextHelper(
                    track.keySignature,
                    "keySignatureOptions"
                  )}`
                : null}
            </div>
          )}
        </div>

        <div className="date-added">{moment(track.createdAt).format("ll")}</div>

        <div className="right">
          {trackHover && isAuthenticated ? (
            <div
              className={
                liked ? "like-button like-button-active" : "like-button"
              }
              onClick={(e) => likeHandler(e)}
            >
              {liked ? (
                <ion-icon name="heart"></ion-icon>
              ) : (
                <ion-icon name="heart-outline"></ion-icon>
              )}
            </div>
          ) : (
            <div
              className={
                liked ? "like-button like-button-active" : "like-button"
              }
              onClick={(e) => likeHandler(e)}
            >
              {liked ? <ion-icon name="heart"></ion-icon> : null}
            </div>
          )}

          {isAuthenticated ? (
            <div
              className="comment-button"
              onClick={(e) => toggleViewTrackModalHandler(e)}
            >
              <ion-icon name="chatbubble-outline"></ion-icon>
            </div>
          ) : (
            <div className="comment-button"></div>
          )}

          {contextObject && contextObject.publicDownloads ? (
            <a
              className="download-button"
              download
              href={track.fileURL}
              onClick={(e) => downloadHandler(e)}
            >
              <ion-icon name="download-outline"></ion-icon>
            </a>
          ) : null}

          {isAuthenticated ? (
            <div
              className={
                activeTrackObject._id === track._id && isPlaying
                  ? "menu-button menu-button-active"
                  : "menu-button"
              }
              onClick={(e) => displayMenu(e)}
            >
              <ion-icon name="ellipsis-horizontal"></ion-icon>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  activeTrackObject: state.player.activeTrackObject,
  isPlaying: state.player.isPlaying,
  currentTime: state.player.currentTime,
  isAuthenticated: state.auth.isAuthenticated,
  uploadTracks: state.uploadTrack.uploadTracks,
  contextObject: state.context.contextObject,
});

export default withRouter(
  connect(mapStateToProps, {
    selectTrack,
    loadTrack,
    toggleTrackUpdate,
    togglePlay,
    toggleShowDownloadsModal,
    toggleViewTrackModal,
    removeUploadTrack,
    toggleShareTrackModal,
    setContextMenuObject,
  })(BrowseTrack)
);
