import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { contextMenu } from "react-contexify";
import { setContextMenuObject } from "../../../../actions/contextMenu";

const InvitedMember = ({ user, setContextMenuObject, ownerId }) => {
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    setPermissions(user.permissions);
  }, [user.permissions]);

  // Display context menu
  function displayMenu(e) {
    setContextMenuObject(user, "permissions-user");
    contextMenu.show({
      id: "share-permissions-menu",
      event: e,
    });
  }

  return (
    <div>
      <div className="member-item">
        <div className="member-info">
          {user.profileImageURL ? (
            <div
              className="member-icon"
              style={{
                backgroundImage: `url(${user.profileImageURL})`,
              }}
            ></div>
          ) : (
            <div className="member-icon">{user.username.charAt(0)}</div>
          )}

          <div className="member-name">{user.username}</div>
        </div>

        {ownerId === user._id ? (
          <div className="member-permissions">
            <div className="permission-container" style={{ cursor: "auto" }}>
              <div className="member-permission-text">Created By</div>
            </div>
          </div>
        ) : (
          <div className="member-permissions" onClick={(e) => displayMenu(e)}>
            <div className="permission-container">
              <div className="member-permission-text">
                {permissions && permissions.canEdit ? "Full Access" : null}
                {permissions &&
                !permissions.canEdit &&
                permissions.canDownload &&
                permissions.canView
                  ? "View and download"
                  : null}
                {permissions &&
                !permissions.canEdit &&
                !permissions.canDownload &&
                permissions.canView
                  ? "View only"
                  : null}
              </div>
              <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, { setContextMenuObject })(InvitedMember);
