import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { loadUser } from "../../../../actions/auth";
import { toast } from "react-toastify";

const EditProfileForm = ({ user, loadUser }) => {
  const [formData, setFormData] = useState({
    username: user ? user.username : "",
    email: "",
  });

  const { username, email } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function changeUsername() {
    try {
      const body = {
        username: username,
      };

      if (!username.match("^[a-zA-Z0-9_]+$"))
        throw new Error(
          "Usernames can only include letters, numbers, underscores.",
          "danger"
        );

      const res = await axios.patch("/api/users/setUsername", body);
      if (res.data.error) throw new Error(res.data.message);
      else {
        toast.success("Username successfully changed");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function changeEmail() {
    try {
      const body = {
        email: email,
      };

      const res = await axios.patch("/api/users/setEmail", body);
      if (res.data.error) throw new Error(res.data.message);
      else {
        toast.success("Email successfully changed");
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (username !== user.username) {
      await changeUsername();
    }
    if (email !== "") {
      await changeEmail();
    }

    loadUser();
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="input-container">
        <div className="input-label">Username</div>
        <input
          type="text"
          placeholder={user.username}
          name="username"
          value={username}
          onChange={(e) => onChange(e)}
          className="text-field"
        />
      </div>
      <div className="input-container">
        <div className="input-label">Email</div>
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={email}
          onChange={(e) => onChange(e)}
          className="text-field"
        />
      </div>

      <input
        type="submit"
        className={
          username !== user.username || email !== ""
            ? "button save-button"
            : "button save-button disabled-button"
        }
        value="Submit"
      />
    </form>
  );
};

export default connect(null, { loadUser })(EditProfileForm);
