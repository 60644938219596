import React, { useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { toggleTrackUpdate } from "../../../../actions/track";
import { toast } from "react-toastify";
import selectStyles from "../../../../utils/reactSelectStyle.js";
import filterData from "../../../../utils/filterData.json";
import Select from "react-select";

import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const typeOptions = filterData.typeOptions;
const genreOptions = filterData.genreOptions;
const keySignatureOptions = filterData.keySignatureOptions;

const Metadata = ({ track, toggleTrackUpdate }) => {
  const [trackType, setTrackType] = useState(track.trackType);
  const [genre, setGenre] = useState(track.genre ? track.genre : null);
  const [formData, setFormData] = useState({
    bpm: track.bpm ? track.bpm : null,
    notes: track.notes,
  });
  const [keySignature, setKeySignature] = useState(
    track.keySignature ? track.keySignature : null
  );

  const { bpm, notes } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const renderFormattedTextHelper = (tag, type) => {
    const obj = filterData[type].find((o) => o.value === tag);
    let label = "";

    if (obj) {
      label = obj.label;
    }
    return label;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const trackId = track._id;

    try {
      const body = JSON.stringify({
        bpm,
        notes,
        trackType: trackType.value ? trackType.value : trackType,
        keySignature:
          keySignature !== null
            ? keySignature.value
              ? keySignature.value
              : keySignature
            : null,
        genre: genre !== null ? (genre.value ? genre.value : genre) : null,
      });

      await axios.patch(`/api/tracks/updateTrack/${trackId}`, body, config);

      toggleTrackUpdate();

      toast.success("Updated track");
    } catch (error) {
      toast.error("Failed to update");
    }
  };

  return (
    <>
      <div className="type-input setting-input-container">
        <div className="input-label">Type</div>
        <Select
          options={typeOptions}
          styles={selectStyles}
          value={trackType}
          onChange={(trackType) => setTrackType(trackType)}
          placeholder={
            trackType
              ? renderFormattedTextHelper(trackType, "typeOptions")
              : "Song"
          }
        />
      </div>

      <div className="genre-input setting-input-container">
        <div className="input-label">Genre</div>
        <Select
          options={genreOptions}
          styles={selectStyles}
          value={genre}
          onChange={(genre) => setGenre(genre)}
          placeholder={
            genre ? renderFormattedTextHelper(genre, "genreOptions") : "Genre"
          }
        />
      </div>

      <div className="key-input setting-input-container">
        <div className="input-label">Key Signature</div>
        <Select
          options={keySignatureOptions}
          styles={selectStyles}
          value={keySignature}
          onChange={(keySignature) => setKeySignature(keySignature)}
          placeholder={
            keySignature
              ? renderFormattedTextHelper(keySignature, "keySignatureOptions")
              : "Key"
          }
        />
      </div>
      <div className="bpm-input setting-input-container">
        <div className="input-label">BPM</div>
        <input
          type="text"
          placeholder={bpm ? bpm : "BPM"}
          name="bpm"
          value={bpm}
          onChange={(e) => {
            if (isNaN(Number(e.target.value))) {
              return;
            } else {
              onChange(e);
            }
          }}
          maxLength="3"
          className="text-field"
        />
      </div>

      <div className="notes-input setting-input-container">
        <div className="input-label">Notes</div>
        <input
          type="text"
          placeholder="Notes"
          name="notes"
          value={notes}
          onChange={(e) => onChange(e)}
          className="text-field"
        />
      </div>
      <div className="button save-button" onClick={(e) => onSubmit(e)}>
        Save
      </div>
    </>
  );
};

export default connect(null, { toggleTrackUpdate })(Metadata);
