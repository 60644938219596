import React from "react";
import { connect } from "react-redux";
import BrowseTrackMenu from "./BrowseTrackMenu";
import FolderMenu from "./FolderMenu";
import SharePermissionsMenu from "./SharePermissionsMenu";
import PendingInvitePermissionsMenu from "./PendingInvitePermissionsMenu";
import FolderInviteMenu from "./FolderInviteMenu";

import "./style.scss";

const ContextMenu = ({
  contextObject,
  contextMenuObject,
  user,
  contextMenuType,
}) => {
  return (
    <>
      <BrowseTrackMenu
        folder={contextObject}
        track={contextMenuObject}
        user={user}
      />
      <FolderMenu
        contextFolder={contextMenuObject}
        contextMenuType={contextMenuType}
        user={user}
      />
      <SharePermissionsMenu />
      <PendingInvitePermissionsMenu />
      <FolderInviteMenu />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  contextObject: state.context.contextObject,
  contextMenuType: state.contextMenu.contextMenuType,
  contextMenuObject: state.contextMenu.contextMenuObject,
});

export default connect(mapStateToProps)(ContextMenu);
