import React, { useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useOutsideClick from "../../../../utils/useOutsideClick";

import "./style.scss";

const PermissionsMenu = ({
  closeMenuHandler,
  updatePermissionsHandler,
  removeUserHandler,
}) => {
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  return (
    <div className="permissions-menu-container" ref={menuRef}>
      <div
        className="menu-item"
        onClick={() =>
          updatePermissionsHandler({
            canEdit: true,
            canView: true,
            canDownload: true,
          })
        }
      >
        Full access
      </div>
      <div
        className="menu-item"
        onClick={() =>
          updatePermissionsHandler({
            canEdit: false,
            canView: true,
            canDownload: true,
          })
        }
      >
        View and download
      </div>
      <div
        className="menu-item"
        onClick={() =>
          updatePermissionsHandler({
            canEdit: false,
            canView: true,
            canDownload: false,
          })
        }
      >
        View only
      </div>
      <div className="permissions-divider"></div>
      <div className="menu-item" onClick={() => removeUserHandler()}>
        Remove
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, {})(PermissionsMenu));
