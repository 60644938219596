import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import NotificationMenu from "./NotificationMenu";
import CreateMenu from "./CreateMenu";
import MobileNavMenu from "./MobileNavMenu";
import SettingsMenu from "./SettingsMenu";

import "./style.scss";

const TopBar = ({ user, scrollTop, isAuthenticated }) => {
  const [openCreateMenu, setOpenCreateMenu] = useState(false);
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [openMobileNav, setOpenMobileNav] = useState(false);

  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);
  const [topBarClass, setTopBarClass] = useState();

  const closeSettingsMenuHandler = async () => {
    setOpenSettingsMenu(false);
  };

  const history = useHistory();
  const location = useLocation();

  const historyButtonHandler = (action) => {
    if (action === "back") {
      history.go(-1);
    } else if (action === "forward") {
      history.go(1);
    }
  };

  const closeMenuHandler = async () => {
    setOpenNotificationMenu(false);
    setOpenCreateMenu(false);
  };

  const closeMobileNavHandler = () => {
    setOpenMobileNav(false);
  };

  const setTopBarClassHandler = () => {
    if (isAuthenticated) {
      if (location.pathname.includes("submit-to")) {
        setTopBarClass("top-bar-hidden");
      } else {
        setTopBarClass("top-bar");
      }
    } else {
      setTopBarClass("top-bar-hidden");
    }
  };

  useEffect(() => {
    setUnreadNotificationCountHandler();
    setTopBarClassHandler();
  }, [user, isAuthenticated]);

  const setUnreadNotificationCountHandler = () => {
    if (user) {
      setUnreadNotificationCount(user.unreadNotificationCount);
    }
  };

  const renderUnreadBadge = () => {
    if (unreadNotificationCount > 0) {
      return <div className="unread-badge">{unreadNotificationCount}</div>;
    } else {
      return null;
    }
  };

  return (
    <>
      {openMobileNav ? (
        <MobileNavMenu closeMobileNavHandler={closeMobileNavHandler} />
      ) : null}
      <div className={topBarClass}>
        <div
          className="top-bar-background"
          style={{
            background: scrollTop < 10 ? "transparent" : "#111",

            opacity: scrollTop > 200 ? 1 : scrollTop * 0.01,
            filter: "brightness(40%)",
          }}
        ></div>
        <div className="content-container">
          <div className="top-bar-left">
            <div
              className="mobile-hamburger-menu"
              onClick={() => setOpenMobileNav(true)}
            >
              <ion-icon name="menu"></ion-icon>
            </div>
            <div
              className="back-button"
              onClick={() => historyButtonHandler("back")}
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </div>

            {history.length === 0 ? (
              <div className="forward-button forward-button-inactive">
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>
            ) : (
              <div
                className="forward-button"
                onClick={() => historyButtonHandler("forward")}
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </div>
            )}
          </div>
          <div className="top-bar-right">
            <div
              className={
                openNotificationMenu
                  ? "notification-button notification-button-active"
                  : "notification-button"
              }
              onClick={() => setOpenNotificationMenu(true)}
            >
              {renderUnreadBadge()}
              <ion-icon
                name={
                  openNotificationMenu
                    ? "notifications"
                    : "notifications-outline"
                }
              ></ion-icon>
            </div>

            {user && user.profileImageURL ? (
              <div
                className="profile-image"
                style={{
                  backgroundImage: `url(${user.profileImageURL})`,
                }}
                onClick={() => setOpenSettingsMenu(true)}
              ></div>
            ) : (
              <div
                className="profile-image"
                onClick={() => setOpenSettingsMenu(true)}
              >
                {user ? user.username.charAt(0) : null}
              </div>
            )}

            {openSettingsMenu ? (
              <SettingsMenu closeMenuHandler={closeSettingsMenuHandler} />
            ) : null}

            {openNotificationMenu ? (
              <NotificationMenu
                closeMenuHandler={closeMenuHandler}
                setUnreadNotificationCount={setUnreadNotificationCount}
                user={user}
              />
            ) : null}

            {openCreateMenu ? (
              <CreateMenu closeMenuHandler={closeMenuHandler} />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(TopBar);
