import React, { Fragment, useState } from "react";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../../actions/auth";
import { toast } from "react-toastify";

import "./style.scss";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    passwordResetEmail: "",
  });
  const [showResetPassword, setShowResetPassword] = useState();

  const { email, password, passwordResetEmail } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  async function forgotPassword() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (!passwordResetEmail.match("S+@S+.S+"))
        throw new Error("Invalid Email", "danger");

      const res = await axios.post(
        "/api/users/forgotPassword",
        { email: passwordResetEmail },
        config
      );
      if (res.data.error) throw new Error(res.data.message);

      toast.success(`A password reset link was sent to ${passwordResetEmail}`);
    } catch (error) {
      toast.error(error.message);
    }
  }

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="dashboard/library" />;
  }

  return (
    <Fragment>
      <div className="mainPage">
        <div className="background" />
        <div className="mainContent">
          <div className="loginScreen">
            <div className="description">
              Upload, listen and share your music with anyone, anywhere.
            </div>
            <div className="form">
              <h2 className="title">Log in</h2>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="bottom-16">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    minLength="6"
                    className="text-field"
                  />
                </div>
                <input
                  type="submit"
                  className="button bottom-40 full-width"
                  value="Log in"
                />
              </form>
              <div className="link-text">
                Don't have an account?{" "}
                <Link to="/register" className="link-button">
                  Sign up
                </Link>
                {/** <p
                  className="link-button"
                  onClick={() => setShowResetPassword(true)}
                >
                  Forgot Password?
                </p> **/}
                {showResetPassword ? (
                  <>
                    <label for="passwordResetEmail">
                      Enter your account's email below
                    </label>
                    <input
                      className="text-field"
                      type="email"
                      name="passwordResetEmail"
                      value={passwordResetEmail}
                      onChange={onChange}
                    />
                    <button className="button" onClick={forgotPassword}>
                      Send
                    </button>
                  </>
                ) : null}
              </div>
            </div>

            <Link to="/" className="logo">
              catalog
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
