import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import axios from "axios";
import ReceivedSubmission from "../../../../components/Submission/ReceivedSubmission";

import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";

const InboxList = ({ updateToggle }) => {
  const [isFetching, setIsFetching] = useState(true);
  const [submissionList, setSubmissionList] = useState();

  async function fetchSubmissionList() {
    setIsFetching(true);
    try {
      const res = await axios.get(`/api/submissions/receivedSubmissions`);

      await setSubmissionList(res.data);

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      toast.error("Error getting inbox");
    }
  }

  useEffect(() => {
    fetchSubmissionList();
  }, [updateToggle]);

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();

    toast.success("Sharing link copied to clipboard");
  }

  return (
    <div>
      {!isFetching ? (
        <div className="submission-list">
          {submissionList && submissionList.length > 0 ? (
            submissionList.map((submission, index) => (
              <ReceivedSubmission
                key={index}
                submission={submission._id}
                tab={"inbox"}
                readSubmission={submission.read}
              />
            ))
          ) : (
            <div className="empty-state-container">
              <div className="empty-info">
                <div className="empty-state-title">
                  You haven't received any music yet.
                </div>
                <div className="empty-state-text">
                  Music you receive will show here.
                </div>

                {/** <div className="empty-state-text">
                  Share this link to receive music
                </div>

                <div className="share-link-container">
                  <div className="text-field bottom-24">
                    sendmusic.to/{user ? user.username : null}
                  </div>

                  {document.queryCommandSupported("copy") && (
                    <div
                      className="button copy-link-button"
                      onClick={copyToClipboard}
                    >
                      Copy Link
                    </div>
                  )}
                </div>

                <textarea
                  ref={textAreaRef}
                  value={`localhost:3000/submit-to/${
                    user ? user.username : null
                  }`}
                  style={{
                    height: "1px",
                    width: "1px",
                    opacity: "0",
                    position: "absolute",
                  }}
                />**/}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="empty-state-container">
          <div className="loading-icon">
            <BeatLoader
              sizeUnit={"px"}
              size={9}
              color={"#fff"}
              loading={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect()(InboxList);
