import React from "react";
import { connect } from "react-redux";

import { toggleFolderUpdate } from "../../../actions/folder";
import axios from "axios";
import { toast } from "react-toastify";

// Context Menu
import { Menu, Item, animation } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "pending-invite-permissions-menu";

const PendingInvitePermissionsMenu = ({
  folder,
  invite,
  toggleFolderUpdate,
}) => {
  const updatePermissionsHandler = async ({
    canEdit,
    canDownload,
    canView,
  }) => {
    try {
      const res = await axios.patch(
        `/api/folders/changeInvitePermissions/${folder._id}/${invite._id}`,
        { canEdit, canDownload, canView }
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success(`Updated permissions`);
      toggleFolderUpdate();
    } catch (error) {
      toast.error("Failed to update");
    }
  };

  const removeInviteHandler = async () => {
    try {
      const res = await axios.delete(
        `/api/folders/removeInvite/${folder._id}/${invite._id}`
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success(`Removed access from ${invite.email}`);
      toggleFolderUpdate();
    } catch (error) {
      toast.error("Failed to update");
    }
  };

  return (
    <Menu id={MENU_ID} animation={animation.fade}>
      <Item
        onClick={() =>
          updatePermissionsHandler({
            canEdit: true,
            canView: true,
            canDownload: true,
          })
        }
      >
        Full Access
      </Item>
      <Item
        onClick={() =>
          updatePermissionsHandler({
            canEdit: false,
            canView: true,
            canDownload: true,
          })
        }
      >
        View and download
      </Item>
      <Item
        onClick={() =>
          updatePermissionsHandler({
            canEdit: false,
            canView: true,
            canDownload: false,
          })
        }
      >
        View only
      </Item>
      <Item onClick={() => removeInviteHandler()}>Remove</Item>
    </Menu>
  );
};

const mapStateToProps = (state) => ({
  invite: state.contextMenu.contextMenuObject,
  folder: state.modal.shareFolderObject,
});

export default connect(mapStateToProps, { toggleFolderUpdate })(
  PendingInvitePermissionsMenu
);
