import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toggleFolderUpdate } from "../../../../actions/folder";
import { toast } from "react-toastify";

import "./style.scss";

const EditFolderForm = ({ folder, toggleFolderUpdate }) => {
  const [formData, setFormData] = useState({
    folderName: folder ? folder.name : "",
  });

  const { folderName } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  async function changeFolderName() {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (folderName.length === 0 || !folderName) {
      toast.error("Add a name for your folder");
    } else {
      try {
        const body = JSON.stringify({
          name: folderName,
        });

        await axios.patch(
          `/api/folders/updateFolder/${folder._id}`,
          body,
          config
        );

        toast.success("Updated Folder");
        toggleFolderUpdate();
      } catch (error) {
        toast.error("Failed to update");
      }
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (folderName !== folder.name) {
      await changeFolderName();
    }
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="input-container">
        <div className="input-label">Folder name</div>
        <input
          type="text"
          placeholder={folder ? folder.name : ""}
          name="folderName"
          value={folderName}
          onChange={(e) => onChange(e)}
          className="text-field"
        />
      </div>

      <input
        type="submit"
        className={
          folderName !== folder.name
            ? "button save-button"
            : "button save-button disabled-button"
        }
        value="Submit"
      />
    </form>
  );
};

export default connect(null, { toggleFolderUpdate })(EditFolderForm);
