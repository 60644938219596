import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { userToggleUpdate } from "../../../../actions/auth";
import { toast } from "react-toastify";

import "./style.scss";

const ChangePasswordPage = ({ user, userToggleUpdate }) => {
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    newPassword: "",
    newPasswordVerify: "",
  });

  const [passwordMessage, setPasswordMessage] = useState(" ");

  const { password, newPassword, newPasswordVerify } = passwordForm;

  function onPasswordChange(e) {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  }

  async function changePassword() {
    try {
      if (newPassword && newPassword.length < 6)
        toast.error("Your password must be at least 6 characters");
      else if (newPassword !== newPasswordVerify)
        toast.error("Passwords do not match");
      else if (!password || !newPassword || !newPasswordVerify)
        toast.error("Your password must be at least 6 characters");
      else if (newPassword === password)
        toast.error("That is your current password!");
      else {
        const body = {
          password: password,
          newPassword: newPassword,
        };

        const res = await axios.patch("/api/users/setPassword", body);

        if (res.data.error) throw new Error(res.data.message);
        else {
          toast.success("Password successfully changed");
          userToggleUpdate();
          setPasswordForm({
            password: "",
            newPassword: "",
            newPasswordVerify: "",
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function forgotPassword() {
    try {
      const res = await axios.post("/api/users/forgotPassword", {
        email: user.email,
      });
      if (res.data.error) throw new Error(res.data.message);

      toast.success(`A password reset link was sent to ${user.email}`);
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (newPassword && newPassword.length < 6)
      setPasswordMessage("Your password must be at least 6 characters");
    else if (newPassword !== newPasswordVerify)
      setPasswordMessage("Passwords do not match");
    else if (!password || !newPassword || !newPasswordVerify)
      setPasswordMessage(" ");
    else if (newPassword === password)
      setPasswordMessage("That is your current password!");
    else setPasswordMessage(null);
  }, [password, newPassword, newPasswordVerify]);

  return (
    <div className="change-password-form">
      <label for="password">Current Password</label>
      <input
        className="text-field"
        type="password"
        name="password"
        value={password}
        onChange={onPasswordChange}
        placeholder="Current Password"
      />
      <label for="newPassword">New Password</label>
      <input
        className="text-field"
        type="password"
        name="newPassword"
        value={newPassword}
        onChange={onPasswordChange}
        placeholder="New Password"
      />
      <label for="username">Confirm Password</label>
      <input
        className="text-field"
        type="password"
        name="newPasswordVerify"
        value={newPasswordVerify}
        onChange={onPasswordChange}
        placeholder="Confirm Password"
      />
      <p className="warning-message">{passwordMessage}</p>
      <div
        className={
          passwordMessage
            ? "button save-button disabled-button"
            : "button save-button"
        }
        onClick={changePassword}
      >
        Submit
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  updateToggle: state.track.updateToggle,
});

export default connect(mapStateToProps, { userToggleUpdate })(
  ChangePasswordPage
);
