const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "#181818",
    color: "#fff",
    width: "200px",
    borderColor: isFocused ? "#181818" : "#181818",
    "&:hover": {
      borderColor: isFocused ? "#181818" : "#181818",
    },
    borderRadius: "4px",
    fontSize: "14px",
    boxShadow: "none",
    cursor: "pointer",
    margin: "0px 8px 8px 0px",
    height: "40px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "#fff",
    fontSize: "16px",
  }),
  input: (styles) => ({
    ...styles,
    color: "#fff",
    fontSize: "16px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#b3b3b3",
    fontSize: "16px",
  }),
  menu: (styles) => ({
    ...styles,
    position: "absolute",
    backgroundColor: "#181818",
    borderRadius: "4px",
    border: "1px solid #282828",
    fontSize: "14px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: "#282828",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#fff",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "black"
        : isFocused
        ? "black"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
          ? "white"
          : "white"
        : "white",
      cursor: "pointer",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#000" : "#000"),
      },
    };
  },
};

export default colourStyles;
