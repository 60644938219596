import React from "react";
import { toggleUploadTrackModal } from "../../../actions/modal";
import UploadModalDropzone from "../../Dropzone/UploadModalDropzone";
import { connect } from "react-redux";

import "./style.scss";

const UploadTrackModal = ({ toggleUploadTrackModal, uploadContext }) => {
  return (
    <div className="upload-track-modal">
      <div className="content-top bottom-16">
        <h2 className="title">
          {uploadContext
            ? uploadContext.permissions
              ? uploadContext.permissions.canEdit
                ? `Upload tracks to ${uploadContext.name}`
                : "Upload to Your Tracks"
              : "Upload to Your Tracks"
            : null}
        </h2>
        <div className="close-icon" onClick={toggleUploadTrackModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      <div className="content-middle">
        <UploadModalDropzone uploadContext={uploadContext} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uploadContext: state.modal.uploadContext,
});

export default connect(mapStateToProps, {
  toggleUploadTrackModal,
})(UploadTrackModal);
