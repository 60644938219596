import React, { useState } from "react";

import { toggleFolderSettingsModal } from "../../../actions/modal";
import EditFolderForm from "./EditFolderForm";
import SetColorForm from "./SetColorForm";
import FolderImageUpload from "../../ImageUpload/FolderImage";
import ArtistNameSettings from "./ArtistNameSettings";

import { connect } from "react-redux";

import "./style.scss";

const FolderSettingsModal = ({ toggleFolderSettingsModal, folder }) => {
  const [tab, setTab] = useState("edit-folder");

  return (
    <div className="folderSettingsModal">
      <div className="content-top">
        <h2 className="title">Folder Settings</h2>
        <div className="close-icon" onClick={toggleFolderSettingsModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      {folder ? (
        <div className="content-middle">
          <div className="settings-menu">
            <div
              className={
                tab === "edit-folder"
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
              onClick={() => setTab("edit-folder")}
            >
              Edit folder
            </div>
            <div
              className={
                tab === "set-image" ? "menu-item menu-item-active" : "menu-item"
              }
              onClick={() => setTab("set-image")}
            >
              Set image
            </div>

            <div
              className={
                tab === "artist-name"
                  ? "menu-item menu-item-active"
                  : "menu-item"
              }
              onClick={() => setTab("artist-name")}
            >
              Artist Name Settings
            </div>

            {/**<div
              className={
                tab === "set-color" ? "menu-item menu-item-active" : "menu-item"
              }
              onClick={() => setTab("set-color")}
            >
              Set color
            </div> **/}
          </div>
          <div className="settings-body">
            {tab === "edit-folder" && <EditFolderForm folder={folder} />}
            {tab === "set-image" && (
              <FolderImageUpload
                folderId={folder._id}
                imageURL={folder.folderImageURL}
              />
            )}
            {tab === "set-color" && <SetColorForm folder={folder} />}

            {tab === "artist-name" && <ArtistNameSettings folder={folder} />}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  folder: state.modal.folderSettingsObject,
});

export default connect(mapStateToProps, {
  toggleFolderSettingsModal,
})(FolderSettingsModal);
