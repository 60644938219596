import React, { useState } from "react";
import axios from "axios";
import { toggleCreateFolderModal } from "../../../actions/modal";
import { toggleFolderUpdate } from "../../../actions/folder";
import { loadUser } from "../../../actions/auth";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "./style.scss";

import { Mixpanel } from "../../../utils/Mixpanel";

const NewFolderForm = ({
  toggleCreateFolderModal,
  toggleFolderUpdate,
  uploadContext,
}) => {
  const [formData, setFormData] = useState({
    name: "",
  });

  const location = useLocation();

  const { name } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (uploadContext._id) {
        const res = await axios.post(
          `/api/folders?parentFolder=${uploadContext._id}`,
          { name },
          config
        );
        if (res.data.error) throw new Error(res.data.message);

        Mixpanel.track("Create folder", {
          folderId: res.data._id,
        });
      } else {
        const res = await axios.post("/api/folders", { name }, config);
        if (res.data.error) throw new Error(res.data.message);

        Mixpanel.track("Create folder", {
          folderId: res.data._id,
        });
      }

      toggleFolderUpdate();
      toggleCreateFolderModal();
      loadUser();
    } catch (error) {
      toast.error("Add a name to your folder");
    }
  };

  return (
    <div className="newFolderModal">
      <div className="content-top bottom-16">
        <h2 className="title">
          {uploadContext
            ? uploadContext.permissions
              ? uploadContext.permissions.canEdit
                ? `Create folder in ${uploadContext.name}`
                : "Set folder name"
              : "Set folder name"
            : null}
        </h2>
        <div className="close-icon" onClick={toggleCreateFolderModal}>
          <ion-icon name="close"></ion-icon>
        </div>
      </div>

      <div className="content-middle">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="bottom-16">
            <input
              type="text"
              placeholder="Folder name"
              name="name"
              value={name}
              onChange={(e) => onChange(e)}
              className="text-field"
            />
          </div>

          <input type="submit" className="button full-width" value="Create" />
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  uploadContext: state.modal.uploadContext,
});

export default connect(mapStateToProps, {
  toggleCreateFolderModal,
  toggleFolderUpdate,
  loadUser,
})(NewFolderForm);
