import React, { Fragment } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { logout } from "../../../actions/auth";

const Sidebar = ({ isAuthenticated, closeMobileNavHandler }) => {
  const location = useLocation();

  const sideBarClassHandler = () => {
    let sidebarClass = null;

    if (
      !location.pathname.includes("register") ||
      !location.pathname.includes("login")
    ) {
      if (location.pathname.includes("submit-to")) {
        sidebarClass = "sidebar-hidden";
      } else if (!isAuthenticated) {
        sidebarClass = "sidebar sidebar-logged-out";
      } else {
        sidebarClass = "sidebar";
      }
    } else {
      sidebarClass = "sidebar-hidden";
    }

    return sidebarClass;
  };

  return (
    <div className={sideBarClassHandler()}>
      {isAuthenticated &&
      (location.pathname.includes("dashboard") ||
        location.pathname.includes("folder") ||
        location.pathname.includes("track")) ? (
        <Fragment>
          <div className="logo-title">catalog</div>

          <div className="nav-section">
            <Link
              to="/dashboard/library"
              className={
                (location.pathname.includes("folder") ||
                  location.pathname.includes("track") ||
                  location.pathname.includes("library")) &&
                !location.pathname.includes("browse")
                  ? "nav-item nav-item-active"
                  : "nav-item"
              }
              onClick={() => closeMobileNavHandler()}
            >
              <div className="icon">
                <ion-icon
                  name={
                    (location.pathname.includes("folder") ||
                      location.pathname.includes("track") ||
                      location.pathname.includes("library")) &&
                    !location.pathname.includes("browse")
                      ? "folder-open"
                      : "folder-open-outline"
                  }
                ></ion-icon>
              </div>
              <div className="page-name">Library</div>
            </Link>

            <Link
              to="/dashboard/uploads"
              className={
                location.pathname.includes("uploads")
                  ? "nav-item nav-item-active"
                  : "nav-item"
              }
              onClick={() => closeMobileNavHandler()}
            >
              <div className="icon">
                <ion-icon
                  name={
                    location.pathname.includes("uploads")
                      ? "musical-notes"
                      : "musical-notes-outline"
                  }
                ></ion-icon>
              </div>
              <div className="page-name">Your Tracks</div>
            </Link>

            <Link
              to="/dashboard/shared"
              className={
                location.pathname.includes("shared") &&
                !location.pathname.includes("browse")
                  ? "nav-item nav-item-active"
                  : "nav-item"
              }
              onClick={() => closeMobileNavHandler()}
            >
              <div className="icon">
                <ion-icon
                  name={
                    location.pathname.includes("shared") &&
                    !location.pathname.includes("browse")
                      ? "file-tray-full"
                      : "file-tray-full-outline"
                  }
                ></ion-icon>
              </div>
              <div className="page-name">Shared</div>
            </Link>

            <Link
              to="/dashboard/analytics"
              className={
                location.pathname.includes("analytics")
                  ? "nav-item nav-item-active"
                  : "nav-item"
              }
              onClick={() => closeMobileNavHandler()}
            >
              <div className="icon">
                <ion-icon
                  name={
                    location.pathname.includes("analytics")
                      ? "analytics"
                      : "analytics-outline"
                  }
                ></ion-icon>
              </div>
              <div className="page-name">Analytics</div>
            </Link>
          </div>
          <div className="close-button" onClick={() => closeMobileNavHandler()}>
            Close
          </div>
        </Fragment>
      ) : null}

      {!isAuthenticated ? (
        <Fragment>
          <div className="sidebar-title logo-title">catalog</div>

          <div className="nav-section logged-out-info">
            <div className="description-text">
              Upload, stream and share music privately.
            </div>
            <Link to="/register" className="button">
              Sign up
            </Link>
            <Link to="/login" className="button-secondary">
              Log in
            </Link>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(Sidebar);
