import {
  SELECT_SUBMISSION_CATALOG_TRACKS,
  REMOVE_SUBMISSION_CATALOG_TRACK,
  UPDATE_SUBMISSION_UPLOADED_FILES,
  REMOVE_SUBMISSION_UPLOAD_FILE,
  ADD_SUBMISSION_UPLOAD_FILE,
  TOGGLE_SUBMISSION_UPDATE,
  INCREMENT_TRACKS_UPLOADED,
  SET_UPLOADING,
} from "../actions/types";

const initialState = {
  selectedCatalogTracks: [],
  uploadFiles: [],
  updateToggle: false,
  tracksUploaded: 0,
  uploading: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SELECT_SUBMISSION_CATALOG_TRACKS:
      return {
        ...state,
        selectedCatalogTracks: payload,
      };

    case REMOVE_SUBMISSION_CATALOG_TRACK:
      return {
        ...state,
        selectedCatalogTracks: state.selectedCatalogTracks.filter(
          ({ _id }) => _id !== payload._id
        ),
      };

    case REMOVE_SUBMISSION_UPLOAD_FILE:
      return {
        ...state,
        uploadFiles: state.uploadFiles.filter(({ name }) => name !== payload),
      };

    case ADD_SUBMISSION_UPLOAD_FILE:
      return {
        ...state,
        uploadFiles: [...state.uploadFiles, payload],
      };

    case TOGGLE_SUBMISSION_UPDATE:
      return {
        ...state,
        updateToggle: !state.updateToggle,
      };

    case INCREMENT_TRACKS_UPLOADED:
      return {
        ...state,
        tracksUploaded: state.tracksUploaded + 1,
      };

    case SET_UPLOADING:
      return {
        ...state,
        uploading: payload,
      };

    default:
      return state;
  }
}
