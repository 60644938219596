import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import TrackList from "../../../components/TrackList";
import BeatLoader from "react-spinners/BeatLoader";
import "./style.scss";

const LikesPage = ({ match, updateToggle, user }) => {
  const [tracks, setTracks] = useState();
  const [filters, setFilters] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const filterParams = urlParams.get("filters");

  async function fetchTracks() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      var res = await axios.get(`/api/users/getLikes/${filterParams}`, config);

      setTracks(res.data.tracks);
      setFilters(res.data.filters);
    } catch (error) {
      console.log("get loops error");
    }
  }

  useEffect(() => {
    fetchTracks();
  }, [updateToggle, user, filterParams]);

  return (
    <div className="likes-page page-size">
      <div className="page-container">
        <div className="page-top">
          <div className="page-title">Favorites</div>
        </div>
        <div className="page-content">
          {filters ? (
            <TrackList tracks={tracks} filters={filters} canReorder={true} />
          ) : (
            <div className="loader-icon">
              <BeatLoader
                sizeUnit={"px"}
                size={9}
                color={"#fff"}
                loading={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  updateToggle: state.track.updateToggle,
});

export default connect(mapStateToProps)(LikesPage);
