import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  USER_TOGGLE_UPDATE,
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import { Mixpanel } from "../utils/Mixpanel";

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.accessToken) {
    setAuthToken(localStorage.accessToken);
    navigator?.serviceWorker?.controller?.postMessage(localStorage.accessToken);
  }

  try {
    const res = await axios.get(`/api/auth/`);
    await axios.get("/api/users/getNotifications/0");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

/**
 * Register a new user
 * adds user to Users database
 *
 * @requires {post} /api/users
 */
export const register = (username, email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    username,
    email,
    password,
  });

  try {
    const res = await axios.post("api/auth/register", body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    // Load user after registering
    dispatch(loadUser());
    Mixpanel.track("Successful sign up");
    Mixpanel.identify(email);

    Mixpanel.people.set({
      $email: email,
      $created: new Date(),
      $last_name: username,
    });
  } catch (error) {
    const errors = error?.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          setAlert(
            "The username or email you entered is already taken. Please try a something else",
            "danger"
          )
        );
      });
    }

    Mixpanel.track("Unsuccessful sign up");

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

/**
 * Logs an existing user in
 *
 * @requires {post} /api/auth
 */
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    email,
    password,
  });

  try {
    const res = await axios.post("/api/auth", body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    // Load user after login
    dispatch(loadUser());
    Mixpanel.identify(email);
    Mixpanel.track("Successful login");
  } catch (error) {
    const errors = error?.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => {
        dispatch(
          setAlert(
            "The email and password you entered did not match our records. Please double-check and try again.",
            "danger"
          )
        );
      });
    }

    Mixpanel.track("Unsuccessful login");

    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

/**
 * Logs user out
 */
export const logout = () => async (dispatch) => {
  await dispatch({
    type: LOGOUT,
  });
  Mixpanel.track("Successful sign out");
};

/**
 * Toggle Update
 */
export const userToggleUpdate = () => async (dispatch) => {
  await dispatch({
    type: USER_TOGGLE_UPDATE,
  });
};

/**  Successful registration **/

export const registerSuccess = (payload) => async (dispatch) => {
  await dispatch({
    type: REGISTER_SUCCESS,
    payload: payload,
  });
};
