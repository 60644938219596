import React, { useRef } from "react";
import MobileSidebar from "../MobileSidebar";
import useOutsideClick from "../../../utils/useOutsideClick";

import "./style.scss";

export default function MobileNavMenu({ closeMobileNavHandler }) {
  const navRef = useRef();

  useOutsideClick(navRef, () => {
    closeMobileNavHandler();
  });

  return (
    <div className="mobile-nav-menu" ref={navRef}>
      <MobileSidebar closeMobileNavHandler={closeMobileNavHandler} />
    </div>
  );
}
