import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { setContextMenuObject } from "../../actions/contextMenu";

import { contextMenu } from "react-contexify";

import "./style.scss";

function FolderTile({ folder, isAuthenticated, setContextMenuObject }) {
  const [folderLink, setFolderLink] = useState("/");

  const setFolderLinkHandler = () => {
    let link;
    isAuthenticated
      ? (link = `/dashboard/folder/${folder._id}`)
      : (link = `/folder/${folder._id}`);

    setFolderLink(link);
  };

  useEffect(() => {
    setFolderLinkHandler();
  }, [folder]);

  // Display context menu
  function displayMenu(e) {
    e.stopPropagation();

    setContextMenuObject(folder, "folder");

    contextMenu.show({
      id: "folder-menu",
      event: e,
    });
  }

  return (
    <Link
      to={folderLink}
      className="folder-tile-large folder-margin-right"
      onContextMenu={displayMenu}
    >
      {folder.folderImageURL ? (
        <div
          className="folder-tile-image"
          style={{ backgroundImage: `url(${folder.folderImageURL})` }}
        ></div>
      ) : (
        <div className="folder-tile-image">
          <div className="folder-tile-icon">
            <ion-icon name="musical-notes"></ion-icon>
          </div>
        </div>
      )}

      <div className="bottom">
        <div className="folder-info-container">
          <div className="folder-info">
            <div className="folder-name">{folder.name}</div>
            <div className="folder-text">
              {folder.tracks.length > 0
                ? folder.tracks.length === 1
                  ? "1 track"
                  : `${folder.tracks.length} tracks `
                : !folder.folders.length > 0 && "0 tracks"}

              {folder.folders.length > 0
                ? folder.tracks.length > 0
                  ? folder.folders.length === 1
                    ? " • 1 folder"
                    : ` • ${folder.folders.length} folders`
                  : folder.folders.length === 1
                  ? "1 folder"
                  : `${folder.folders.length} folders`
                : null}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default withRouter(
  connect(mapStateToProps, { setContextMenuObject })(FolderTile)
);
