import {
  ADD_UPLOAD_TRACK,
  REMOVE_UPLOAD_TRACK,
  UPDATE_UPLOAD_TRACK,
  REMOVE_ALL_UPLOAD_TRACKS,
} from "../actions/types";

const initialState = {
  uploadTracks: [],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_UPLOAD_TRACK:
      return {
        ...state,
        uploadTracks: [...state.uploadTracks, payload],
      };

    case REMOVE_UPLOAD_TRACK:
      return {
        ...state,
        uploadTracks: state.uploadTracks.filter(
          (track) => track.id !== payload
        ),
      };

    case UPDATE_UPLOAD_TRACK:
      const selectedTrack = state.uploadTracks.findIndex(
        (element) => element.id === payload.id
      );

      state.uploadTracks[selectedTrack] = {
        ...state.uploadTracks[selectedTrack],
        ...payload,
      };

      return {
        ...state,
        uploadTracks: [...state.uploadTracks],
      };

    case REMOVE_ALL_UPLOAD_TRACKS:
      return {
        ...state,
        uploadTracks: [],
      };

    default:
      return state;
  }
}
