import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import TrackList from "../../../components/TrackList";

import { toggleSubmissionUpdate } from "../../../actions/submission";
import { loadUser } from "../../../actions/auth";
import FolderTile from "../../../components/FolderTile";

import moment from "moment";

import { toast } from "react-toastify";
import "./style.scss";

// Color Extractor
import { usePalette } from "react-palette";

const SubmissionPage = ({
  user,
  match,
  updateSubmissionToggle,
  updateFolderToggle,
  loadUser,
}) => {
  const [submission, setSubmission] = useState();
  const [addedToLibrary, setAddedToLibrary] = useState();

  async function fetchSubmission() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/submissions/submission/${match.params.id}`,
        config
      );

      await setSubmission(res.data);

      let currentFolder = user.folders.find(
        (x) => x._id === res.data.folder._id
      );

      if (currentFolder && currentFolder.addedToLibrary) {
        setAddedToLibrary(true);
      } else {
        setAddedToLibrary(false);
      }
    } catch (error) {
      console.log("Error getting submission");
    }
  }

  useEffect(() => {
    fetchSubmission();
  }, [updateSubmissionToggle, updateFolderToggle, user, match.params.id]);

  async function addtoLibraryHandler() {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      await axios.patch(
        `/api/submissions/addToLibrary/${submission.folder._id}/${submission._id}`,
        config
      );

      toast.success("Added to library");

      setAddedToLibrary(true);
      loadUser();
    } catch (error) {
      toast.error("Error adding to library");
    }
  }

  let renderAddedToLibraryButton = () => {
    if (addedToLibrary) {
      return (
        <div className="added-box">
          <div className="checkmark-icon">
            <ion-icon name="checkmark-circle-outline"></ion-icon>
          </div>
          <div className="added-text">Added to library</div>
        </div>
      );
    } else {
      return (
        <div className="button-secondary" onClick={() => addtoLibraryHandler()}>
          Add to library
        </div>
      );
    }
  };

  // Color Extractor
  const { data, loading, error } = usePalette(
    submission?.folder?.folderImageURL
  );

  return (
    <div className="submission-page page-size">
      {submission ? (
        <div className="page-container">
          <div
            className="page-top"
            style={{
              background: data
                ? `linear-gradient(${data.muted}, rgba(0, 0, 0, 0.3))`
                : `linear-gradient(transparent, rgba(0, 0, 0, 0.3))`,
            }}
          >
            <div className="folder-info-container">
              <div className="folder-info">
                {submission.folder.folderImageURL ? (
                  <div
                    className="folder-image"
                    style={{
                      backgroundImage: `url(${submission.folder.folderImageURL})`,
                    }}
                  ></div>
                ) : null}

                <div className="folder-details">
                  <div
                    className="submission-text"
                    style={{
                      color: submission.folder.colorCode
                        ? "rgba(255, 255, 255,0.7)"
                        : "#b3b3b3",
                    }}
                  >
                    {moment(submission.createdAt).format("ll")}
                  </div>
                  <div className="page-title">{submission.folder.name}</div>

                  <div className="folder-buttons">
                    {user && submission && !submission.folder.inTrash
                      ? user._id !== submission.fromUser._id
                        ? renderAddedToLibraryButton()
                        : null
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content">
            {!submission.folder.inTrash ? (
              <Fragment>
                {submission.folder.folders.length > 0 ? (
                  <div className="folders-container">
                    <div className="page-label">Folders</div>
                    {submission.folder.folders.map((folder) => (
                      <FolderTile key={folder} folder={folder} />
                    ))}
                  </div>
                ) : null}

                {submission.folder.tracks.length > 0 ? (
                  <div className="page-label">Tracks</div>
                ) : null}

                <TrackList tracks={submission.folder.tracks} />
              </Fragment>
            ) : (
              <div className="removed-message">
                Your access to this folder has been removed or the folder has
                been deleted
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  updateSubmissionToggle: state.submission.updateToggle,
  updateFolderToggle: state.folder.updateToggle,
});

export default connect(mapStateToProps, {
  toggleSubmissionUpdate,
  loadUser,
})(SubmissionPage);
