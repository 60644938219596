import React, { useState, Fragment, memo } from "react";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import BrowseTrack from "../Tracks/BrowseTrack";
import TrackFilterButtons from "../TrackFilters/FilterButtons";

import "./style.scss";
import {
  toggleCreateFolderModal,
  toggleUploadStatusModal,
  toggleUploadTrackModal,
} from "../../actions/modal";

import { withRouter } from "react-router-dom";

const TrackList = ({
  tracks,
  user,
  filters,
  restrictedDownloads,
  toggleUploadTrackModal,
  isAuthenticated,
  canAddTracks,
  canReorder,
  onDragEnd,
  contextObject,
  uploadStatusOpen,
  toggleUploadStatusModal,
  folderImageURL,
  artistNameDisplayed,
}) => {
  const [style, setStyle] = useState({
    "background-color": "#282828",
  });
  const location = useLocation();

  const openUploadTrackModalHandler = () => {
    if (uploadStatusOpen) {
      toggleUploadStatusModal();
    } else {
      const folderSearch = location.pathname.match("/folder/");

      let uploadContext;

      if (folderSearch) {
        uploadContext = contextObject;
        toggleUploadTrackModal(uploadContext, "folder");
      } else {
        toggleUploadTrackModal({});
      }
    }
  };

  const renderEmptyState = () => {
    if (location.pathname.includes("uploads")) {
      return (
        <div className="empty-info">
          <div className="empty-state-title">
            You haven't uploaded any tracks yet.
          </div>
          <div className="empty-state-text">Let's upload some tracks</div>
          <div className="button hide-mobile" onClick={toggleUploadTrackModal}>
            Upload tracks
          </div>
        </div>
      );
    } else if (location.pathname.includes("likes")) {
      return (
        <div className="empty-info">
          <div className="empty-state-title">
            You haven't liked any tracks yet.
          </div>
          <div className="empty-state-text">
            Tracks that you like will show here.
          </div>
        </div>
      );
    } else if (location.pathname.includes("browse")) {
      return (
        <div className="empty-info">
          <div className="empty-state-text">No tracks have been added yet.</div>
        </div>
      );
    } else if (location.pathname.includes("downloads")) {
      return (
        <div className="empty-info">
          <div className="empty-state-title">
            You haven't downloaded any tracks yet.
          </div>
          <div className="empty-state-text">
            Tracks that you download will show here.
          </div>
        </div>
      );
    } else if (location.pathname.includes("folder") && isAuthenticated) {
      return (
        <div className="empty-info">
          <div className="empty-state-title">
            No tracks have been added to this folder.
          </div>
          <div className="empty-state-text">
            Once tracks are added, they will show up here.
          </div>

          {canAddTracks && (
            <div
              className="button hide-mobile"
              onClick={() => openUploadTrackModalHandler()}
            >
              Upload tracks
            </div>
          )}
        </div>
      );
    } else if (location.pathname.includes("folder") && !isAuthenticated) {
      return (
        <Fragment>
          <div className="empty-info">
            <div className="empty-state-title">
              No tracks have been added yet.
            </div>
            <div className="empty-state-text">
              Once tracks are added, they will show up here.
            </div>
          </div>
        </Fragment>
      );
    }
  };

  return (
    <div className="tracklist">
      {tracks !== undefined ? (
        tracks.length > 0 ? (
          <div className="tracks-label-container">
            <div className="tracks-label">#</div>
            <div className="tracks-label">Title</div>
            <div className="tracks-label">Date added</div>
            {isAuthenticated ? (
              <div className="tracks-label options-label">Options</div>
            ) : null}
          </div>
        ) : null
      ) : null}

      <div
        className="track-container"
        style={{
          borderTop:
            tracks && tracks.length > 0
              ? `1px solid rgba(255,255,255,0.1)`
              : "0px",
        }}
      >
        {tracks && tracks.length > 0 ? (
          canReorder ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="tracklistDroppable"
                isDropDisabled={!canReorder}
              >
                {(provided, snapshot) => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {tracks.map((track, index) => (
                        <Draggable
                          key={track._id}
                          index={index}
                          draggableId={track._id.toString()}
                          isDragDisabled={!canReorder}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <BrowseTrack
                                style={snapshot.isDragging ? style : null}
                                restrictedDownloads={restrictedDownloads}
                                track={track}
                                key={track._id}
                                playlistIndex={index}
                                currentPlaylist={tracks}
                                trackIndex={index + 1}
                                folderImageURL={folderImageURL}
                                artistNameDisplayed={
                                  artistNameDisplayed === false ? false : true
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          ) : (
            tracks.map((track, index) => (
              <BrowseTrack
                restrictedDownloads={restrictedDownloads}
                track={track}
                key={track._id}
                userId={user ? user._id : null}
                playlistIndex={index}
                currentPlaylist={tracks}
                trackIndex={index + 1}
                folderImageURL={folderImageURL}
                artistNameDisplayed={artistNameDisplayed}
              />
            ))
          )
        ) : (
          <div className="empty-state-container">{renderEmptyState()}</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  uploadStatusOpen: state.statusBar.uploadStatusOpen,
  contextObject: state.context.contextObject,
});

export default memo(
  withRouter(
    connect(mapStateToProps, {
      toggleCreateFolderModal,
      toggleUploadStatusModal,
      toggleUploadTrackModal,
    })(TrackList)
  )
);
