import React, { useState } from "react";
import axios from 'axios';
import PermissionsMenu from "../TrackPermissionsMenu";

const InvitedMember = ({ user, id }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [permissions, setPermissions] = useState(user.permissions);
  const [deleted, setDeleted] = useState(false);

  const closeMenuHandler = async () => {
    setOpenMenu(false);
  };

  const updatePermissionsHandler = async ({canEdit, canDownload, canView}) => {

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.patch(
        `/api/tracks/changeUserPermissions/${id}/${user._id}`,
        {canEdit, canDownload, canView},
        config
      );

      if (res.data.error) throw new Error(res.data.message);
      setOpenMenu(false);
      setPermissions({canEdit, canDownload, canView});
    } catch (error) {
      console.log(error);
    }
  }

  const removeUserHandler = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.delete(
        `/api/tracks/removeUser/${id}/${user._id}`,
        config
      );

      if (res.data.error) throw new Error(res.data.message);
      setOpenMenu(false);
      setDeleted(true);
    } catch (error) {
      console.log(error);
    }
  }

  if (!deleted)
    return (
      <div>
        <div className="member-item">
          <div className="member-info">
            <div className="member-icon"></div>
            <div className="member-name">{user.username}</div>
          </div>
          <div className="member-permissions" onClick={() => setOpenMenu(true)}>
            {openMenu ? (
              <PermissionsMenu 
                closeMenuHandler={closeMenuHandler} 
                updatePermissionsHandler={updatePermissionsHandler} 
                removeUserHandler={removeUserHandler} 
              />
            ) : null}
            <div className="permission-container">
              <div className="member-permission-text">
                {permissions.canEdit ? 'Full Access' : null}
                {!permissions.canEdit && permissions.canDownload && permissions.canView ? 'View and download' : null}
                {!permissions.canEdit && !permissions.canDownload && permissions.canView ? 'View only' : null}
              </div>
              <ion-icon name="chevron-down-outline"></ion-icon>
            </div>
          </div>
        </div>
      </div>
    );
  else return null;
};

export default InvitedMember;
