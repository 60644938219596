import React, { Fragment, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import filterData from "../../../utils/filterData.json";
import { removeUploadTrack } from "../../../actions/uploadTrack";
import { addUploadTrack } from "../../../actions/uploadTrack";
import EditTrack from "./EditTrack";
import moment from "moment";

import { css } from "@emotion/core";
import "./style.scss";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const renderFormattedTextHelper = (tag, type) => {
  const obj = filterData[type].find((o) => o.value === tag);
  let label = "";

  if (obj) {
    label = obj.label;
  }
  return label;
};

const UploadModalTrack = ({
  track,
  user,
  trackType,

  removeUploadTrack,
}) => {
  const removeTrackHandler = async () => {
    removeUploadTrack(track.id);
  };

  const [editOpen, setEditOpen] = useState(false);

  return (
    <div className="upload-modal-track">
      {editOpen ? (
        <EditTrack track={track} setEditOpen={setEditOpen} />
      ) : (
        <>
          {" "}
          <div className="left">
            <div className="info">
              <div className="track-name">{track.name}</div>
              <div className="track-tags">
                {renderFormattedTextHelper(track.trackType, "typeOptions")}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="edit-button" onClick={() => setEditOpen(true)}>
              Edit
            </div>
            <div className="remove-button" onClick={() => removeTrackHandler()}>
              <ion-icon name="close"></ion-icon>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { addUploadTrack, removeUploadTrack })(
  UploadModalTrack
);
