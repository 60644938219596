import React, { useRef } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import CreateFolderModal from "./CreateFolderModal";
import EditTrackModal from "./EditTrackModal";
import ShareTrackModal from "./ShareTrackModal";
import UploadTrackModal from "./UploadTrackModal";
import AddToFolderModal from "./AddToFolderModal";
import ShareFolderModal from "./ShareFolderModal";
import DeleteObjectModal from "./DeleteObjectModal";
import ShowDownloadsModal from "./ShowDownloadsModal";
import AuthModal from "./AuthModal";
import AddFromCatalogModal from "./AddFromCatalogModal";
import UploadStatusModal from "./UploadStatusModal";
import InviteSubmissionModal from "./InviteSubmissionModal";
import AccountSettingsModal from "./AccountSettingsModal";
import FolderSettingsModal from "./FolderSettingsModal";
import TrackModal from "./TrackModal";
import MoveFolderModal from "./MoveFolderModal";
import TrackVersionsModal from "./TrackVersionsModal";

import "./style.scss";

import useOutsideClick from "../../utils/useOutsideClick";
import { disableAllModal } from "../../actions/modal";

const modalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "320px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const editTrackModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "660px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const uploadTrackModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "660px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const shareModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "100vh",
    transform: "translate(-50%, -50%)",
    width: "660px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const uploadStatusModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "660px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const addFromCatalogModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const notificationModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const accountSettingsModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "660px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const folderSettingsModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "660px",
    padding: "0px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const viewTrackModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    height: "600px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
    width: "1000px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const addToFolderModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    maxHeight: "100vh ",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
    width: "480px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const moveFolderModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    maxHeight: "100vh ",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
    width: "480px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const trackVersionsModalStyles = {
  content: {
    background: "#000",
    borderColor: "#282828",
    borderWidth: "1px",
    maxHeight: "100vh ",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px",
    transform: "translate(-50%, -50%)",
    width: "480px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
};

const ModalClass = ({ disableAllModal, ...props }) => {
  const modalRef = useRef(null);

  useOutsideClick(modalRef, () => {
    //disableAllModal();
  });

  return (
    <div>
      <Modal
        isOpen={props.modalOpen}
        style={
          props.editTrackOpen
            ? editTrackModalStyles
            : props.uploadTrackOpen
            ? uploadTrackModalStyles
            : props.shareFolderOpen || props.shareTrackOpen
            ? shareModalStyles
            : props.addFromCatalogOpen
            ? addFromCatalogModalStyles
            : props.uploadStatusOpen
            ? uploadStatusModalStyles
            : props.accountSettingsOpen
            ? accountSettingsModalStyles
            : props.folderSettingsOpen
            ? folderSettingsModalStyles
            : props.viewTrackOpen
            ? viewTrackModalStyles
            : props.addToFolderOpen
            ? addToFolderModalStyles
            : props.moveFolderOpen
            ? moveFolderModalStyles
            : props.trackVersionsModal
            ? trackVersionsModalStyles
            : modalStyles
        }
        ariaHideApp={false}
      >
        <div ref={modalRef} className="modal">
          {props.authOpen && <AuthModal />}
          {props.createFolderOpen && <CreateFolderModal />}
          {props.editTrackOpen && <EditTrackModal />}
          {props.shareTrackOpen && <ShareTrackModal />}
          {props.uploadTrackOpen && <UploadTrackModal />}
          {props.addToFolderOpen && <AddToFolderModal />}
          {props.shareFolderOpen && <ShareFolderModal />}
          {props.deleteObjectOpen && <DeleteObjectModal />}
          {props.showDownloadsOpen && <ShowDownloadsModal />}
          {props.addFromCatalogOpen && <AddFromCatalogModal />}
          {props.uploadStatusOpen && <UploadStatusModal />}
          {props.inviteSubmissionOpen && <InviteSubmissionModal />}
          {props.accountSettingsOpen && <AccountSettingsModal />}
          {props.folderSettingsOpen && <FolderSettingsModal />}
          {props.viewTrackOpen && <TrackModal />}
          {props.moveFolderOpen && <MoveFolderModal />}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  modalOpen: state.modal.modalOpen,
  authOpen: state.modal.authOpen,
  createFolderOpen: state.modal.createFolderOpen,
  editTrackOpen: state.modal.editTrackOpen,
  folderSettingsOpen: state.modal.folderSettingsOpen,
  shareTrackOpen: state.modal.shareTrackOpen,
  addToFolderOpen: state.modal.addToFolderOpen,
  shareFolderOpen: state.modal.shareFolderOpen,
  uploadTrackOpen: state.modal.uploadTrackOpen,
  editTrackOpen: state.modal.editTrackOpen,
  deleteObjectOpen: state.modal.deleteObjectOpen,
  showDownloadsOpen: state.modal.showDownloadsOpen,
  addFromCatalogOpen: state.modal.addFromCatalogOpen,
  uploadStatusOpen: state.modal.uploadStatusOpen,
  inviteSubmissionOpen: state.modal.inviteSubmissionOpen,
  accountSettingsOpen: state.modal.accountSettingsOpen,
  viewTrackOpen: state.modal.viewTrackOpen,
  moveFolderOpen: state.modal.moveFolderOpen,
});

export default connect(mapStateToProps, { disableAllModal })(ModalClass);
