import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom";
import GenreFilterMenu from "../GenreFilterMenu";
import UserFilterMenu from "../UserFilterMenu";
import KeySignatureFilterMenu from "../KeySignatureFilterMenu";
import BPMFilterMenu from "../BPMFilterMenu";
import "./style.scss";

const FilterButtons = ({ filters, history, match }) => {
  const location = useLocation();

  const [selectedFilters, setSelectedFilters] = useState({
    genre: [],
    user: [],
    bpm: [],
    keySignature: [],
    filtersAdded: false,
  });
  const [openMenu, setOpenMenu] = useState(false);

  const closeMenuHandler = async () => {
    setOpenMenu(false);
  };

  const saveFiltersHandler = (filterType, filterArray) => {
    switch (filterType) {
      case "GENRE_FILTERS":
        setSelectedFilters({
          ...selectedFilters,
          genre: filterArray,
          filtersAdded: true,
        });

        break;

      case "USER_FILTERS":
        setSelectedFilters({
          ...selectedFilters,
          user: filterArray,
          filtersAdded: true,
        });

        break;

      case "KEY_SIGNATURE_FILTERS":
        setSelectedFilters({
          ...selectedFilters,
          keySignature: filterArray,
          filtersAdded: true,
        });

        break;

      case "BPM_FILTERS":
        setSelectedFilters({
          ...selectedFilters,
          bpm: filterArray,
          filtersAdded: true,
        });

        break;

      default:
        setSelectedFilters({
          ...selectedFilters,
        });
    }
  };

  const pushHistoryHandler = async () => {
    const selectedFiltersString = JSON.stringify(selectedFilters);

    if (selectedFilters.filtersAdded) {
      history.push(location.pathname + `?filters=${selectedFiltersString}`);
    }
  };

  useEffect(() => {
    pushHistoryHandler();
  }, [selectedFilters]);

  return (
    <div className="filter-container">
      <div className="filters">
        {!location.pathname.includes("uploads") ? (
          <div className="filter-button-container">
            <div
              className={
                selectedFilters.user.length !== 0
                  ? "filter-button filter-button-active"
                  : "filter-button"
              }
              onClick={() => setOpenMenu("user")}
            >
              Creator
            </div>
            {openMenu === "user" ? (
              <UserFilterMenu
                closeMenuHandler={closeMenuHandler}
                userFilters={filters.userFilters}
                saveFiltersHandler={saveFiltersHandler}
              />
            ) : null}
          </div>
        ) : null}

        <div className="filter-button-container">
          <div
            className={
              selectedFilters.genre.length !== 0
                ? "filter-button filter-button-active"
                : "filter-button"
            }
            onClick={() => setOpenMenu("genre")}
          >
            Genre
          </div>

          {openMenu === "genre" ? (
            <GenreFilterMenu
              closeMenuHandler={closeMenuHandler}
              genreFilters={filters.genreFilters}
              saveFiltersHandler={saveFiltersHandler}
            />
          ) : null}
        </div>

        <div className="filter-button-container">
          <div
            className={
              selectedFilters.keySignature.length !== 0
                ? "filter-button filter-button-active"
                : "filter-button"
            }
            onClick={() => setOpenMenu("keySignature")}
          >
            Key
          </div>
          {openMenu === "keySignature" ? (
            <KeySignatureFilterMenu
              closeMenuHandler={closeMenuHandler}
              keySignatureFilters={filters.keySignatureFilters}
              saveFiltersHandler={saveFiltersHandler}
            />
          ) : null}
        </div>

        <div className="filter-button-container">
          <div
            className={
              selectedFilters.bpm.length !== 0
                ? "filter-button filter-button-active"
                : "filter-button"
            }
            onClick={() => setOpenMenu("bpm")}
          >
            BPM
          </div>
          {openMenu === "bpm" ? (
            <BPMFilterMenu
              closeMenuHandler={closeMenuHandler}
              bpmFilters={filters.bpmFilters}
              saveFiltersHandler={saveFiltersHandler}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default withRouter(FilterButtons);
