import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toggleUploadStatusModal } from "../../../actions/modal";

const UploadStatus = ({ toggleUploadStatusModal, uploadTracksArray }) => {
  const [uploadingTrack, setUploadingTrack] = useState();
  const [remainingUploadLength, setRemainingUploadLength] = useState();

  const getNextTrack = () => {
    const nextTrack = uploadTracksArray.find(
      (track) => track.status === "uploading"
    );

    let remainingUploadLengthNumber = 0;

    uploadTracksArray.forEach((track) =>
      track.status === "uploading" ? remainingUploadLengthNumber++ : null
    );

    setUploadingTrack(nextTrack);
    setRemainingUploadLength(remainingUploadLengthNumber);
  };

  useEffect(() => {
    getNextTrack();
  }, [uploadTracksArray]);

  return (
    <div className="upload-status-bar">
      <div className="status-bar-left">
        <div className="updating-icon spin">
          <ion-icon name="sync-circle-outline"></ion-icon>
        </div>
        <div className="status-text">
          Uploading {uploadingTrack ? uploadingTrack.name : null}
        </div>
      </div>
      <div className="status-bar-right">
        <div className="open-modal-button" onClick={toggleUploadStatusModal}>
          {remainingUploadLength
            ? remainingUploadLength === 1
              ? "1 track left"
              : `${remainingUploadLength} tracks left`
            : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  uploadTracksArray: state.uploadTrack.uploadTracks,
});

export default connect(mapStateToProps, { toggleUploadStatusModal })(
  UploadStatus
);
