import { SET_CONTEXT_MENU_OBJECT } from "../actions/types";

const initialState = {
  contextMenuObject: {},
  contextMenuType: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_CONTEXT_MENU_OBJECT:
      return {
        ...state,
        contextMenuObject: action.contextMenuObject,
        contextMenuType: action.contextMenuType,
      };

    default:
      return state;
  }
}
