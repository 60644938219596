import React, { useState, useEffect } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./style.scss";

const ResetPassword = ({ match }) => {
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    passwordVerify: "",
  });
  const [passwordMessage, setPasswordMessage] = useState(" ");

  const [user, setUser] = useState();

  // Invalid token redirects to home page
  const [invalidToken, setInvalidToken] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const { password, passwordVerify } = passwordForm;

  // Decodes the token from the URL and updates state
  async function decodeURL() {
    try {
      const accessToken = match.params.accessToken;

      const body = { accessToken };

      const res = await axios.post("/api/auth/verifyPasswordResetToken", body);
      if (res.data.error) throw new Error(res.data.message);

      setUser(res.data.user);
    } catch (error) {
      toast.error(error.message);
      setInvalidToken(true);
    }
  }

  useEffect(() => {
    decodeURL();
  }, []);

  async function setPassword() {
    try {
      const body = { userId: user._id, password };

      const res = await axios.patch("/api/users/resetPassword", body);
      if (res.data.error) throw new Error(res.data.message);

      toast.success("Your password has been updated");

      setRedirect(true);
    } catch (error) {
      toast.error(error.message);
    }
  }

  function onPasswordChange(e) {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (password && password.length < 6)
      setPasswordMessage("Your password must be at least 6 characters");
    else if (password !== passwordVerify)
      setPasswordMessage("Passwords do not match");
    else if (!password || !passwordVerify) setPasswordMessage(" ");
    else setPasswordMessage(null);
  }, [password, passwordVerify]);

  if (invalidToken) {
    return <Redirect to="/" />;
  }

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="account-page">
      <div className="page-container">
        <div className="page-title">Reset Password</div>
        {user ? (
          <p>Hello {user.username}, please create a new password</p>
        ) : null}

        <label for="newPassword">New Password</label>
        <input
          className="text-field"
          type="password"
          name="password"
          value={password}
          onChange={onPasswordChange}
        />
        <label for="username">Verify New Password</label>
        <input
          className="text-field"
          type="password"
          name="passwordVerify"
          value={passwordVerify}
          onChange={onPasswordChange}
        />
        <p className="warning-message">{passwordMessage}</p>
        <button
          className={
            passwordMessage ? "small-button disabled-button" : "small-button"
          }
          onClick={setPassword}
          disabled={Boolean(passwordMessage)}
        >
          Change Password
        </button>
      </div>
    </div>
  );
};

export default connect()(ResetPassword);
