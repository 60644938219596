import React, { useState, useEffect } from "react";
import "./style.scss";
import { connect } from "react-redux";
import axios from "axios";

const AddFromCatalogModal = ({}) => {
  return (
    <div className="addFromCatalogModal">
      <div className="content-top">
        <h2 className="title">Select tracks</h2>
        <div className="close-icon">
          <ion-icon name="close"></ion-icon>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  addFromCatalogFolderId: state.modal.addFromCatalogFolderId,
});

export default connect(mapStateToProps, {})(AddFromCatalogModal);
