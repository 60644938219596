import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import TrackList from "../../../components/TrackList";
import { toast } from "react-toastify";

import { loadUser } from "../../../actions/auth";

import "./style.scss";
import {
  toggleShareFolderModal,
  toggleUploadTrackModal,
  toggleCreateFolderModal,
  toggleAuthModal,
} from "../../../actions/modal";

import {
  toggleFolderUpdate,
  setCurrentFolderColor,
  setArtistNameDisplayed,
} from "../../../actions/folder";
import BeatLoader from "react-spinners/BeatLoader";
import { setContextObject } from "../../../actions/context";
import FolderTile from "../../../components/FolderTile";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

import { Mixpanel } from "../../../utils/Mixpanel";

import { setContextMenuObject } from "../../../actions/contextMenu";

import { contextMenu } from "react-contexify";

// Color Extractor
import { usePalette } from "react-palette";

const SortableItem = SortableElement(({ folder }) => (
  <FolderTile folder={folder} />
));

const SortableList = SortableContainer(({ folders }) => {
  return (
    <div>
      {folders.map((folder, index) => (
        <SortableItem
          key={`item-${folder._id}`}
          index={index}
          folder={folder}
        />
      ))}
    </div>
  );
});

const FolderPage = ({
  match,
  user,
  updateTrackToggle,
  updateFolderToggle,
  isAuthenticated,
  toggleShareFolderModal,
  setContextObject,
  setCurrentFolderColor,
  toggleUploadTrackModal,
  toggleCreateFolderModal,
  contextObject,
  setContextMenuObject,
  loadUser,
  toggleAuthModal,
  setArtistNameDisplayed,
}) => {
  const [folder, setFolder] = useState();
  const [filters, setFilters] = useState();
  const [childFolders, setChildFolders] = useState();
  const [inLibrary, setInLibrary] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const filterParams = urlParams.get("filters");
  const history = useHistory();
  const linkRef = useRef();

  function onDragEnd(result) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (!result.destination) return;
    const { source, destination } = result;

    let tracks = [...folder.tracks];

    const element = tracks.splice(source.index, 1)[0];
    tracks.splice(destination.index, 0, element);

    setFolder({ ...folder, tracks });

    axios.patch(
      `/api/folders/updateTracklist/${folder._id}`,
      {
        oldPosition: source.index,
        newPosition: destination.index,
      },
      config
    );
  }

  const getPathHeading = () => {
    return (
      <div
        className="page-label"
        style={{
          color: folder.colorCode ? "rgba(255, 255, 255,0.7)" : "#b3b3b3",
        }}
      >
        Folder
      </div>
    );
  };

  async function fetchFolder() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.get(
        `/api/folders/getFolder/${match.params.folderId}/${filterParams}`,
        config
      );

      if (res.data.error) throw new Error(res.data.message);
      setFolder(res.data.folder);
      setChildFolders(res.data.folder.folders);
      setFilters(res.data.filters);
      setContextObject("folder", res.data.folder);
      setArtistNameDisplayed(
        res.data.folder.artistNameDisplayed === false ? false : true
      );

      let folderInLibrary = user
        ? user.folders.filter((_folder) => _folder._id === res.data.folder._id)
        : false;

      await setInLibrary(folderInLibrary[0]?.addedToLibrary ? true : false);

      Mixpanel.track("Open folder", {
        name: res.data.folder.name,
        folderId: res.data.folder._id,
        user: user ? user.username : "unidentified",
      });
    } catch (error) {
      toast.error(error.message);

      history.push("/");
    }
  }

  const copyURL = () => {
    linkRef.current.select();

    document.execCommand("copy");

    toast.success("Link copied");

    Mixpanel.track("Share folder via link", {
      folderId: folder._id,
    });
  };

  useEffect(() => {
    fetchFolder();
  }, [
    updateTrackToggle,
    updateFolderToggle,
    user,
    filterParams,
    match.params.folderId,
  ]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    setChildFolders((folders) => arrayMove(folders, oldIndex, newIndex));

    axios.patch(
      `/api/folders/reorderFolderList/${folder._id}`,
      {
        oldPosition: oldIndex,
        newPosition: newIndex,
      },
      config
    );
  };

  // Display context menu
  function displayMenu(e) {
    e.stopPropagation();

    setContextMenuObject(folder, "folder");

    contextMenu.show({
      id: "folder-menu",
      event: e,
    });
  }

  // Add to Library button

  const renderAddToLibraryButton = () => {
    if (inLibrary) {
      return null;
    } else if (user) {
      return (
        <div
          className="share-button button-secondary"
          onClick={() => addtoLibraryHandler()}
        >
          Save to Library
        </div>
      );
    } else {
      return (
        <div
          className="share-button button-secondary"
          onClick={() => toggleAuthModal("register-save-to-library")}
        >
          Save to Library
        </div>
      );
    }
  };

  async function addtoLibraryHandler() {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      await axios.patch(
        `/api/submissions/addToLibrary/${folder._id}/null`,
        config
      );

      loadUser();

      toast.success("Added to library");
    } catch (error) {
      console.log("get loops error");
    }
  }

  const { data, loading, error } = usePalette(folder?.folderImageURL);

  return (
    <div
      className={
        isAuthenticated
          ? "folder-page page-size"
          : "folder-page folder-page-logged-out"
      }
    >
      {folder ? (
        <div className="page-container">
          <div
            className="page-top"
            style={{
              background: data
                ? `linear-gradient(${data.muted}, rgba(0, 0, 0, 0.3))`
                : `linear-gradient(transparent, rgba(0, 0, 0, 0.3))`,
            }}
          >
            <input
              className="text-field sharing-link-field-mobile"
              type="text"
              name="username"
              ref={linkRef}
              autoComplete="off"
              value={`https://catalog.studio/folder/${folder._id}`}
              readOnly
            />
            <div className="folder-info-container">
              <div className="folder-page-info">
                {folder?.folderImageURL && (
                  <div
                    className="folder-image"
                    style={{
                      backgroundImage: `url(${folder?.folderImageURL})`,
                    }}
                  ></div>
                )}

                <div className="folder-details">
                  {getPathHeading()}
                  <div className="folder-page-name">{folder.name}</div>
                  <div className="folder-buttons">
                    {folder?.permissions.canEdit && (
                      <>
                        <div
                          className="create-folder-button button-secondary"
                          onClick={() =>
                            toggleCreateFolderModal(contextObject, "folder")
                          }
                        >
                          New folder
                        </div>

                        <div
                          className="add-tracks-button button-secondary"
                          onClick={() =>
                            toggleUploadTrackModal(contextObject, "folder")
                          }
                        >
                          Add tracks
                        </div>
                      </>
                    )}

                    {renderAddToLibraryButton()}

                    <div
                      className="share-button button-secondary"
                      onClick={() => toggleShareFolderModal(folder)}
                    >
                      Share
                    </div>

                    {isAuthenticated && (
                      <div
                        className="folder-menu-button"
                        onClick={(e) => displayMenu(e)}
                      >
                        <ion-icon name="ellipsis-horizontal"></ion-icon>
                      </div>
                    )}
                  </div>

                  <div className="folder-buttons-mobile">
                    <div
                      className="share-button-mobile button-secondary"
                      onClick={copyURL}
                    >
                      Share
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="page-content">
            {filters ? (
              !folder.inTrash ? (
                <>
                  {childFolders.length > 0 && (
                    <div className="folders-container">
                      <div className="folder-label">Folders</div>

                      <SortableList
                        distance={1}
                        folders={childFolders}
                        axis="xy"
                        onSortEnd={onSortEnd}
                      />
                    </div>
                  )}

                  {childFolders.length > 0 &&
                  folder.tracks.length === 0 ? null : (
                    <>
                      {folder.tracks.length > 0 && (
                        <div className="folder-label">Tracks</div>
                      )}
                      <TrackList
                        trackOwner={folder.permissions.isOwner}
                        restrictedDownloads={!folder.permissions.canDownload}
                        tracks={folder.tracks}
                        filters={filters}
                        folderId={match.params.folderId}
                        canAddTracks={folder.permissions.canEdit}
                        canReorder={folder.permissions.canEdit}
                        onDragEnd={onDragEnd}
                        folderImageURL={
                          folder.folderImageURL && folder.folderImageURL
                        }
                        artistNameDisplayed={folder.artistNameDisplayed}
                      />
                    </>
                  )}
                </>
              ) : (
                <div className="removed-message-container">
                  <div className="removed-message">
                    Your access to this folder has been removed or the folder
                    has been deleted.
                  </div>
                </div>
              )
            ) : (
              <div className="loader-icon">
                <BeatLoader
                  sizeUnit={"px"}
                  size={10}
                  color={"var(--primary-color)"}
                  loading={true}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  updateTrackToggle: state.track.updateToggle,
  updateFolderToggle: state.folder.updateToggle,
  isAuthenticated: state.auth.isAuthenticated,
  contextObject: state.context.contextObject,
});

export default connect(mapStateToProps, {
  toggleShareFolderModal,
  setContextObject,
  toggleFolderUpdate,
  setCurrentFolderColor,
  toggleUploadTrackModal,
  toggleCreateFolderModal,
  setContextMenuObject,
  loadUser,
  toggleAuthModal,
  setArtistNameDisplayed,
})(FolderPage);
