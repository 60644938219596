import React, { useState } from "react";
import { connect } from "react-redux";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

const AuthModal = ({ authState, contextObject }) => {
  const [currentAuthState, setCurrentAuthState] = useState(authState);

  const setCurrentAuthStateHandler = (authState) => {
    setCurrentAuthState(authState);
  };

  return currentAuthState === "login-save-to-library" ||
    currentAuthState === "login" ? (
    <LoginForm
      setCurrentAuthState={(currentAuthState) =>
        setCurrentAuthStateHandler(currentAuthState)
      }
      authState={currentAuthState}
      contextObjectId={contextObject._id}
    />
  ) : (
    <RegisterForm
      setCurrentAuthState={(currentAuthState) =>
        setCurrentAuthStateHandler(currentAuthState)
      }
      authState={currentAuthState}
      contextObjectId={contextObject._id}
    />
  );
};

const mapStateToProps = (state) => ({
  authState: state.modal.authState,
  contextObject: state.context.contextObject,
});

export default connect(mapStateToProps)(AuthModal);
