import React, { useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { register } from "../../../actions/auth";
import PropTypes from "prop-types";
import "./style.scss";

const Register = ({ register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const { username, email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (username === "") {
        throw new Error("Please enter a username");
      }

      if (!username.match("^[a-zA-Z0-9_]+$")) {
        throw new Error(
          "Usernames can only include letters, numbers, underscores."
        );
      }

      if (email === "") {
        throw new Error("Please enter an email address");
      }

      if (password === "") {
        throw new Error("Please enter a password");
      }

      register(username, email, password);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Redirect if signed up
  if (isAuthenticated) {
    return <Redirect to="dashboard/library" />;
  }

  return (
    <Fragment>
      <div className="mainPage">
        <div className="background" />
        <div className="mainContent">
          <div className="registerScreen">
            <div className="description">
              Upload, listen and share your music with anyone, anywhere.
            </div>
            <div className="form">
              <h2 className="title">Sign up</h2>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="bottom-16">
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => onChange(e)}
                    name="username"
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    className="text-field"
                  />
                </div>
                <div className="bottom-16">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={(e) => onChange(e)}
                    className="text-field"
                  />
                </div>

                <input
                  type="submit"
                  className="button bottom-40 full-width"
                  value="Sign up"
                />
              </form>
              <div className="link-text">
                Already have an account?{" "}
                <Link to="/login" className="link-button">
                  Log In
                </Link>
              </div>
            </div>
            <Link to="/" className="logo">
              catalog
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Register.propTypes = {
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register })(Register);
