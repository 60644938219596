import React from "react";
import { connect } from "react-redux";
import { setInvitePermission } from "../../../actions/folder";

// Context Menu
import { Menu, Item, animation } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const MENU_ID = "new-folder-invite";

const FolderInviteMenu = ({ setInvitePermission }) => {
  return (
    <Menu id={MENU_ID} animation={animation.fade}>
      <Item onClick={() => setInvitePermission("full-access")}>
        Full Access
      </Item>
      <Item onClick={() => setInvitePermission("view-and-download")}>
        View and download
      </Item>
      <Item onClick={() => setInvitePermission("view-only")}>View only</Item>
    </Menu>
  );
};

export default connect(null, { setInvitePermission })(FolderInviteMenu);
