import axios from "axios";
const config = require("./config.json");

/**
 * Sets up a push subscription if it is null
 */
export default async function () {
  const sw = await navigator?.serviceWorker?.getRegistration();
  sw.pushManager.getSubscription().then((subscription) => {
    if (subscription === null) {
      sw.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(config.VAPID_PUBLIC_KEY),
        })
        .then(async (subscription) => {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };

          const body = subscription;

          await axios.post("/api/auth/setWebPushSubscription", body, config);
        })
        .catch((e) => console.log(e));
    }
  });
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
