import React, { useRef } from "react";
import { connect } from "react-redux";
import useOutsideClick from "../../../../utils/useOutsideClick";
import { toast } from "react-toastify";

import { toggleTrackUpdate } from "../../../../actions/track";

import axios from "axios";

import "./style.scss";

const CommentMenu = ({
  closeMenuHandler,
  type,
  track,
  comment,
  reply,

  toggleTrackUpdate,
}) => {
  const menuRef = useRef();

  useOutsideClick(menuRef, () => {
    closeMenuHandler();
  });

  const deleteCommentHandler = () => {
    if (type === "comment") {
      deleteComment();
    } else if (type === "reply") {
      deleteReply();
    }
  };

  const deleteComment = async () => {
    try {
      const res = await axios.patch(
        `/api/comments/removeComment/${track._id}/${comment._id}`
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success("Deleted comment");

      toggleTrackUpdate();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteReply = async () => {
    try {
      const res = await axios.patch(
        `/api/comments/removeReply/${comment._id}/${reply._id}`
      );

      if (res.data.error) throw new Error(res.data.message);

      toast.success("Deleted reply");

      toggleTrackUpdate();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="comment-menu-container" ref={menuRef}>
      <div className="menu-item" onClick={() => deleteCommentHandler()}>
        Delete
      </div>
    </div>
  );
};

export default connect(null, { toggleTrackUpdate })(CommentMenu);
